
//---------------------------------------------------------------------------
// 顧問
//---------------------------------------------------------------------------
var CommonScript = Backbone.View.extend({
	initialize:function(){

		_.each(SCRIPTS,function(func,name,obj){
			func(this);
		},this);

		var self = this;

		window.onpageshow = function(){self.onPageShow()}
	},

	onPageShow:function(){
		$("body").waitMe("hide");
	},

});

//---------------------------------------------------------------------------
// 初期動作
//---------------------------------------------------------------------------
(function(document,$){
    window.KKD =  new CommonScript({el:document});
})(document,jQuery);