//---------------------------------------------------------------------------
// 契約書の詳細ページ用JS
//---------------------------------------------------------------------------
var ContractDetail = Backbone.View.extend({
	events:{
		"change [name='mail_address_select_domain']" : "onChangeMailDomainSelect",
		"click #send_mail_btn" : "onClickSendMail",
		"click #send_sms_btn" : "onClickSendSms",
	},


	loan_total:0,
	loan_deposit:0,

	pay_type:null,

	is_calculation:false,
	do_next_calculation:false,

	initialize:function(){
		//console.log(this.$el);
		
		// 指定役務を決める
		//this.onChangeSpecificSale();

		// this.pay_type = __PAY_TYPE;

		// // 引渡し時期の設定
		// this.loan_total = this.$("#total_item_price2").val();
		// this.loan_deposit = this.$("#loan_deposit").val();

		// this.$("#total_item_price2").val(changeNumFomatMoney(this.loan_total));
		// this.$("#loan_deposit").val(changeNumFomatMoney(this.loan_deposit));

		// this.funcLoanSimlation();

		// this.$(".simlation_manual_pay").hide();
		// this.$(".simlation_bonus_pay").hide();
		// this.$(".simlation_one_pay").hide();
		// this.$(".simlation_two_pay").hide();

		// switch(this.pay_type){
		// 	case 1:
		// 		this.$(".simlation_manual_pay").show();
		// 		break;
		// 	case 2:
		// 		this.$(".simlation_bonus_pay").show();
		// 		break;
		// 	case 3:
		// 		this.$(".simlation_one_pay").show();
		// 		break;
		// 	case 4:
		// 		this.$(".simlation_two_pay").show();
		// 		break;
		// }

		// メールアドレス（その他かどうか）
		this.onChangeMailDomainSelect();
	},





/*===============================
=            Checker            =
===============================*/



/*=====  End of Checker  ======*/


	



	
/*===============================
=            Program            =
===============================*/
	

	// ローンシミュレーションを動かす
	funcLoanSimlation:function(){

		// 支払い月
		var nextTwoMonth = moment().add(2,"M");
		var year = nextTwoMonth.year();
		var month = nextTwoMonth.month()+1;

		// 支払い回数
		var count = 0;
		if(this.pay_type==1 || this.pay_type==2){
			count = this.$("#payment_count").val();
		}else if(this.pay_type==3){
			count = 1;
		}else if(this.pay_type==4){
			count = 2;
		}

		// 一括、または2回払いの時利用
		var first_date = null;
		var second_date = null;
		if(this.pay_type==3){
			first_date = this.$("#onePaySelect1").val();
		}else if(this.pay_type==4){
			first_date = this.$("#twoPaySelect1").val();
			second_date = this.$("#twoPaySelect2").val();
		}

		// 一括、または2回払いの時利用
		var first_pay = null;
		if(this.pay_type==3){
		}else if(this.pay_type==4){
			first_pay = this.$("#twoPayInput1AreaInput").val();
		}

        var total          = changeNumFomatInt(this.$("#total_item_price2").val());
        var down           = changeNumFomatInt(this.$("#loan_deposit").val());
        var firstyear      = year;
        var firstmonth     = month;
        var numpay         = count;
        var bonuspay       = this.pay_type==2 ? changeNumFomatInt(this.$("#bonusPayMoneyInput").val()) : null;
        var bonus1_m       = this.pay_type==2 ? this.$("#pay_bonus_summer_month").val() : null;
        var bonus2_m       = this.pay_type==2 ? this.$("#pay_bonus_winter_month").val() : null;
        var saving_depo    = null; // 預貯金額はnull

        var shop_pay_num   = 0;
        var base_date      = firstyear+"-"+firstmonth;
        var first_date     = first_date;
        var first_pay      = first_pay;
        var second_date    = second_date;

        var post = {
        	"total"			: total,
        	"down"			: down,
        	"firstyear"		: firstyear,
        	"firstmonth"	: firstmonth,
        	"numpay"		: numpay,
        	"bonuspay"		: bonuspay,
        	"bonus1_m"		: bonus1_m,
        	"bonus2_m"		: bonus2_m,
        	"saving_depo"	: saving_depo,
        	"shop_pay_num"	: shop_pay_num,
        	"base_date"		: base_date,
        	"first_date"	: first_date,
        	"first_pay"		: first_pay,
        	"second_date"	: second_date,
        };

        // ajaxで計算を走らす
        if(total && down && (!this.is_calculation)){
        	this.is_calculation = true;
        	this.$("#simulationArea").waitMe({effect : 'progressBar',color : "#298470"});
        	this.ajax(__AJAX_LOAN_CALC_URL,post,function(res){
        		this.$("#simulationArea").waitMe("hide");
        		this.funcSetLoanMoney(res);
        	},function(){
        		this.$("#simulationArea").waitMe("hide");
        		this.is_calculation = false;
        	});
        }else{
        	this.do_next_calculation = true;
        }

	},

	// 結果を反映
	funcSetLoanMoney:function(result){

		this.$("#I_Total").val(result["I_Total"]);
		this.$("#I_Down").val(result["I_Down"]);
		this.$("#O_Remaining").val(result["O_Remaining"]);
		this.$("#O_InstallmentFee").val(result["O_InstallmentFee"]);
		this.$("#O_InstallmentsTotal").val(result["O_InstallmentsTotal"]);
		this.$("#O_TotalPay1").val(result["O_TotalPay1"]);

		var initialDay = moment(result["I_InitialPayDay"]).format("YYYY年MM月");
		var finalDay = moment(result["O_FinalPayDay"]).format("YYYY年MM月");

		if(this.pay_type==1){
			this.$("#O_MANUAL_FirstPay").text(result["O_FirstPay"] + "円 × " + result["O_FirstPayNum"] + "回");
			this.$("#O_MANUAL_SecondPay").text(result["O_SecondPay"] + "円 × " + result["O_SecondPayNum"] + "回");
			this.$("#I_MANUAL_InitialPayDay").text("初回支払月 " + initialDay + "　" + "最終支払月 " + finalDay);
		}

		if(this.pay_type==2){
			this.$("#O_BONUS_FirstPay").text(result["O_FirstPay"] + "円 × " + result["O_FirstPayNum"] + "回");
			this.$("#O_BONUS_SecondPay").text(result["O_SecondPay"] + "円 × " + result["O_SecondPayNum"] + "回");
			this.$("#I_BONUS_Pay").text(result["I_BonusPay"] + "円 × " + result["O_BonusNumberPay"] + "回");
			this.$("#I_BONUS_InitialPayDay").text("初回支払月 " + initialDay + "　" + "最終支払月 " + finalDay);
		}

		if(this.pay_type==3){
			this.$("#O_ONEPAY_FirstPay").text(result["O_FirstPay"] + "円");
			this.$("#O_ONEPAY_FinalPayDay").text(finalDay);
		}

		if(this.pay_type==4){
			var initialDayTwoPay = moment(result["I_FirstDate"]).format("YYYY年MM月");
			
			this.$("#O_TWOPAY_FirstPay").text(result["O_FirstPay"] + "円");
			this.$("#O_TWOPAY_FirstPayDay").text(initialDayTwoPay);

			this.$("#O_TWOPAY_SecondPay").text(result["O_SecondPay"] + "円");
			this.$("#O_TWOPAY_FinalPayDay").text(finalDay);
		}

		// 完了
		this.is_calculation = false;

		// すぐに計算をしなければ行けない場合はここが走る
		if(this.do_next_calculation){
			this.do_next_calculation = false;
			this.funcLoanSimlation();
		}
	},

	// メール送信
	funcSendMailAjax:function(sei,mei,mail){
		var data = {};
		data["sei"] = sei;
		data["mei"] = mei;
		data["mail"] = mail;

		this.$(".mail_box").waitMe({effect : 'progressBar'});

		this.ajax(__SEND_MAIL_URL,data,function(res){
			var $mailBox = this.$(".mail_box");
			$mailBox.waitMe("hide");

			var $smsBox = $mailBox.find('.sms_box');
			var smsBoxHTML = ($smsBox.length) ? $smsBox.prop('outerHTML') : '';
			var html = $(
				'<div class="alert alert-success">' +
					res.sended_at+' '+res.ctml_vc_sei+' '+res.ctml_vc_mei+' 様('+res.ctml_vc_send_address+')にローン申込フォーム（マイページ）をメールで送信しました。<br>' +
					res.login_url +
					smsBoxHTML +
				'</div>'
			);
			html.hide();
			this.$(".mail_sended_list").append(html);
			html.fadeIn(200);

			$smsBox.remove();

			$mailBox.find('input:not([readonly])').val('');
			$mailBox.find('select').val(
				$mailBox.find('select option:first-of-type').val()
			);
			this.onChangeMailDomainSelect();
		},function(){
			this.$(".mail_box").waitMe("hide");
		});
	},

	// SMS送信
	funcSendSmsAjax:function(){
		var $smsBox = this.$('.sms_box');
		var $btn = $smsBox.find('#send_sms_btn');
		var $err = $smsBox.find('.error_message');
		var $sent = $smsBox.find('.message');

		$btn.addClass('disabled');

		this.ajax(__SEND_SMS_URL, null, function(res) {
			console.log(res);
			$btn.removeClass('disabled');
			$sent.fadeIn(200);
		}, function() {
			$btn.removeClass('disabled');
			$err.find('p').text('SMSの送信に失敗しました。');
			$err.fadeIn(200);
		});
	},

/*=====  End of Program  ======*/

/*=============================
=            Event            =
=============================*/
	onChangeMailDomainSelect:function(){
		//「その他」ならフォーム表示
		var $sel = this.$('[name="mail_address_select_domain"]');
		var $other = this.$('[name="mail_address_input_domain"]');
		if ($sel.val() == 'other') {
			$sel.addClass('show_other');
			$other.show();
		} else {
			$sel.removeClass('show_other');
			$other.hide();
		}
	},

	onClickSendMail:function(){
		var sei = this.$("#mail_sei").val();
		var mei = this.$("#mail_mei").val();

		var $mailbox = this.$(".mail_box");
		var $error = $mailbox.find(".error_message");
		var $message = $error.find("p");

		var $sel = this.$('[name="mail_address_select_domain"]');
		var account = this.$('[name="mail_address_input_account"]').val();
		var domain = ($sel.val() == 'other') ? this.$('[name="mail_address_input_domain"]').val() : $sel.find('option:selected').text();

		// 小文字に変換してアドレス作成
		var address = account.toLowerCase() + '@' + domain.toLowerCase();

		if (sei && mei) {

			if (!account) {
				$message.text("メールアドレスの「@」マークより前の部分は必ず入力してください。");
				$error.fadeIn(200);

			} else if (($sel.val() == 'other') && !domain) {
				$message.text("その他の場合は、必ずその他入力欄にメールアドレスの「@」マークより後ろの部分を入力してください。");
				$error.fadeIn(200);

			} else if (!Validator.isMail(address)) {
				$message.text("メールアドレスが正しくありません。再度ご確認ください。");
				$error.fadeIn(200);

			} else {
				$error.fadeOut(200);
				this.funcSendMailAjax(sei, mei, address);
			}

		} else {
			$message.text("お名前は必須です。必ず入力してください。");
			$error.fadeIn(200);
		}

		return false;
	},

	onClickSendSms:function(){
		var $smsBox = this.$('.sms_box');
		var $sent = $smsBox.find('.message');
		var $err = $smsBox.find('.error_message');
/*
		if (!tel) {
			$err.find('p').text('電話番号は必須です。必ず入力してください。');
			$err.fadeIn(200);
			$sent.hide();

		} else if (!/^(070|080|090)\d{8}$/.test(tel)) {
			$err.find('p').text('電話番号は、070・080・090 の何れかから始まる11桁の半角数字のみ入力できます。');
			$err.fadeIn(200);
			$sent.hide();

		} else {
			$err.fadeOut(200);
			$sent.fadeOut(200);
			this.funcSendSmsAjax(tel);
		}
*/
		$err.fadeOut(200);
		$sent.fadeOut(200);
		this.funcSendSmsAjax();

		return false;
	},


/*=====  End of Event  ======*/



});