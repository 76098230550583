//---------------------------------------------------------------------------
// 契約書の確認ページ用JS
//---------------------------------------------------------------------------
var ContractConfirm = Backbone.View.extend({
	events:{
		"click .weit-btn" : "onClickWeitBtn",
	},


	loan_total:0,
	loan_deposit:0,

	pay_type:null,

	is_calculation:false,
	do_next_calculation:false,

	initialize:function(){

		// 戻るボタン制御
		history.pushState(null, null, null);
		var self = this;
		window.addEventListener("popstate", function() {
			self.backAlert();
			//alert("ブラウザの「戻る」ボタンはご利用になれません。お申込み内容の修正を行う場合は、最下部の「内容を修正する」ボタンより、お戻りください。");
			history.pushState(null, null, null);
			return false;
		});

		//console.log(this.$el);
		
		// 指定役務を決める
		//this.onChangeSpecificSale();

		this.pay_type = __PAY_TYPE;

		// 引渡し時期の設定
		this.loan_total = this.$("#total_item_price2").val();
		this.loan_deposit = this.$("#loan_deposit").val();

		this.$("#total_item_price2").val(changeNumFomatMoney(this.loan_total));
		this.$("#loan_deposit").val(changeNumFomatMoney(this.loan_deposit));

		this.funcLoanSimlation();

		this.$(".simlation_manual_pay").hide();
		this.$(".simlation_bonus_pay").hide();
		this.$(".simlation_one_pay").hide();
		this.$(".simlation_two_pay").hide();

		switch(this.pay_type){
			case 1:
				this.$(".simlation_manual_pay").show();
				break;
			case 2:
				this.$(".simlation_bonus_pay").show();
				break;
			case 3:
				this.$(".simlation_one_pay").show();
				break;
			case 4:
				this.$(".simlation_two_pay").show();
				break;
		}

		this.$el.waitMe("hide");

	},





/*===============================
=            Checker            =
===============================*/



/*=====  End of Checker  ======*/


	



	
/*===============================
=            Program            =
===============================*/
	

	// ローンシミュレーションを動かす
	funcLoanSimlation:function(){

		var cmoment = moment(this.$("#create_apply").val());

		// 一括、二回は、こちらをBaseに作る
		var oneTwoPayCmoment = moment(this.$("#create_apply").val());

		if(!isTablet){
			oneTwoPayCmoment = contract_created_at;
		}

		if(addictionCoolingOff){
			//cmoment.add(7,"d");
			cmoment.add(coolingOffTerm-1,"d");  // クーリングオフ デフォルト利用
		}

		if(isTablet){

			var today = moment();

			var notTodayContract = false;

			var diff1 = Number(cmoment.format("YYYYMMDD"));
			var diff2 = Number(today.format("YYYYMMDD"));
			if(diff1 >= diff2){
				// 今日までは、cmoment（申込年月日で日付計算）
			}else{
				// 修正日優先
				cmoment = today;

				// 昨日以前のお申込み
				notTodayContract = true;
			}

			// 初回支払のチェック。初回支払い設定を超えていると、月を跨ぐように設定
			if(!_.has(nextMonthPayParam,Number(cmoment.format("YYYYMM")))){
				return false;
			}
			var checkdate = _.clone(nextMonthPayParam[cmoment.format("YYYYMM")]);
			checkdate.date = _.str_replace(checkdate.date,"-","/");
			var isNextMonthPay = false;
			if(addictionCoolingOff){
				if(cmoment.isAfter(checkdate.date)){
					isNextMonthPay = true;
				}
			}else{

				// 20171212 タテモト修正
				if(isAfAcst && notTodayContract){ // 訂正の時は一日ズラす
					checkdate.date = moment(checkdate.date).add(1,"d").format("YYYY-MM-DD")
				}

				if(cmoment.isSameOrAfter(checkdate.date)){
					isNextMonthPay = true;
				}
			}
			// 次月に変更する
			if(isNextMonthPay){
				cmoment.add(1,"M");
			}
		}

		// 支払い月
		var nextTwoMonth = cmoment.add(1,"M");
		var year = nextTwoMonth.year();
		var month = nextTwoMonth.month()+1;

		var addon = AddonPoint;
		var shop_addon = ShopAddonPoint;

		// 支払い回数
		var count = 0;
		var burdenCommissionCount = BurdenCommisionCount;
		if(this.pay_type==1 || this.pay_type==2){
			count = this.$("#payment_count").val();
		}else if(this.pay_type==3){
			count = 1;
		}else if(this.pay_type==4){
			count = 2;
		}

		// 一括、または2回払いの時利用
		var first_date = null;
		var second_date = null;
		if(this.pay_type==3){
			first_date = this.$("#onePaySelect1").val();
		}else if(this.pay_type==4){
			first_date = this.$("#twoPaySelect1").val();
			second_date = this.$("#twoPaySelect2").val();
		}

		// 一括、または2回払いの時利用
		var first_pay = null;
		if(this.pay_type==3){
		}else if(this.pay_type==4){
			first_pay = this.$("#twoPayInput1AreaInput").val();
		}

        var total          = changeNumFomatInt(this.$("#total_item_price2").val());
        var down           = changeNumFomatInt(this.$("#loan_deposit").val());
        var firstyear      = year;
        var firstmonth     = month;
        var numpay         = count;
        var bonuspay       = this.pay_type==2 ? changeNumFomatInt(this.$("#bonusPayMoneyInput").val()) : null;
        var bonus1_m       = this.pay_type==2 ? this.$("#pay_bonus_summer_month").val() : null;
        var bonus2_m       = this.pay_type==2 ? this.$("#pay_bonus_winter_month").val() : null;
        var saving_depo    = null; // 預貯金額はnull

        var shop_pay_num   = burdenCommissionCount;
        var base_date      = firstyear+"-"+firstmonth;
        var first_date     = first_date;
        var first_pay      = first_pay;
        var second_date    = second_date;

        if(this.pay_type==3 || this.pay_type==4){
        	base_date = oneTwoPayCmoment.format("YYYY-MM");
        }

        var post = {
        	"total"			: total,
        	"down"			: down,
        	"firstyear"		: firstyear,
        	"firstmonth"	: firstmonth,
        	"numpay"		: numpay,
        	"bonuspay"		: bonuspay,
        	"bonus1_m"		: bonus1_m,
        	"bonus2_m"		: bonus2_m,
        	"saving_depo"	: saving_depo,
        	"shop_pay_num"	: shop_pay_num,
        	"base_date"		: base_date,
        	"first_date"	: first_date,
        	"first_pay"		: first_pay,
        	"second_date"	: second_date,
        	"addon"			: addon,
        	"shop_addon"	: shop_addon,
        };

        // ajaxで計算を走らす
        if(total && (!this.is_calculation)){
        	this.is_calculation = true;
        	this.$("#simulationArea").waitMe({effect : 'progressBar',color : "#298470"});
        	this.ajax(__AJAX_LOAN_CALC_URL,post,function(res){
        		this.$("#simulationArea").waitMe("hide");
        		this.funcSetLoanMoney(res);
        	},function(){
        		this.$("#simulationArea").waitMe("hide");
        		this.is_calculation = false;
        	});
        }else{
        	this.do_next_calculation = true;
        }

	},

	// 結果を反映
	funcSetLoanMoney:function(result){

		this.$("#I_Total").val(result["I_Total"]);
		this.$("#I_Down").val(result["I_Down"]);
		this.$("#O_Remaining").val(result["O_Remaining"]);
		this.$("#O_InstallmentFee").val(result["O_InstallmentFee"]);
		this.$("#O_InstallmentsTotal").val(result["O_InstallmentsTotal"]);
		this.$("#O_TotalPay1").val(result["O_TotalPay1"]);

		var initialDay = moment(result["I_InitialPayDay"].replace(/-/g, "/")).format("YYYY年MM月27日");
		var finalDay = moment(result["O_FinalPayDay"].replace(/-/g, "/")).format("YYYY年MM月27日");

		if(this.pay_type==1){
			this.$("#O_MANUAL_FirstPay").text(result["O_FirstPay"] + "円 × " + result["O_FirstPayNum"] + "回");
			this.$("#O_MANUAL_SecondPay").text(result["O_SecondPay"] + "円 × " + result["O_SecondPayNum"] + "回");
			this.$("#I_MANUAL_InitialPayDay").text("初回支払月 " + initialDay + "　" + "最終支払月 " + finalDay);
		}

		if(this.pay_type==2){

			// ボーナス加算月を入れる
			var month = [];
			_.each(result["O_BonusMonth"],function(v){
				var m = moment(v.replace(/-/g, "/"));
				month.push(m.format("YYYY年MM月"));
			});

			this.$("#O_BONUS_FirstPay").text(result["O_FirstPay"] + "円 × " + result["O_FirstPayNum"] + "回");
			this.$("#O_BONUS_SecondPay").text(result["O_SecondPay"] + "円 × " + result["O_SecondPayNum"] + "回");
			this.$("#I_BONUS_Pay").text(result["I_BonusPay"] + "円 × " + result["O_BonusNumberPay"] + "回");
			this.$("#O_BonusMonth").html(month.join("<br>"));
			this.$("#I_BONUS_InitialPayDay").text("初回支払月 " + initialDay + "　" + "最終支払月 " + finalDay);
		}

		if(this.pay_type==3){
			this.$("#O_ONEPAY_FirstPay").text(result["O_FirstPay"] + "円");
			this.$("#O_ONEPAY_FinalPayDay").text(finalDay);
		}

		if(this.pay_type==4){
			var initialDayTwoPay = moment(result["I_FirstDate"].replace(/-/g, "/")).format("YYYY年MM月27日");
			
			this.$("#O_TWOPAY_FirstPay").text(result["O_FirstPay"] + "円");
			this.$("#O_TWOPAY_FirstPayDay").text(initialDayTwoPay);

			this.$("#O_TWOPAY_SecondPay").text(result["O_SecondPay"] + "円");
			this.$("#O_TWOPAY_FinalPayDay").text(finalDay);
		}

		// 完了
		this.is_calculation = false;

		// すぐに計算をしなければ行けない場合はここが走る
		if(this.do_next_calculation){
			this.do_next_calculation = false;
			this.funcLoanSimlation();
		}
	},

/*=====  End of Program  ======*/

/*=============================
=            Event            =
=============================*/

	onClickWeitBtn:function(){
		this.$el.waitMe({effect : 'progressBar'});
	},

/*=====  End of Event  ======*/



});
