//---------------------------------------------------------------------------
// チャットのプログラム
//---------------------------------------------------------------------------
var ContractIndividual = Backbone.View.extend({
	events:{

		"click #submit_btn":"onSubmitMessageBtn",

		"change .readed_check" : "onChangeReadedCheck",

		"click #tel_change" : "onClickTelChange",
		"click #tel_reset" : "onClickTelReset",

		"click #set_cancel" : "onClickSetCancel",

		"click #tel_change_submit" : "onClickTelChangeSubmit",
		"click #tel_reset_submit" : "onClickTelResetSubmit",

		"click #set_cancel_submit" : "onClickSetCancelSubmit",
	},

	chatRoomId:null,

	messageTimeout:null,
	messageReloadTime: 10000,


	initialize:function(){
		this.chatRoomId = __CHAT_ROOM_ID;

		var self = this;

		this.messageTimeout = setTimeout(function(){
			self.getMessage();
		},this.messageReloadTime);

	},
	





/*===============================
=            Checker            =
===============================*/



/*=====  End of Checker  ======*/


/*=================================
=            Beri Area            =
=================================*/
	onClickTelChange:function(){
		this.$("#tel_change_modal").find(".error_message").hide();
		this.$("#tel_change_modal").modal("show");

		return false;
	},
	onClickTelReset:function(){
		this.$("#tel_reset_modal").find(".error_message p").text("");
		this.$("#tel_reset_modal").modal("show");

		return false;
	},

	onClickSetCancel:function(){
		this.$("#set_cancel_modal").modal("show");

		return false;
	},


/*=====  End of Beri Area  ======*/


/*=======================================
=            Change Tel Date            =
=======================================*/

	onClickTelChangeSubmit:function(){
		var $modal = this.$("#tel_change_modal");

		$modal.find(".beri_date_error p").text("");

		var year = $modal.find(".select-year").val();
		var month = $modal.find(".select-month").val();
		var day = $modal.find(".select-day").val();

		var hour = $modal.find(".select-hour1").val();
		var minute = $modal.find(".select-minute1").val();

		var hour2 = $modal.find(".select-hour2").val();
		var minute2 = $modal.find(".select-minute2").val();

		var home = $modal.find("#tc_beri_hope_type-1").prop("checked");
		var mobile = $modal.find("#tc_beri_hope_type-2").prop("checked");
		var company = $modal.find("#tc_beri_hope_type-3").prop("checked");
		var counter = $modal.find("#tc_beri_hope_type-4").prop("checked");

		var date_ok = false;
		var time_ok = false;
		var noBefore = true;
		var noAfter = true;

		var isValid = true;

		if(year && month && day){
			var check1 = (isTodayBeri) ? __APPLY_DATE.isSameOrBefore(year+"/"+month+"/"+day) : __APPLY_BEFORE_DATE.isSameOrBefore(year+"/"+month+"/"+day);
			var check2 = __APPLY_DATE.clone().add(2,"M").endOf("month").isSameOrAfter(year+"/"+month+"/"+day);
			
			if(check1 && check2){
				$modal.find(".date").val(year+"-"+month+"-"+day);
				date_ok = true;
			}else{
				$modal.find(".date").val("");
				if(!check1){
					noBefore = false;
				}
				if(!check2){
					noAfter = false;
				}
				
			}

		}else{
			$modal.find(".date").val("");
		}
		if(hour){
			if(!minute){
				minute = "0";
			}
			$modal.find(".time1").val(hour+":"+minute);
			time_ok = true;
		}else{
			$modal.find(".time1").val("");
		}
		if(hour2){
			if(!minute2){
				minute2 = "0";
			}
			$modal.find(".time2").val(hour2+":"+minute2);
		}else{
			$modal.find(".time2").val("");
		}

		if(!date_ok || !time_ok){
			if(noBefore && noAfter){
				$modal.find(".beri_date_error p").text("日付、または日時が正しくありません。");
			}
			if(noBefore){
			}else{
				$modal.find(".beri_date_error p").text((isTodayBeri) ? "ベリ日時は申込日以前に設定できません。" : "ベリ日時は申込日以降設定可能です。");
			}
			if(noAfter){
			}else{
				$modal.find(".beri_date_error p").text("日付は、申込日から翌々月末以前まで設定可能です。");
			}
		}

		var time1Num = hour+minute;
		var time2Num = false;
		if(hour2){
			time2Num = hour2+minute2;
		}
		

		if(
			time1Num == "2015" ||
			time1Num == "2030" ||
			time1Num == "2045" ||
			time2Num == "2015" ||
			time2Num == "2030" ||
			time2Num == "2045"
		){
			$modal.find(".beri_date_error p").text("20:00以降は設定出来ません。");
		}




		// 非対応日時のチェック処理
		var isIncompatible = false;

		var d = Number(moment(year+"/"+month+"/"+day).format("YYYYMMDD"));
		var tm1 = Number(hour+(minute=="0" ? "00" : minute));
		var tm2 = Number(hour2+(minute2=="0" ? "00" : minute2));

		if(!_(d).isNaN()){
			_(beri_master).each( function( value, key, beri_master ) {
				var sd = value.dt_start;
				var ed = value.dt_end;
				var st = value.tm_start;
				var et = value.tm_end;
				if(d >= sd && d <= ed){
					if(tm2){
						if(
							(tm1 >= st && tm1 <= et) || (tm2 >= st && tm2 <= et)
						){
							isIncompatible = true;
						}
					}else{
						if(
							(tm1 >= st && tm1 <= et)
						){
							isIncompatible = true;
						}
					}
					
				}
			});

			if(isIncompatible){
				$modal.find(".beri_date_error p").text("この日時は、設定出来ません。");
			}
		}

		//架電先チェック
		if (!home && !mobile && !company && !counter) {
			$modal.find(".beri_type_error").show();
			$modal.find(".type").val("");
			isValid = false;
		} else {
			$modal.find(".beri_type_error").hide();
			$modal.find(".type").val(
				$modal.find('[name="ctcus_tint_beri_hope_type"]:checked').val()
			);
		}



		if($modal.find(".beri_date_error p").text()){
			$modal.find(".beri_date_error").show();

			isValid = false;
		}

		if (!isValid) return false;

		this.$el.waitMe({effect : 'progressBar'});
		this.$("#tel_change_form").submit();

	},

/*=====  End of Change Tel Date  ======*/


/*===========================================
=            Beri Reset function            =
===========================================*/

	onClickTelResetSubmit:function(){

		var self = this;

		var $modal = this.$("#tel_reset_modal");

		var $hope_date = $modal.find(".beri_box");
		$modal.find(".error_message p").text("");

		var home = $modal.find("#tr_beri_hope_type-1").prop("checked");
		var mobile = $modal.find("#tr_beri_hope_type-2").prop("checked");
		var company = $modal.find("#tr_beri_hope_type-3").prop("checked");
		var counter = $modal.find("#tr_beri_hope_type-4").prop("checked");

		var has_error = false;

		$hope_date.each(function(index){
			var year = $(this).find(".select-year").val();
			var month = $(this).find(".select-month").val();
			var day = $(this).find(".select-day").val();

			var b_date = year+'/'+month+'/'+day;
			var b_time = $(this).find(".select-time").val();

			var date_ok = false;
			var time_ok = false;
			var noBefore = true;
			var noAfter = true;

			if(year && month && day){
				// 明日以降
				//var check = moment(moment().format("YYYY/MM/DD")).add(1, 'days').isSameOrBefore(year+"/"+month+"/"+day);
				// 申込日以降
				
				var check1 = (isTodayBeri) ? __APPLY_DATE.isSameOrBefore(year+"/"+month+"/"+day) : __APPLY_BEFORE_DATE.isSameOrBefore(year+"/"+month+"/"+day);
				var check2 = __APPLY_DATE.clone().add(2,"M").endOf("month").isSameOrAfter(year+"/"+month+"/"+day);

				if(check1 && check2){
					$(this).find(".date").val(year+"-"+month+"-"+day);
					date_ok = true;
				}
				if(check1){

				}else{
					has_error = true;
					$(this).find(".beri_date_error p").text((isTodayBeri) ? "ベリ日時は申込日以前に設定できません。" : "ベリ日時は申込日以降設定可能です。");
					noBefore = false;
				}
				if(check2){

				}else{
					has_error = true;
					$(this).find(".beri_date_error p").text("ベリ日時は翌月末以前まで設定可能です。");
					noAfter = false;
				}
			}else{
				$(this).find(".date").val("");
				if(month || day){
					has_error = true;
					$(this).find(".beri_date_error p").text("日付が正しくありません。");
				}
			}

			if(b_time != '0'){
				time_ok = true;
				b_time_obj = beri_times[b_time];
				$(this).find(".time1").val(b_time_obj.time1);
				$(this).find(".time2").val(b_time_obj.time2);
			}else{
				$(this).find(".time1").val("");
				$(this).find(".time2").val("");
			}

			$(this).find(".remark").val($(this).find(".remark_box").val());

			var count = index+1;

			if(count==1){
				if(!date_ok || !time_ok){
					has_error = true;
					if(noBefore && noAfter){
						$(this).find(".beri_date_error p").text("第1希望年月日時間は必須です。");
					}else{
						if(!noBefore){
							$(this).find(".beri_date_error p").text((isTodayBeri) ? "ベリ日時は申込日以前に設定できません。" : "ベリ日時は申込日以降設定可能です。");
						}
						if(!noAfter){
							$(this).find(".beri_date_error p").text("ベリ日時は申込日から翌々月末以前まで設定可能です。");
						}
					}
				}
			}else{
				if(date_ok){
					if(!time_ok){
						has_error = true;
						$(this).find(".beri_date_error p").text("必ず「時間」も選択してください。");
					}
				}
			}



			// 非対応日時のチェック処理
			var isIncompatible = false;
			var tm1 = $(this).find(".time1").val();
			var tm2 = $(this).find(".time2").val();
			if(b_date && tm1 && tm2){
				isIncompatible = _.beriIncompatible(beri_master, b_date+' '+tm1,b_date+' '+tm2);
				if(isIncompatible){
					$(this).find(".beri_date_error p").text("申し訳ございませんがNCB臨時休業時間中のご指定となっておりますのでご変更をお願い致します。");
				}
			}
		});

		//架電先チェック
		if (!home && !mobile && !company && !counter) {
			$modal.find(".beri_type_error p").text("ベリ架電先は必ず選択してください。");
			has_error = true;
		}

		if(has_error){
			return false;
		}

		this.$el.waitMe({effect : 'progressBar'});
		this.$("#tel_reset_form").submit();

		return false;
	},

/*=====  End of Beri Reset function  ======*/

/*===========================================
=            Set Cancel program           =
===========================================*/

	onClickSetCancelSubmit:function(){
		this.$el.waitMe({effect : 'progressBar'});
		this.$("#set_cancel_form").submit();
	},

/*=====  End of Set Cancel program ======*/




	
/*===============================
=            Program            =
===============================*/

	clearMessageForm:function(){
		this.$("#message_form").val("");
	},

	sendMessage:function(){
		var message = this.$("#message_form").val();
		if(message){
			var data = {
				id:this.chatRoomId,
				message:message,
			};

			var self = this;

			this.ajax(__CHAT_SEND_URL,data,function(){
				self.getMessage();
			},function(){
				
			});

			this.$("#message_form").val("");
		}
	},

	getMessage:function(){
		this.clearGetMessage();
		var self = this;

		var chat_message = this.$(".chat_message").first();
		var messageId = 0;
		if(chat_message.size()){
			messageId = chat_message.data("messageId");
		}

		var data = {
			id:this.chatRoomId,
			message_id:messageId,
		};

		this.ajax(__CHAT_MESSAGE_URL,data,function(res){
			this.gotMessage(res.new);
			this.updateReadMessageIcon(res.messages);

			this.messageTimeout = setTimeout(function(){
				self.getMessage();
			},this.messageReloadTime);
		},function(){
		});
	},

	gotMessage:function(messages){
		var $message_list = this.$(".message_list");

		var owner_tpl = _.template(this.$("#message_owner").html());
		var plum_tpl = _.template(this.$("#message_plum").html());

		var all_html = "";

		_.each(messages,function(v){
			var user = v.login_user;
			var type = user.users_int_type;
			var html = "";

			v.is_readed = v.cht_is_plum_readed;

			if(type==9 || type==8){
				html = plum_tpl(v);
			}else{
				html = owner_tpl(v);
			}

			$message_list.prepend(html);
		});
	},

	updateReadMessageIcon:function(messages){
		var $message_list = this.$(".message_list");
		_.each(messages,function(v){
			var $list = $message_list.find(".chat_message[data-message-id="+v.cht_id+"]");
			$list.find(".readed_icon").html(v.readed_icon);
		},this);
	},

	clearGetMessage:function(){
		clearTimeout(this.messageTimeout);
	},




/*=====  End of Program  ======*/

/*=============================
=            Event            =
=============================*/

	onSubmitMessageBtn:function(){

		this.sendMessage();

		return false;
	},

	onChangeReadedCheck:function(e){
		var $this = $(e.currentTarget);
		var $chat_message = $this.parents(".chat_message");

		var data = {
			readed : $this.prop("checked") ? 1 : 0,
			message_id : $chat_message.data("messageId"),
		};
		this.ajax(__CHAT_READED_URL,data,function(){

		});
	},

	

/*=====  End of Event  ======*/



});