//---------------------------------------------------------------------------
// プラムページ　リスト
//---------------------------------------------------------------------------
var PlumBeriSetting = Backbone.View.extend({
	events:{
		"click #exclusion_submit":"onClickExclusionSubmit",
	},



	initialize:function(){

	},


/*=============================
=            Event            =
=============================*/
	onClickExclusionSubmit:function(){
		var $start = this.$("#exclusion_start");
		var $end = this.$("#exclusion_end");

		var s_year = $start.find(".select-year").val();
		var s_month = $start.find(".select-month").val();
		var s_day = $start.find(".select-day").val();

		var e_year = $end.find(".select-year").val();
		var e_month = $end.find(".select-month").val();
		var e_day = $end.find(".select-day").val();

		var $error = this.$(".beri_form_box .error_message");

		if(s_year && s_month && s_day && e_year && e_month && e_day){
			var startValid = _.validDate(s_year,s_month,s_day);
			var endValid = _.validDate(e_year,e_month,e_day);
			if(startValid && endValid){
				return true;
			}else{
				$error.find("p").text("選択された日付が正しくありません。ご確認ください。");
				$error.show();
			}
		}else{
			$error.find("p").text("日付は必ず入力してください。");
			$error.show();
		}

		return false;
	},

	

/*=====  End of Event  ======*/



});