// Backboneを全部入れる
//------------------------------------------------------------------------
var SCRIPTS = {};
// Backbone拡張
//------------------------------------------------------------------------
var ajax_ex = {
    xhrs:{},

    // テストをやりやすくするため、ここにajax処理をしている
    ajax:function(url,data,callBack,error){
        var self = this;
        if(self.xhrs[url]){
            self.xhrs[url].abort();
        }

        // ajaxを開始
        self.xhrs[url] = $.ajax({
            url: url,
            type: "post",
            data: data,
            dataType: "json",
            headers: {
                'X-CSRF-TOKEN': $('meta[name="csrf-token"]').attr('content')
            },
        }).done(function(res){
            // jsonの中にdataが必ずある場合のみ処理を続ける
            //if(!_.isUndefined(res["data"])){
            //if(!_.isUndefined(res["error"])){
                
                var func = _.bind(callBack, self, res);
                func();
                
            //}
            
            self.xhrs[url] = null;
        });

        // エラー時
        if(error){
            self.xhrs[url].fail(function(jqXHR, textStatus, errorThrown){
                var func = _.bind(error, self, jqXHR, textStatus, errorThrown);
                func();
                self.xhrs[url] = null;
            });
        }
    },
    
    sync: function () { return false; },
};

var backbone_ex = {
    errors:{},

    isBackEvent:false,

    getError:function(key){
        if(_.isUndefined(this.errors[key])){
            return "";
        }else{
            return this.errors[key];
        }
    },
    setError:function(key,value){
        this.errors[key] = value;
    },
    delError:function(key){
        delete this.errors[key];
    },
    hasError:function(){
        return !_.isEmpty(this.errors);
    },
    setErrorMessage:function(selector,message){
        this.$(selector).find(".error_message p").text(message);
        this.$(selector).find(".error_message").fadeIn(200);
    },
    hideErrorMessage:function(selector){
        this.$(selector).find(".error_message").fadeOut(200);
    },
    resetErrorMessage:function(){
        this.$(".error_message").hide();
    },

    toErrorMessageFirst:function(){
        var $first = this.$(".error_message:visible").first();

        if($first.size()){
            var targetY = $first.offset().top-100;
            $('html,body').animate({scrollTop: targetY}, 500, 'swing');
        }
    },

    delHiddenInput:function(selector,name){
        this.$(selector).find('.create_hidden_input_data[name="'+name+'"]').remove();
    },
    setHiddenInput:function(selector,name,value){
        var tpl = _.template('<input type="hidden" name="'+name+'" value="'+value+'" class="create_hidden_input_data">');
        this.delHiddenInput(selector,name);
        this.$(selector).append(tpl({name:name,value:value}));
    },
    onFocusChangeMoneyToNum:function(e){
        var money = $(e.currentTarget).val();
        $(e.currentTarget).val(changeNumFomatInt(money));
    },
    onBlurChangeNumToMoney:function(e){
        var num = $(e.currentTarget).val();
        num = num.replace(/[Ａ-Ｚａ-ｚ０-９]/g, function(s) {
            return String.fromCharCode(s.charCodeAt(0) - 65248);
        });
        num = changeNumFomatInt(num);
        $(e.currentTarget).val(changeNumFomatMoney(num));
    },
    onEnterDontSubmit:function(e){
        var code = e.keyCode ? e.keyCode : e.which;
        if(code === 13)
        {
           return false;
        }
    },

    backAlert:function(str){
        if(str){
            $("#backAlertModal").find(".alert_text").text(str);
        }
        $("#backAlertModal").modal("show");
    },

};


_.extend(Backbone.View.prototype, ajax_ex);
_.extend(Backbone.Model.prototype, ajax_ex);

_.extend(Backbone.View.prototype, backbone_ex);
_.extend(Backbone.Model.prototype, backbone_ex);

/**
 * 数値を金額表記へ変換
 * @param int or string num
 * @return string
 */
function changeNumFomatMoney(num){
    var str = num;
    num = Number(num);
    if (_.isUndefined(num) || _.isNull(num) || _.isNaN(num) || str=="") {
        return "0";
    }
    return String(num).replace( /(\d)(?=(\d\d\d)+(?!\d))/g, '$1,');
}

/**
 * 金額表記を数値へ変換
 * @param string money
 * @return int
 */
function changeNumFomatInt(money){
    if (_.isUndefined(money) || _.isNull(money) || money === "") {
        return "";
    }
    return parseInt(money.split(',').join('').trim());
}


//---------------------------------------------------------------------------
// Underscore拡張
//---------------------------------------------------------------------------
_.mixin({
    getUA: function() {
        return navigator.userAgent.toLowerCase();
    },
    detectBrowser: function() {
        var ua = this.getUA();
        var that = this;
        var browsers = [
            'chrome',
            'safari',
            'opera',
            'firefox'
        ];
        for (var i = 0, len = browsers.length; i < len; i++) {
            if (ua.indexOf(browsers[i]) >= 0) {
                return browsers[i];
            }
        }
    },
    isModern: function(){
        //return false;
        return typeof window.FileReader === "function";
    },
    useHistory: function(){
        return window.history && window.history.pushState;
    },
    inArray:function(array,key){
        return _.indexOf(array, key) > -1
    },
    str_replace:function($str,$pattrn,$join){
        return $str.split($pattrn).join($join);
    },
    getSuffixNumber:function(num){
        if(!_.inArray([11,12,13],num % 100)){
            switch(num % 10){
                case 1:
                    return num + 'st';
                case 2:
                    return num + 'nd';
                case 3:
                    return num + 'rd';
            }
        }
        return num + 'th';
    },
    convertImgNameToUrl:function(imgName){
        var childPath = imgName.substring(0, 2) + '/' + imgName.substring(2, 4) + '/';
        var path = childPath + imgName;
        return path;
    },
    cache_image_path:function(imgName){
        var childPath = imgName.substring(0, 2) + '/' + imgName.substring(2, 4) + '/';
        var path = childPath;
        return path;
    },
    getTpl:function(selector){
        var html = AC.tpl[selector];
        if(_.isUndefined(html)){
            html = $("#TMPL-"+selector).html();
        }
        return _.template(html);
    },
    
    getImage:function(name,w,h,mode){
        var _w = w ? w : 9999;
        var _h = h ? h : 9999;
        var path = _.convertImgNameToUrl(name);
        var size = "w"+_w+"h"+_h+mode;
        var url = "/imgs/"+size+"/"+path;
        var data = {};
        
        if(w){
            //data.width = w;
        }
        if(h){
            //data.height = h;
        }
        
        data.src = url;
        
        var $img = $("<img />",data);
        return $img;
    },
    
    getStar : function(rate){
        var html = "";
        
        var star_count = Math.floor(rate);
        var star_h_count = Math.ceil(rate) - star_count;
        var star_o_count = 5 - (star_count + star_h_count);
        
        var html = "";
        
        _.times(star_count,function(){
            html += '<span><i class="fa fa-star"></i></span>\n';
        });
        _.times(star_h_count,function(){
            html += '<span><i class="fa fa-star-half-o"></i></span>\n';
        });
        _.times(star_o_count,function(){
            html += '<i class="fa fa-star-o"></i>\n';
        });
        
        return html;
    },
    
    deferEx:function(fn,context){
        setTimeout(_.bind(fn,context),1);
    },
    
    isRange:function(num,fromNum,toNum){
        return (num >= fromNum && num <= toNum);
    },
    
    delNl:function(text){
        text = _.str_replace(text,"\n","");
        text = _.str_replace(text,"\r","");
        return text;
    },
    
    isUrl:function(check){
        var regex = /^(https?)(:\/\/[-_.!~*\'()a-zA-Z0-9;\/?:\@&=+\$,%#]+)$/;
        if (regex.test(check) == true) {
            return true;
        } else {
            return false;
        }
    },
    
    hasEmail:function(str){
        //var address = "メールkakikukeko@fine-creation.jpアドレスaiueo@test.comメール";
        var rgTxp = /[A-Za-z0-9]{1}[A-Za-z0-9_.-]*@{1}[A-Za-z0-9_.-]{1,}\.[A-Za-z0-9]{1,}/;
        return str.match(rgTxp) ? true : false;
    },

    toHankaku:function(val){
        var han = val.replace(/[Ａ-Ｚａ-ｚ０-９]/g,function(s){return String.fromCharCode(s.charCodeAt(0)-0xFEE0)});
        return han
    },

    isStrNum:function(num){
        return num.search(/^[0-9]+$/) === 0;
    },

    hasRomaNum:function(val){
        var regexp = /[Ⅰ-Ⅹ]/ig;
        var result = val.match(regexp);
        if(result){
            return true;
        }
        return false;
    },

    hasSpace:function(val){
        if(!val){
            return true;
        }

        if(val.indexOf(' ') != -1 || val.indexOf('　') != -1){
            return true;
        }
        return false;
    },

    isRomaAndKana:function(val){
        if(!val){
            return true;
        }
        //var regexp = /^[\u30a0-\u30ffa-zA-Z0-9０-９\-ー−‐―-]+$/;
        var regexp = /^[ァ-ヴa-zA-Zａ-ｚＡ-Ｚ0-9０-９\-ー‐‑–—―−－]+$/;
        var result = val.match(regexp);
        if(result){
            return true;
        }
        return false;
    },
    isRomaAndKana2:function(val){
        if(!val){
            return true;
        }
        //var regexp = /^[\u30a0-\u30ffa-zA-Z0-9０-９\-ー−‐―-]+$/;
        var regexp = /^[ァ-ヴa-zA-Z\-ー‐‑–—―−－]+$/;
        var result = val.match(regexp);
        if(result){
            return true;
        }
        return false;
    },
    isKana:function(val){
        if(!val){
            return true;
        }
        var regexp = /^[\u30a0-\u30ff]+$/;
        var result = val.match(regexp);
        if(result){
            return true;
        }
        return false;
    },
    hasZen:function(val){
        var str=val;
        for(var i=0; i<str.length; i++){
            var len=escape(str.charAt(i)).length;
            if(len>=4){
                return true;
            }
        }
        return false;
    },

    validDate:function(y,m,d){
        dt=new Date(y,m-1,d);
        return(dt.getFullYear()==y && dt.getMonth()==m-1 && dt.getDate()==d);
    },

    nl2br:function(str){
        if(str){
            if(str.replace){
                str = str.replace(/\r\n/g, "<br>");
                str = str.replace(/\n/g, "<br>");
            }
        }
        return str;
    },
    // ベリ非対応チェック、ベリマスターと、start時間と、end時間を入れるとそれでチェックする
    beriIncompatible:function(beri_master,start,end){
        var result = false;
        _(beri_master).each( function( value, key, beri_master ) {
            if(moment(start).isSameOrAfter(value.start_datetime) && moment(end).isSameOrBefore(value.end_datetime)){
                result = true;
            }
        });
        return result;
    },
});
