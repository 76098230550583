var Validator = {

	// 日付かどうか
	isDate:function(year,month,day){
		if(year && month && day){

		}else{
			return false;
		}
		return moment(year+"/"+month+"/"+day).isValid();
	},

	// 日付かどうか
	isRangeDate:function(syear,smonth,sday,eyear,emonth,eday){
		//var start = moment(syear+"-"+smonth+"-"+sday);
		//var end = moment(eyear+"-"+emonth+"-"+eday);
		// start < end
		return moment(syear+"/"+smonth+"/"+sday).isBefore(eyear+"/"+emonth+"/"+eday);
	},

	// どれか一つはtrueかどうか（主にチェックボックス系）
	isSome:function(arrays){
		return _.some(arrays);
	},

	// メールかどうか
	isMail:function(str){
		var reg = /^(([^<>()\[\]\\.,;:\s@"]+(\.[^<>()\[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;
		var old = /^([a-zA-Z0-9])+([a-zA-Z0-9\._-])*@([a-zA-Z0-9_-])+([a-zA-Z0-9\._-]+)+$/;

		if(_.hasZen(str)){
			return false;
		}

		if(str.match(reg)){
			return true;
		}else{
			return false;
		}
	},

	// 正常なナンバーかどうか
	isNumber:function(str){
		if(str.match(/[^0-9]+/)){
			return false;
		}else{
			return true;
		}
	},
}