//---------------------------------------------------------------------------
// 契約書お客様のの新規作成JS
//---------------------------------------------------------------------------
var MyContractEdit = Backbone.View.extend({
	events:{
		// フォームの送信（チェック）
		"submit #MainForm" : "onSubmit",

		// 金額のフォームを自動的にお金の表記に変更し、なおかつ、エンター押してもsubmitさせない
		"focus .money_input" : "onFocusChangeMoneyToNum",
		"blur .money_input" : "onBlurChangeNumToMoney",
		"keydown .money_input" : "onEnterDontSubmit",

		// 書面受領
		"change #got_docment_year" : "onChangeGotDocment",
		"change #got_docment_month" : "onChangeGotDocment",
		"change #got_docment_day" : "onChangeGotDocment",
		"changeDate #got_docment_calender" : "onChangeGotDocment",

		"change .birthday_select" : "onChangeBirthday",
		"changeDate .birthday_calender" : "onChangeBirthday",

		"change .save_money_input" : "onChangeSaveMoney",

/*----------  副収入  ----------*/

		"change .sub_income_content1" : "onChangeSubIncomeContent1",
		"change .sub_income_content2" : "onChangeSubIncomeContent2",

		"change .sub_income_input1" : "onChangeSubIncomeMoney1",
		"change .sub_income_input2" : "onChangeSubIncomeMoney2",

/*----------  副収入  ----------*/

		//住所を検索
		"click .zip_code_btn" : "onClickSearchZipCode",
		"click #zip_select_modal .zip_list a" : "onClickSetAdressItem",
		"blur .zip_form input" : "onBlurZipCode",

		"keyup [name='ctcus_var_main_address2']":'onClickCopyKana2',
		"blur [name='ctcus_var_main_address2']":'onClickCopyKana2',
		"keyup [name='ctcus_var_main_address3']":'onClickCopyKana3',
		"blur [name='ctcus_var_main_address3']":'onClickCopyKana3',

		"keyup [name='ctcom_var_address2']":'onClickCopyComKana2',
		"blur [name='ctcom_var_address2']":'onClickCopyComKana2',
		"keyup [name='ctcom_var_address3']":'onClickCopyComKana3',
		"blur [name='ctcom_var_address3']":'onClickCopyComKana3',

		//配偶者有無
		"change #partner_type-1" : "onChangeLiveType",
		"change #partner_type-2" : "onChangeLiveType",
		"change #partner_type-3" : "onChangeLiveType",

		// 住居形態
		"change #live_type" : "onChangeLiveType",

		"change #live_people_count_select" : "onChangePeopleCount",

		"change #maintainer_select" : "onChangeMaintainer",

		"change #now_work-1" : "onChangeNowWork",
		"change #now_work-2" : "onChangeNowWork",
		"change #now_work-3" : "onChangeNowWork",

		"change #dtl_addressee-1" : "onChangeAddressee",
		"change #dtl_addressee-2" : "onChangeAddressee",
		"change #dtl_addressee-3" : "onChangeAddressee",

		"change #beri_after_hope" : "onChangeBeriAfterHope",

		// "change .beri_select" : "onChangeBeriSelect",
		"changeDate .beri_calender" : "onChangeBeriDate",
		"change .select-date" : "onChangeBeriDate",

		"change [name='mail_address_select_domain']" : "onChangeMailDomainSelect",

		"click #copy_address_to_home_address" : "onClickCopyAddressToHomeAddress",


/*----------  チェックのイベント  ----------*/
		"change #customer_name .name2" : "onCheckStart",
		"change #customer_name_kana .name2" : "onCheckStart",
		"change #sex_area .sex" : "onCheckStart",
		"change #telphone #home_phone3" : "onCheckStart",
		"change #telphone #mobile_phone3" : "onCheckStart",
		"change #postcode .postcode" : "onCheckStart",
		"change #kanji_address .address2" : "onCheckStart",
		"change #kanji_address .address3" : "onCheckStart",
		"change #kana_address .address2" : "onCheckStart",
		"change #kana_address .address3" : "onCheckStart",
		"change #live_type_area #live_type" : "onCheckStart",
		"change #live_year .year" : "onCheckStart",
		"change #live_year .month" : "onCheckStart",
		"change #now_loan #now_loan-1" : "onCheckStart",
		"change #now_loan #now_loan-2" : "onCheckStart",
		"change #partner_radios #partner_type-1" : "onCheckStart",
		"change #partner_radios #partner_type-2" : "onCheckStart",
		"change #partner_radios #partner_type-3" : "onCheckStart",
		"change #live_people_count #live_people_count_select" : "onCheckStart",
		"change #maintainer_area #maintainer_select" : "onCheckStart",
		"change #maintainer_area #other_maintainer" : "onCheckStart",
		"change #cohabitation_householder_child_select" : "onCheckStart",
		"change #cohabitation_householder_kyoudai_select" : "onCheckStart",
		"change #cohabitation_householder_other_select" : "onCheckStart",
		"change #save_money_area .save_money_input" : "onCheckStart",
		"change #now_work .nowWork" : "onCheckStart",
		"change #now_student .nowStudent" : "onCheckStart",
		"change #parent_home_postcode .postcode" : "onCheckStart",
		"change #parent_home_address .address_kan" : "onCheckStart",
		"change #parent_home_tel #parent_home_phone3" : "onCheckStart",
		"change #send_to_detail .dtl_address" : "onCheckStart",

		"change #company_name #company_name_kana_input" : "onCheckStart",
		"change #work_service_area #work_service" : "onCheckStart",
		"change #company_postcode .postcode" : "onCheckStart",
		"change #company_kanji_address .address2" : "onCheckStart",
		"change #company_kanji_address .address3" : "onCheckStart",
		"change #company_kana_address .address2" : "onCheckStart",
		"change #company_kana_address .address3" : "onCheckStart",
		"change #company_tel #company_tel2_3" : "onCheckStart",
		"change #company_tel #company_tel2_3" : "onCheckStart",
		"change #payday #payday_input" : "onCheckStart",
		"change #year_income #year_income_input" : "onCheckStart",
		"change #work_type_area #work_type" : "onCheckStart",

		"change #sub_income_content_area1 .sub_income_content1" : "onCheckStart",
		"change #sub_work1_tel #sub_work1_3" : "onCheckStart",
		"change #sub_income_area1 .sub_income_input1" : "onCheckStart",

		"change #sub_income_content_area2 .sub_income_content2" : "onCheckStart",
		"change #sub_work2_tel #sub_work2_3" : "onCheckStart",
		"change #sub_income_area2 .sub_income_input2" : "onCheckStart",

		"change #mailAddressArea [name='mail_address_select_domain']" : "onCheckStart",
		"change #mailAddressArea [name='mail_address_input_domain']" : "onCheckStart",

	},

	$zip_select_modal:null,

	needs_parent_home:true,

	has_job:false,
	has_job2:false,
	has_add_job:false,


	initialize:function(){

		// エラーメッセージを非表示にする
		// this.resetErrorMessage();

		// 住所を検索 モーダルウィンドウ
		this.$zip_select_modal = this.$("#zip_select_modal");
		// テンプレート モーダルウィンドウの中の住所リスト
		this.zip_select_list_modal_template = _.template(this.$("#zip_select_list_modal_template").html());

		// 年齢初期チェック
		this.onChangeBirthday();

		// 居住タイプのチェック
		this.onChangeLiveType();

		// 生計人数 1の場合は、世帯主本人固定
		this.onChangePeopleCount();

		// 世帯主（その他かどうか）
		this.onChangeMaintainer();

		// 実家電話番号住所必須チェック
		this.checkParentAddressRequire();

		// 有職者かどうかで、クレジット送付先を決める
		this.onChangeNowWork();
		this.onChangeAddressee();

		this.onChangeSubIncomeContent1();
		this.onChangeSubIncomeContent2();

		this.onChangeSubIncomeMoney1();
		this.onChangeSubIncomeMoney2();

		// ベリの後日設定かどうか
		this.onChangeBeriAfterHope();

		// メールアドレス（その他かどうか）
		this.onChangeMailDomainSelect();

		// ベリ情報整える
		this.onChangeBeriDate();


		this.$("#form_submit_btn").prop("disabled",false);
	},

	onSubmit:function(){
		if(!this.checkdata(1000)){
			this.toErrorMessageFirst();
			return false;
		}

		//無職なら disabled 解除して値を送る
		if(!this.has_job){
			this.$(".group2.service_form select").prop("disabled", false);
		}

		this.$el.waitMe({effect : 'progressBar'});

		return true;
	},

	// フォームのチェック
	checkdata:function(checkNum){
		this.resetErrorMessage();
		
			// 申込年月日
			this.checkApplyDate();

			// 書面受領日
			this.checkGotDocmentDate();

		if(checkNum >= 10){
			// 氏名
			this.checkCustomerName();
		}

		if(checkNum >= 20){
			// フリガナ
			this.checkCustomerNameKana();
		}

		if(checkNum >= 30){
			// 生年月日
			this.checkBirthdayDate();
		}

		if(checkNum >= 40){
			// 電話番号
			this.checkTelphoneNum();
		}

		if(checkNum >= 50){
			// 郵便番号
			this.checkPostCodeNum();
		}

		if(checkNum >= 60){
			// 漢字住所
			this.checkKanjiAddress();
		}

		if(checkNum >= 70){
			// カナ住所
			this.checkKanaAddress();
		}
		
		if(checkNum >= 80){
			// 居住形態
			this.checkLiveType();
		}

		if(checkNum >= 90){
			// 居住年数
			this.checkLiveYear();
		}

		if(checkNum >= 100){
			// 月額負担額
			this.checkNowLoan();
		}

		if(checkNum >= 110){
			// 同一生計人数
			this.checkLivePeopleCount();
		}

		if(checkNum >= 120){
			// 世帯主
			this.checkMaintainer();
		}

		if(checkNum >= 123){
			// 同居のご家族 子供、親兄弟
			this.checkCohabitation();
		}

		if(checkNum >= 130){
			// 預貯金
			this.checkSaveMoney();
		}

		if(checkNum >= 140){
			// お勤め状況
			this.checkNowWork();
		}

		if(checkNum >= 148 && this.needs_parent_home){
			// 実家郵便番号
			this.checkParentHomePostCodeNum();
		}

		if(checkNum >= 149 && this.needs_parent_home){
			// 実家住所
			this.checkParentHomeAddress();
		}

		if(checkNum >= 150 && this.needs_parent_home){
			// 実家電話番号
			this.checkParentHomeTel();
		}

		if(checkNum >= 155){
			// クレジット利用明細書送付先
			this.checkAddressee();
		}

		/*----------  勤め先情報  ----------*/
		
		if(checkNum >= 160){
			// 会社名
			this.checkComapnyName();
		}

		if(checkNum >= 170){
			// 勤務年数
			this.checkWorkService();
		}

		if(checkNum >= 180){
			// 郵便番号
			this.checkCompanyPostCodeNum();
		}

		if(checkNum >= 190){
			// 漢字住所
			this.checkCompanyKanjiAddress();
		}

		if(checkNum >= 200){
			// カナ住所
			this.checkCompanyKanaAddress();
		}

		if(checkNum >= 210){
			// 実家電話番号
			this.checkCompanyTel();
		}

		if(checkNum >= 220){
			// 給料日
			this.checkPayDay();
		}

		if(checkNum >= 230){
			// 税込年収
			this.checkYearIncome();
		}

		if(checkNum >= 240){
			// 職業形態
			this.checkWorkType();
		}

		/*----------  副収入  ----------*/

		if(checkNum >= 250){
			// 副収入1内容
			this.checkSubIncomeContent1();
		}

		if(checkNum >= 260){
			// 副収入1電話番号
			this.checkSubWork1Tel();
		}

		if(checkNum >= 270){
			// 副収入1収入
			this.checkSubIncome1();
		}

		if(checkNum >= 280){
			// 副収入2内容
			this.checkSubIncomeContent2();
		}

		if(checkNum >= 290){
			// 副収入2電話番号
			this.checkSubWork2Tel();
		}

		if(checkNum >= 300){
			// 副収入2収入
			this.checkSubIncome2();
		}

		/*----------  副収入  ----------*/

		if(checkNum >= 310){
			// 画像アップロード
			this.checkIdentificationUpload();
		}

		if(checkNum >= 320){
			// ベリのチェック
			this.checkBeriHope();
		}

		if(checkNum >= 330){
			// ベリの確認先をチェック
			this.checkBeriType();
		}

		if(checkNum >= 340){
			if(isTablet && !isEdit){
				this.checkMailAddress();
			}
		}

		//console.log(this.errors);

		return !this.hasError();
	},


	/* 実家住所・TEL必須判定 */
	checkParentAddressRequire: function() {
		var mobile_phone1 = _.toHankaku(this.$("#mobile_phone1").val());
		var mobile_phone2 = _.toHankaku(this.$("#mobile_phone2").val());
		var mobile_phone3 = _.toHankaku(this.$("#mobile_phone3").val());

		var home_phone1 = _.toHankaku(this.$("#home_phone1").val());
		var home_phone2 = _.toHankaku(this.$("#home_phone2").val());
		var home_phone3 = _.toHankaku(this.$("#home_phone3").val());

		if(mobile_phone1 && mobile_phone2 && mobile_phone3 && home_phone1 && home_phone2 && home_phone3){
			this.$('#parent_home_postcode_hissu').hide();
			this.$('#parent_home_address_hissu').hide();
			this.$('#parent_home_tel_hissu').hide();
			this.needs_parent_home = false;
		}else{
			this.$('#parent_home_postcode_hissu').show();
			this.$('#parent_home_address_hissu').show();
			this.$('#parent_home_tel_hissu').show();
			this.needs_parent_home = true;
		}
	},
































/*===============================
=            Checker            =
===============================*/

	// 契約書作成年月日
	checkApplyDate:function(){
		// エラーのキー
		var errorKey = "applyDate";

		// まず削除
		this.delError(errorKey);

		var year = this.$("#apply_year").val();
		var month = this.$("#apply_month").val();
		var day = this.$("#apply_day").val();

		// バリデートする
		if(!Validator.isDate(year,month,day)){
			this.setError(errorKey,"正しい日付をご入力ください。");
			this.setErrorMessage("#"+errorKey,this.getError(errorKey));
			this.delHiddenInput("#"+errorKey,"ctcus_dt_apply");
		}else{
			this.setHiddenInput("#"+errorKey,"ctcus_dt_apply",year+"-"+month+"-"+day);
		}
	},

	// 書面受領日
	checkGotDocmentDate:function(){
		// エラーのキー
		var errorKey = "got_docment";

		// まず削除
		this.delError(errorKey);

		this.$("#"+errorKey).find(".error_message").hide();

		var year = this.$("#got_docment_year").val();
		var month = this.$("#got_docment_month").val();
		var day = this.$("#got_docment_day").val();

		var year2 = this.$("#apply_year").val();
		var month2 = this.$("#apply_month").val();
		var day2 = this.$("#apply_day").val();

		this.delHiddenInput("#"+"applyDate","ctcus_dt_got_docment");

		// バリデートする
		if(!Validator.isDate(year,month,day) || !_.validDate(year,month,day)){
			this.setError(errorKey,"正しい日付をご入力ください。");
			this.setErrorMessage("#"+errorKey,this.getError(errorKey));
			
		}else{

			// お申込み日が正常かどうか
			if(Validator.isDate(year2,month2,day2)){
				if(moment(year2+"/"+month2+"/"+day2).isBefore(year+"/"+month+"/"+day)){
					this.setError(errorKey,"申込年月日以降の日付は、選択できません。");
					this.setErrorMessage("#"+errorKey,this.getError(errorKey));
				}else{
					//this.setHiddenInput("#"+errorKey,"ctcus_dt_got_docment",year+"-"+month+"-"+day);
					this.setHiddenInput("#"+"applyDate","ctcus_dt_got_docment",year+"-"+month+"-"+day);
				}
			}
		}

	},

	// 氏名
	checkCustomerName:function(){
		// エラーのキー
		var errorKey = "customer_name";

		// まず削除
		this.delError(errorKey);

		var name1 = this.$("#customer_name").find(".name1").val();
		var name2 = this.$("#customer_name").find(".name2").val();

		if(!isApproval){
			// バリデートする
			if(!name1 || !name2){
				this.setError(errorKey,"氏名は必ずご入力ください。");
				this.setErrorMessage("#"+errorKey,this.getError(errorKey));
			}else{
			}
		}
	},

	// フリガナ
	checkCustomerNameKana:function(){
		// エラーのキー
		var errorKey = "customer_name_kana";

		// まず削除
		this.delError(errorKey);

		var name1 = this.$("#customer_name_kana").find(".name1").val();
		var name2 = this.$("#customer_name_kana").find(".name2").val();

		if(!isApproval){
			// バリデートする
			if(!name1 || !name2){
				this.setError(errorKey,"フリガナは必ずご入力ください。");
				this.setErrorMessage("#"+errorKey,this.getError(errorKey));
			}else{
				if(!_.isKana(name1+name2)){
					this.setError(errorKey,"フリガナはすべて全角カタカナでご入力ください。");
					this.setErrorMessage("#"+errorKey,this.getError(errorKey));
				}
			}
		}
	},

	// 生年月日
	checkBirthdayDate:function(){
		// エラーのキー
		var errorKey = "birthday";

		// まず削除
		this.delError(errorKey);

		var year = this.$("#birthday_year").val();
		var month = this.$("#birthday_month").val();
		var day = this.$("#birthday_day").val();

		if(!isApproval){
			// バリデートする
			if(!Validator.isDate(year,month,day) || !_.validDate(year,month,day)){
				this.setError(errorKey,"正しい日付をご入力ください。");
				this.setErrorMessage("#"+errorKey,this.getError(errorKey));
				this.delHiddenInput("#"+errorKey,"ctcus_dt_birthday");
			}else{
				$birth = moment(year+"/"+month+"/"+day);
				$today = moment();

				// 今日から、誕生日を入れた差
				var $age = moment($today).diff($birth,"year");

				// 未成年または、65以下はだめ
				if($age >= minAge && $age < maxAge+1){
					this.setHiddenInput("#"+errorKey,"ctcus_dt_birthday",year+"-"+month+"-"+day);
				}else{
					this.setError(errorKey,"受付年齢対象外です");
					this.setErrorMessage("#"+errorKey,this.getError(errorKey));
					this.delHiddenInput("#"+errorKey,"ctcus_dt_birthday");
				}
			}
		}
	},

	// 電話番号のチェック
	checkTelphoneNum:function(){
		// エラーのキー
		var errorKey = "telphone";

		// まず削除
		this.delError(errorKey);

		var mobile_phone1 = _.toHankaku(this.$("#mobile_phone1").val());
		var mobile_phone2 = _.toHankaku(this.$("#mobile_phone2").val());
		var mobile_phone3 = _.toHankaku(this.$("#mobile_phone3").val());

		var home_phone1 = _.toHankaku(this.$("#home_phone1").val());
		var home_phone2 = _.toHankaku(this.$("#home_phone2").val());
		var home_phone3 = _.toHankaku(this.$("#home_phone3").val());

		var mobile_nums = ""+mobile_phone1+"-"+mobile_phone2+"-"+mobile_phone3;
		var home_nums = ""+home_phone1+"-"+home_phone2+"-"+home_phone3;

		var mobile = (function(){
			if(mobile_phone1 && mobile_phone2 && mobile_phone3){
				var num = ""+mobile_phone1+mobile_phone2+mobile_phone3;
				return _.isStrNum(num);
			}
			return false;
		})();

		var home = (function(){
			if(home_phone1 && home_phone1 && home_phone1){
				var num = ""+home_phone1+home_phone1+home_phone1;
				return _.isStrNum(num);
			}
			return false;
		})();


		if(mobile || home){
			var mobile_error = false;
			var home_error = false;

			if(mobile){
				if(mobile_nums.length==13 && !_.hasSpace(mobile_nums)){
					this.setHiddenInput("#"+errorKey,"ctcus_var_portable_tel",mobile_nums);
				}else{
					mobile_error = true;
				}
			}else{
				if(_.hasSpace(mobile_nums)){
					mobile_error = true;
				}
			}
			if(home){
				if(home_nums.length==12 && !_.hasSpace(home_nums)){
					this.setHiddenInput("#"+errorKey,"ctcus_var_home_tel",home_nums);
				}else{
					home_error = true;
				}
			}else{
				if(_.hasSpace(home_nums)){
					home_error = true;
				}
			}
			if(home_error){
				if(mobile_error){
					if(_.hasSpace(home_nums)){
						this.setError(errorKey,"固定電話に空白が入ってあります。");
					}else if(_.hasSpace(mobile_nums)){
						this.setError(errorKey,"携帯番号に空白が入ってあります。");
					}else{
						this.setError(errorKey,"固定電話、携帯番号の番号桁数が正しくありません。");
					}
					
				}else{
					if(_.hasSpace(home_nums)){
						this.setError(errorKey,"固定電話に空白が入ってあります。");
					}else{
						this.setError(errorKey,"固定電話の番号桁数が正しくありません");
					}
				}
				this.setErrorMessage("#"+errorKey,this.getError(errorKey));
				this.delHiddenInput("#"+errorKey,"ctcus_var_portable_tel");
				this.delHiddenInput("#"+errorKey,"ctcus_var_home_tel");
			}
			if(!home_error && mobile_error){
				if(_.hasSpace(mobile_nums)){
					this.setError(errorKey,"携帯番号に空白が入ってあります。");
				}else{
					this.setError(errorKey,"携帯番号の番号桁数が正しくありません");
				}
				this.setErrorMessage("#"+errorKey,this.getError(errorKey));
				this.delHiddenInput("#"+errorKey,"ctcus_var_portable_tel");
				this.delHiddenInput("#"+errorKey,"ctcus_var_home_tel");
			}
		}else{
			if(_.hasSpace(home_nums)){
				this.setError(errorKey,"固定電話に空白が入ってあります。");
			}else if(_.hasSpace(mobile_nums)){
				this.setError(errorKey,"携帯番号に空白が入ってあります。");
			}else{
				this.setError(errorKey,"固定電話、または、携帯番号は必ずどちらかは正確にご入力ください。");
			}
			this.setErrorMessage("#"+errorKey,this.getError(errorKey));
			this.delHiddenInput("#"+errorKey,"ctcus_var_portable_tel");
			this.delHiddenInput("#"+errorKey,"ctcus_var_home_tel");
		}

		this.checkParentAddressRequire();

	},

	// 郵便番号
	checkPostCodeNum:function(){
		// エラーのキー
		var errorKey = "postcode";

		// まず削除
		this.delError(errorKey);

		var postcode = this.$("#postcode").find(".postcode").val();

		// バリデートする
		if(!postcode){
			this.setError(errorKey,"郵便番号は必ずご入力ください");
			this.setErrorMessage("#"+errorKey,this.getError(errorKey));
		}else{
			postcode = _.toHankaku(postcode);
			if(postcode.length === 7 && _.isStrNum(postcode)){
				this.$("#postcode").find(".postcode").val(postcode);
			}else{
				this.setError(errorKey,"郵便番号は必ず正確なものをご入力ください。");
				this.setErrorMessage("#"+errorKey,this.getError(errorKey));
			}

		}
	},

	// 漢字住所
	checkKanjiAddress:function(){
		// エラーのキー
		var errorKey = "kanji_address";

		// まず削除
		this.delError(errorKey);

		var address1 = this.$("#kanji_address").find(".address1").val();
		var address2 = this.$("#kanji_address").find(".address2").val();
		var address3 = this.$("#kanji_address").find(".address3").val();

		// バリデートする
		if(!address1 || !address2){
			this.setError(errorKey,"ご住所は必ずご入力ください");
			this.setErrorMessage("#"+errorKey,this.getError(errorKey));
			if(!address1){
				// エラーのキー
				var errorKey_pc = "postcode";
				// まず削除
				this.delError(errorKey_pc);
				this.setError(errorKey_pc,"必ず郵便番号をご記入の上、住所を検索してください。");
				this.setErrorMessage("#"+errorKey_pc,this.getError(errorKey_pc));
			}
		}else{
			
			if(_.hasRomaNum(address2) || _.hasRomaNum(address3)){
				this.setError(errorKey,"住所にローマ数字は利用出来ません。");
				this.setErrorMessage("#"+errorKey,this.getError(errorKey));
			}
		}
	},

	// カナ住所
	checkKanaAddress:function(){
		// エラーのキー
		var errorKey = "kana_address";

		// まず削除
		this.delError(errorKey);

		var address1 = this.$("#kana_address").find(".address1").val();
		var address2 = this.$("#kana_address").find(".address2").val();
		var address3 = this.$("#kana_address").find(".address3").val();

		// バリデートする
		if(!address1 || !address2){
			this.setError(errorKey,"カナご住所は必ずご入力ください");
			this.setErrorMessage("#"+errorKey,this.getError(errorKey));
		}else{
			
			if(_.hasRomaNum(address2) || _.hasRomaNum(address3)){
				this.setError(errorKey,"カナ住所にローマ数字は利用出来ません。（記号・スペース・ローマ数字[Ⅳ等]入力不可）");
				this.setErrorMessage("#"+errorKey,this.getError(errorKey));
			}

			if(!_.isRomaAndKana(address2) || !_.isRomaAndKana(address3)){
				this.setError(errorKey,"全角カナと英数字以外はご入力出来ません。（記号・スペース・ローマ数字[Ⅳ等]入力不可）");
				this.setErrorMessage("#"+errorKey,this.getError(errorKey));
			}
		}
	},

	// 居住形態
	checkLiveType:function(){
		// エラーのキー
		var errorKey = "live_type_area";

		// まず削除
		this.delError(errorKey);

		var val = this.$("#live_type_area").find("#live_type").val();

		if(!isApproval){
			// バリデートする
			if(!val){
				this.setError(errorKey,"居住形態は必ず選択してください。");
				this.setErrorMessage("#"+errorKey,this.getError(errorKey));
			}else{
			}
		}

	},

	// 居住年数
	checkLiveYear:function(){
		// エラーのキー
		var errorKey = "live_year";

		// まず削除
		this.delError(errorKey);

		var year = this.$("#live_year").find(".year").val();
		var month = this.$("#live_year").find(".month").val();

		// バリデートする
		if(!year && !month){
			this.setError(errorKey,"居住年数は正しく入力してください。");
			this.setErrorMessage("#"+errorKey,this.getError(errorKey));
		}else{
			if(year==""){
				year = "0";
			}
			if(month==""){
				month = "0";
			}

			if(_.isStrNum(year) && _.isStrNum(month)){

				var chkMonth = (function(){
					if(Number(month) < 12){
						return true;
					}else{
						return false;
					}
				})();

				if(chkMonth){

					var byear = this.$("#birthday_year").val();
					var bmonth = this.$("#birthday_month").val();
					var bday = this.$("#birthday_day").val();

					if(isApproval){
						var date = this.$("#birthday_hidden_input").val();
						var m = moment(date);
						byear = m.year();
						bmonth = m.month()+1;
						bday = m.date();
					}

					if(byear && bmonth && bday){
						var birth = moment(byear+"/"+bmonth+"/"+bday);
					
						var today = moment();
						today = today.subtract(Number(year),"year");
						today = today.subtract(Number(month),"month");
						if(Number(today.format("YYYYMMDD")) >= birth.format("YYYYMMDD")){

						}else{
							this.setError(errorKey,"ご自身の年齢より、居住年数が越えています。正確な居住年数をご記入ください。");
							this.setErrorMessage("#"+errorKey,this.getError(errorKey));
						}
						
					}else{
						this.setError(errorKey,"先に生年月日をご入力ください。");
						this.setErrorMessage("#"+errorKey,this.getError(errorKey));
					}

				}else{
					this.setError(errorKey,"居住年数の「月」は、入力は11ヶ月以内です。");
					this.setErrorMessage("#"+errorKey,this.getError(errorKey));
				}

			}else{
				this.setError(errorKey,"居住年数は数値のみ入力可能です。");
				this.setErrorMessage("#"+errorKey,this.getError(errorKey));
			}

		}

	},

	// 月額負担額
	checkNowLoan:function(){
		// エラーのキー
		var errorKey = "now_loan_fee";

		// まず削除
		this.delError(errorKey);

		// var val = this.$("#now_loan_fee_input").val();

		// バリデートする
		/*
		if(!val){
			this.setError(errorKey,"月額負担額は必ず記述してください。");
			this.setErrorMessage("#"+errorKey,this.getError(errorKey));
			this.delHiddenInput("#"+errorKey,"ctcus_int_loan_month_price");
		}else{
			val = changeNumFomatInt(val);
			this.setHiddenInput("#"+errorKey,"ctcus_int_loan_month_price",val);
		}
		*/
	},

	// 同一生計人数
	checkLivePeopleCount:function(){
		// エラーのキー
		var errorKey = "live_people_count";

		// まず削除
		this.delError(errorKey);

		var val = this.$("#live_people_count").find("#live_people_count_select").val();

		if(!isApproval){
			// バリデートする
			if(!val){
				this.setError(errorKey,"同一生計人数は必ず選択してください。");
				this.setErrorMessage("#"+errorKey,this.getError(errorKey));
			}else{
				var live_type = this.$("#live_type").val();
				if(live_type==8){
					this.setHiddenInput("#"+errorKey,"ctcus_tint_live_people_count",1);
				}else{
					this.delHiddenInput("#"+errorKey,"ctcus_tint_live_people_count");
				}
			}
		}

	},

	// 世帯主チェック
	checkMaintainer:function(){
		// エラーのキー
		var errorKey = "maintainer_area";

		// まず削除
		this.delError(errorKey);

		var val = this.$("#maintainer_select").val();

		// パートナーがいるかどうか
		var no_partner = this.$("#partner_type-1").prop("checked");
		var has_partner1 = this.$("#partner_type-2").prop("checked");
		var has_partner2 = this.$("#partner_type-3").prop("checked");

		if(!isApproval){
			// バリデートする
			if(no_partner){
				if(val==="2"){
					this.setError(errorKey,"配偶者が「無し」の場合、世帯主を「配偶者」に選択する事はできません。");
					this.setErrorMessage("#"+errorKey,this.getError(errorKey));
				}
			}
			if(has_partner1 || has_partner2){
				if(val==="1" || val==="2"){
					
				}else{
					this.setError(errorKey,"配偶者が「有り」の場合、世帯主は「本人」また「配偶者」のみ選択可能です。");
					this.setErrorMessage("#"+errorKey,this.getError(errorKey));
				}
			}

			if(val==="9"){
				if(!this.$("#other_maintainer").val()){
					this.setError(errorKey,"その他の場合は必ずその他入力欄に世帯主の続き柄（叔父など）をご入力して下さい。");
					this.setErrorMessage("#"+errorKey,this.getError(errorKey));
				}
			}else{
			}
		}

	},

	// 同居のご家族
	checkCohabitation:function(){
		// エラーのキー
		var errorKey = "live_in_maintainer";

		// まず削除
		this.delError(errorKey);

		// パートナーがいるかどうか
		var live_in_maintainer_1 = this.$("#live_in_maintainer-1").prop("checked");
		var live_in_maintainer_2 = this.$("#live_in_maintainer-2").prop("checked");

		if(!isApproval){
			// バリデートする
			if(!live_in_maintainer_1 && !live_in_maintainer_2){
				this.setError(errorKey,"同居のご家族配偶者の有無は必ず選択してください");
				this.setErrorMessage("#"+errorKey,this.getError(errorKey));
			}
		}

	},

	checkSaveMoney:function(){
		// エラーのキー
		var errorKey = "save_money_area";

		// まず削除
		this.delError(errorKey);

		var check = this.$("#save_money_area").find(".save_money_input").val();

		if(!isApproval){
			// バリデートする
			if(check){
				if(_.isStrNum(check)){
				}else{
					this.setError(errorKey,"税込年収は整数（万単位）のみ入力可能です。小数点以下は入力出来ません。");
					this.setErrorMessage("#"+errorKey,this.getError(errorKey));
				}
			}else{
			}
		}
	},

	// お勤め状況（有職、無職）
	checkNowWork:function(){
		// エラーのキー
		var errorKey = "now_work";

		// まず削除
		this.delError(errorKey);

		var check1 = this.$("#now_work-1").prop("checked");
		var check2 = this.$("#now_work-2").prop("checked");
		var check3 = this.$("#now_work-3").prop("checked");

		if(!isApproval){
			// バリデートする
			if(!check1 && !check2 && !check3){
				this.setError(errorKey,"お勤め状況は必ず選択してください。");
				this.setErrorMessage("#"+errorKey,this.getError(errorKey));
			}else{
			}
		}
	},

	// 実家郵便番号
	checkParentHomePostCodeNum:function(){
		// エラーのキー
		var errorKey = "parent_home_postcode";

		// まず削除
		this.delError(errorKey);

		var postcode = this.$("#parent_home_postcode .postcode").val();

		// バリデートする
		if(this.needs_parent_home){
			if(!postcode){
				this.setError(errorKey,"郵便番号は必ずご入力ください");
				this.setErrorMessage("#"+errorKey,this.getError(errorKey));
			}else{
				postcode = _.toHankaku(postcode);
				if(_.isStrNum(postcode)){
					
				}else{
					this.setError(errorKey,"郵便番号は必ず正確なものをご入力ください。");
					this.setErrorMessage("#"+errorKey,this.getError(errorKey));
				}
			}
		}else{
			if(postcode){
				postcode = _.toHankaku(postcode);
				if(_.isStrNum(postcode)){
					
				}else{
					this.setError(errorKey,"郵便番号は必ず正確なものをご入力ください。");
					this.setErrorMessage("#"+errorKey,this.getError(errorKey));
				}
			}
		}
	},

	// 実家住所
	checkParentHomeAddress:function(){
		// エラーのキー
		var errorKey = "parent_home_address";

		// まず削除
		this.delError(errorKey);

		var address = this.$("#parent_home_address .address_kan").val();

		// バリデートする
		if(this.needs_parent_home && !address){
			this.setError(errorKey,"ご住所は必ずご入力ください。");
			this.setErrorMessage("#"+errorKey,this.getError(errorKey));
		}
	},

	// 実家電話番号
	checkParentHomeTel:function(){
		// エラーのキー
		var errorKey = "parent_home_tel";

		// まず削除
		this.delError(errorKey);

		var phone1 = this.$("#parent_home_phone1").val();
		var phone2 = this.$("#parent_home_phone2").val();
		var phone3 = this.$("#parent_home_phone3").val();

		this.delHiddenInput("#"+errorKey,"ctcus_var_parent_home_tel");

		var phone = ""+phone1+"-"+phone2+"-"+phone3;

		// バリデートする
		if(phone1 || phone2 || phone3){
			if(phone1 && phone2 && phone3 && (phone.length==12 || phone.length==13)){
				if(_.isStrNum(phone1) && _.isStrNum(phone2) && _.isStrNum(phone3) && !_.hasSpace(phone)){
					this.setHiddenInput("#"+errorKey,"ctcus_var_parent_home_tel",phone);
				}else{
					if(_.hasSpace(phone)){
						this.setError(errorKey,"電話番号に空白が入っています。");
					}else{
						this.setError(errorKey,"電話番号が正しくありません。ご確認ください。");
					}
					this.setErrorMessage("#"+errorKey,this.getError(errorKey));
				}
			}else{
				if(_.hasSpace(phone)){
					this.setError(errorKey,"電話番号に空白が入っています。");
				}else{
					this.setError(errorKey,"電話番号が正しくありません。ご確認ください。");
				}
				this.setErrorMessage("#"+errorKey,this.getError(errorKey));
			}
		}else{
		}

		if(this.needs_parent_home){
			if(!phone1 || !phone2 || !phone3){
				this.setError(errorKey,"電話番号は必ずご入力ください。");
				this.setErrorMessage("#"+errorKey,this.getError(errorKey));
			}
		}
	},

	// クレジット利用明細書送付先
	checkAddressee:function(){
		// エラーのキー
		var errorKey = "send_to_detail";

		// まず削除
		this.delError(errorKey);

		var home = this.$("#dtl_addressee-1").prop("checked");
		var company = this.$("#dtl_addressee-2").prop("checked");
		var counter = this.$("#dtl_addressee-3").prop("checked");

		var size = this.$("#dtl_addressee-1").size() + this.$("#dtl_addressee-2").size() + this.$("#dtl_addressee-3").size();

		var checkFlg = true;

		if(size == 1){
			if(this.$("#dtl_addressee-1").attr("type") == "hidden"){
				checkFlg = false;
			}
			if(this.$("#dtl_addressee-2").attr("type") == "hidden"){
				checkFlg = false;
			}
			if(this.$("#dtl_addressee-3").attr("type") == "hidden"){
				checkFlg = false;
			}
		}

		if(checkFlg){
			if(!home && !company && !counter){
				this.setError(errorKey,"クレジット利用明細書送付先は必ずどれか一つは選択してください。");
				this.setErrorMessage("#"+errorKey,this.getError(errorKey));
			}
		}
	},

	// 有職（会社名）
	checkComapnyName:function(){
		// エラーのキー
		var errorKey = "company_name";

		// まず削除
		this.delError(errorKey);

		var name = this.$("#company_name_input").val();
		var kana = this.$("#company_name_kana_input").val();

		// バリデートする
		if(this.has_job){
			if(name && kana){
				if(!_.isRomaAndKana(kana)){
					this.setError(errorKey,"会社名フリガナは、カタカナと半角英数のみ入力可能です。（記号・スペース・ローマ数字[Ⅳ等]入力不可）");
					this.setErrorMessage("#"+errorKey,this.getError(errorKey));
				}
			}else{
				this.setError(errorKey,"会社名を記述してください。");
				this.setErrorMessage("#"+errorKey,this.getError(errorKey));
			}
		}
	},

	// 有職（勤務年数）
	checkWorkService:function(){
		// エラーのキー
		var errorKey = "work_service_area";

		// まず削除
		this.delError(errorKey);

		var num = this.$("#work_service").val();

		// バリデートする
		if(this.has_job){
			if(num){

			}else{
				this.setError(errorKey,"勤務年数は必ず選択してください。");
				this.setErrorMessage("#"+errorKey,this.getError(errorKey));
			}
		}
	},

	// 有職（郵便番号）
	checkCompanyPostCodeNum:function(){
		// エラーのキー
		var errorKey = "company_postcode";

		// まず削除
		this.delError(errorKey);

		var postcode = this.$("#company_postcode").find(".postcode").val();

		// バリデートする
		if(this.has_add_job){
			if(!postcode){
				this.setError(errorKey,"郵便番号は必ずご入力ください");
				this.setErrorMessage("#"+errorKey,this.getError(errorKey));
			}else{
				postcode = _.toHankaku(postcode);
				if(_.isStrNum(postcode)){
					
				}else{
					this.setError(errorKey,"郵便番号は必ず正確なものをご入力ください。");
					this.setErrorMessage("#"+errorKey,this.getError(errorKey));
				}
			}
		}else{
			if(postcode){
				postcode = _.toHankaku(postcode);
				if(_.isStrNum(postcode)){
					
				}else{
					this.setError(errorKey,"郵便番号は必ず正確なものをご入力ください。");
					this.setErrorMessage("#"+errorKey,this.getError(errorKey));
				}
			}
		}
	},

	// 有職（漢字住所）
	checkCompanyKanjiAddress:function(){
		// エラーのキー
		var errorKey = "company_kanji_address";

		// まず削除
		this.delError(errorKey);

		var address1 = this.$("#company_kanji_address").find(".address1").val();
		var address2 = this.$("#company_kanji_address").find(".address2").val();
		var address3 = this.$("#company_kanji_address").find(".address3").val();

		// バリデートする
		if(this.has_add_job){
			if(!address1 || !address2){
				this.setError(errorKey,"ご住所は必ずご入力ください");
				this.setErrorMessage("#"+errorKey,this.getError(errorKey));
			}else{
				
				if(_.hasRomaNum(address2) || _.hasRomaNum(address3)){
					this.setError(errorKey,"住所にローマ数字は利用出来ません。");
					this.setErrorMessage("#"+errorKey,this.getError(errorKey));
				}
			}
		}else{
			if(_.hasRomaNum(address2) || _.hasRomaNum(address3)){
				this.setError(errorKey,"住所にローマ数字は利用出来ません。");
				this.setErrorMessage("#"+errorKey,this.getError(errorKey));
			}
		}
	},

	// 有職（カナ住所）
	checkCompanyKanaAddress:function(){
		// エラーのキー
		var errorKey = "company_kana_address";

		// まず削除
		this.delError(errorKey);

		var address1 = this.$("#company_kana_address").find(".address1").val();
		var address2 = this.$("#company_kana_address").find(".address2").val();
		var address3 = this.$("#company_kana_address").find(".address3").val();

		// バリデートする
		if(this.has_add_job){
			if(!address1 || !address2){
				this.setError(errorKey,"カナご住所は必ずご入力ください");
				this.setErrorMessage("#"+errorKey,this.getError(errorKey));
			}else{
				
				if(_.hasRomaNum(address2) || _.hasRomaNum(address3)){
					this.setError(errorKey,"カナ住所にローマ数字は利用出来ません。（記号・スペース・ローマ数字[Ⅳ等]入力不可）");
					this.setErrorMessage("#"+errorKey,this.getError(errorKey));
				}

				if(!_.isRomaAndKana(address2) || !_.isRomaAndKana(address3)){
					this.setError(errorKey,"全角カナと英数字以外はご入力出来ません。（記号・スペース・ローマ数字[Ⅳ等]入力不可）");
					this.setErrorMessage("#"+errorKey,this.getError(errorKey));
				}
			}
		}else{
			if(_.hasRomaNum(address2) || _.hasRomaNum(address3)){
				this.setError(errorKey,"カナ住所にローマ数字は利用出来ません。（記号・スペース・ローマ数字[Ⅳ等]入力不可）");
				this.setErrorMessage("#"+errorKey,this.getError(errorKey));
			}

			if(!_.isRomaAndKana(address2) || !_.isRomaAndKana(address3)){
				this.setError(errorKey,"全角カナと英数字以外はご入力出来ません。（記号・スペース・ローマ数字[Ⅳ等]入力不可）");
				this.setErrorMessage("#"+errorKey,this.getError(errorKey));
			}
		}
	},

	// 有職（電話番号）
	checkCompanyTel:function(){
		// エラーのキー
		var errorKey = "company_tel";

		// まず削除
		this.delError(errorKey);

		var phone1 = this.$("#company_tel2_1").val();
		var phone2 = this.$("#company_tel2_2").val();
		var phone3 = this.$("#company_tel2_3").val();

		this.delHiddenInput("#"+errorKey,"ctcom_var_tel2");

		var phone = ""+phone1+"-"+phone2+"-"+phone3;

		if(phone1 && phone2 && phone3 && (phone.length==12 || phone.length==13)){
			if(_.isStrNum(phone1) && _.isStrNum(phone2) && _.isStrNum(phone3) && !_.hasSpace(phone)){
				this.setHiddenInput("#"+errorKey,"ctcom_var_tel2",phone);
			}else{
				if(_.hasSpace(phone)){
					this.setError(errorKey,"電話番号に空白が入っています。");
				}else{
					this.setError(errorKey,"電話番号が正しくありません。ご確認ください。");
				}
				this.setErrorMessage("#"+errorKey,this.getError(errorKey));
			}
		}else{
			if(this.has_job){
				if(_.hasSpace(phone)){
					this.setError(errorKey,"電話番号に空白が入っています。");
				}else{
					this.setError(errorKey,"電話番号が正しくありません。ご確認ください。");
				}
				this.setErrorMessage("#"+errorKey,this.getError(errorKey));
			}else{
				if(_.hasSpace(phone)){
					this.setError(errorKey,"電話番号に空白が入っています。");
					this.setErrorMessage("#"+errorKey,this.getError(errorKey));
				}
			}
		}

	},

	// 有職（給料日）
	checkPayDay:function(){
		// エラーのキー
		var errorKey = "payday";

		// まず削除
		this.delError(errorKey);

		var num = this.$("#payday_input").val();

		// バリデートする
		if(this.has_job){
			if(num){

			}else{
				this.setError(errorKey,"給料日は必ず入力してください。");
				this.setErrorMessage("#"+errorKey,this.getError(errorKey));
			}
		}
	},

	// 有職（税込年収）
	checkYearIncome:function(){
		// エラーのキー
		var errorKey = "year_income";

		// まず削除
		this.delError(errorKey);

		var num = this.$("#year_income_input").val();

		if(!isApproval){
			// バリデートする
			if(this.has_job || this.has_job2){
				if(num){

					if(!_.isStrNum(num)){
						this.setError(errorKey,"税込年収は整数（万単位）のみ入力可能です。小数点以下は入力出来ません。");
						this.setErrorMessage("#"+errorKey,this.getError(errorKey));
					}

				}else{
					this.setError(errorKey,"税込年収は必ず入力してください。");
					this.setErrorMessage("#"+errorKey,this.getError(errorKey));
				}
			}
		}
	},

	// 有職（職業形態）
	checkWorkType:function(){
		// エラーのキー
		var errorKey = "work_type_area";

		// まず削除
		this.delError(errorKey);

		var num = this.$("#work_type").val();

		// バリデートする
		if(this.has_job){
			if(num){

			}else{
				this.setError(errorKey,"職業形態は必ず選択してください。");
				this.setErrorMessage("#"+errorKey,this.getError(errorKey));
			}
		}
	},

	checkSubIncomeContent1:function(){
		// エラーのキー
		var errorKey = "sub_income_content_area1";

		// まず削除
		this.delError(errorKey);

		var income = this.$("#sub_income_area1").find(".sub_income_input1").val();
		var content = this.$(".sub_income_content1").val();

		// バリデートする
		if(income){
			if(content==""){
				this.setError(errorKey,"会社名または収入内容は、必ず入力してください");
				this.setErrorMessage("#"+errorKey,this.getError(errorKey));
			}else{

			}
		}else{
		}
	},

	checkSubIncomeContent2:function(){
		// エラーのキー
		var errorKey = "sub_income_content_area2";

		// まず削除
		this.delError(errorKey);

		var income = this.$("#sub_income_area2").find(".sub_income_input2").val();
		var content = this.$(".sub_income_content2").val();

		// バリデートする
		if(income){
			if(content==""){
				this.setError(errorKey,"会社名または収入内容は、必ず入力してください");
				this.setErrorMessage("#"+errorKey,this.getError(errorKey));
			}else{

			}
		}else{
		}
	},

	checkSubIncome1:function(){
		// エラーのキー
		var errorKey = "sub_income_area1";

		// まず削除
		this.delError(errorKey);

		var check = this.$("#sub_income_area1").find(".sub_income_input1").val();
		var content = this.$(".sub_income_content1").val();

		// バリデートする
		if(content){
			if(check==""){
				this.setError(errorKey,"副収入税込年額は、必ず入力してください");
				this.setErrorMessage("#"+errorKey,this.getError(errorKey));
			}else{
				if(_.isStrNum(check)){
				}else{
					this.setError(errorKey,"副収入税込年額は整数（万単位）のみ入力可能です。小数点以下は入力出来ません。");
					this.setErrorMessage("#"+errorKey,this.getError(errorKey));
				}
			}
		}else{
		}
	},

	checkSubIncome2:function(){
		// エラーのキー
		var errorKey = "sub_income_area2";

		// まず削除
		this.delError(errorKey);

		var check = this.$("#sub_income_area2").find(".sub_income_input2").val();
		var content = this.$(".sub_income_content2").val();

		// バリデートする
		if(content){
			if(check==""){
				this.setError(errorKey,"副収入税込年額は、必ず入力してください");
				this.setErrorMessage("#"+errorKey,this.getError(errorKey));
			}else{
				if(_.isStrNum(check)){
				}else{
					this.setError(errorKey,"副収入税込年額は整数（万単位）のみ入力可能です。小数点以下は入力出来ません。");
					this.setErrorMessage("#"+errorKey,this.getError(errorKey));
				}
			}
		}else{
		}
	},

	// 副収入1電話番号
	checkSubWork1Tel:function(){
		// エラーのキー
		var errorKey = "sub_work1_tel";

		// まず削除
		this.delError(errorKey);

		var phone1 = this.$("#sub_work1_1").val();
		var phone2 = this.$("#sub_work1_2").val();
		var phone3 = this.$("#sub_work1_3").val();

		this.delHiddenInput("#"+errorKey,"ctcus_var_subwork1_tel");

		var phone = ""+phone1+"-"+phone2+"-"+phone3;

		// バリデートする
		if(phone1 || phone2 || phone3){
			if(phone1 && phone2 && phone3 && (phone.length==12 || phone.length==13)){
				if(_.isStrNum(phone1) && _.isStrNum(phone2) && _.isStrNum(phone3) && !_.hasSpace(phone)){
					this.setHiddenInput("#"+errorKey,"ctcus_var_subwork1_tel",phone);
				}else{
					if(_.hasSpace(phone)){
						this.setError(errorKey,"電話番号に空白が入っています。");
					}else{
						this.setError(errorKey,"電話番号が正しくありません。ご確認ください。");
					}
					this.setErrorMessage("#"+errorKey,this.getError(errorKey));
				}
			}else{
				if(_.hasSpace(phone)){
					this.setError(errorKey,"電話番号に空白が入っています。");
				}else{
					this.setError(errorKey,"電話番号が正しくありません。ご確認ください。");
				}
				this.setErrorMessage("#"+errorKey,this.getError(errorKey));
			}
		}else{
		}
	},
	// 副収入2電話番号
	checkSubWork2Tel:function(){
		// エラーのキー
		var errorKey = "sub_work2_tel";

		// まず削除
		this.delError(errorKey);

		var phone1 = this.$("#sub_work2_1").val();
		var phone2 = this.$("#sub_work2_2").val();
		var phone3 = this.$("#sub_work2_3").val();

		this.delHiddenInput("#"+errorKey,"ctcus_var_subwork2_tel");

		var phone = ""+phone1+"-"+phone2+"-"+phone3;
		// バリデートする
		if(phone1 || phone2 || phone3){
			if(phone1 && phone2 && phone3 && (phone.length==12 || phone.length==13)){
				if(_.isStrNum(phone1) && _.isStrNum(phone2) && _.isStrNum(phone3) && !_.hasSpace(phone)){
					this.setHiddenInput("#"+errorKey,"ctcus_var_subwork2_tel",phone);
				}else{
					if(_.hasSpace(phone)){
						this.setError(errorKey,"電話番号に空白が入っています。");
					}else{
						this.setError(errorKey,"電話番号が正しくありません。ご確認ください。");
					}
					this.setErrorMessage("#"+errorKey,this.getError(errorKey));
				}
			}else{
				if(_.hasSpace(phone)){
					this.setError(errorKey,"電話番号に空白が入っています。");
				}else{
					this.setError(errorKey,"電話番号が正しくありません。ご確認ください。");
				}
				this.setErrorMessage("#"+errorKey,this.getError(errorKey));
			}
		}else{
		}
	},

	checkIdentificationUpload:function(){
		// エラーのキー
		var errorKey = "identification";

		// まず削除
		this.delError(errorKey);

		var date = this.$("#identification .image-uploader .id_data");

		if(date.size()){

		}else{
			this.setError(errorKey,"身分証は、必ず1枚はアップロードしてください。");
			this.setErrorMessage("#"+errorKey,this.getError(errorKey));
		}

		if(this.$("#identification").find(".waitMe").size()){
			this.setError(errorKey,"アップロード中です。アップロードが完了するまで次に進めません。");
			this.setErrorMessage("#"+errorKey,this.getError(errorKey));
		}

	},

	checkBeriHope:function(){
		// エラーのキー
		var errorKey = "beri_hope";
		var errorKey1 = "beri_hope1";
		var errorKey2 = "beri_hope2";

		// まず削除
		this.delError(errorKey);
		this.delError(errorKey1);
		this.delError(errorKey2);

		var $hope_date = this.$("#beri_hope_area .hope_date");
		var $after = this.$("#beri_after_hope");

		var is_after = $after.prop("checked");
		if(!is_after){
			var app_year = this.$("#apply_year").val();
			var app_month = this.$("#apply_month").val();
			var app_day = this.$("#apply_day").val();

			if(app_year && app_month && app_day){

				var self = this;

				// ベリ一つ一つチェックする
				$hope_date.each(function(index){
					var b_date = $(this).find(".select-date").val();
					var b_time = $(this).find(".beri_select").val();

					var date_ok = false;
					var time_ok = false;


					var count = index+1;
					var erk = eval("errorKey"+count);

					if(b_date){
						var select_date = Number(moment(b_date).format("YYYYMMDD"));
						var today = Number(moment().format("YYYYMMDD"));
						date_ok = true;

						if(select_date >= today){
							$(this).find(".date").val(moment(b_date).format("YYYY/MM/DD"));
							date_ok2 = true;
						}
					}else{
						$(this).find(".date").val("");
					}

					if(b_time != '0'){
						time_ok = true;
						b_time_obj = beri_times[b_time];
						$(this).find(".time1").val(b_time_obj.time1);
						$(this).find(".time2").val(b_time_obj.time2);
					}else{
						$(this).find(".time1").val("");
						$(this).find(".time2").val("");
					}

					$(this).find(".remark").val($(this).find(".remark_box").val());


					if(count==1){
						if(!date_ok || !time_ok){
							self.setError(erk,"第1希望年月日時間は必須です。");
							self.setErrorMessage("#"+erk,self.getError(erk));
						}else{
							if(!date_ok2){
								self.setError(erk,"ベリ予定日は、本日以降設定可能です。");
								self.setErrorMessage("#"+erk,self.getError(erk));
							}
						}
					}else{
						if(date_ok){
							if(!time_ok){
								self.setError(erk,"必ず「時間」も選択してください。");
								self.setErrorMessage("#"+erk,self.getError(erk));
							}
							if(!date_ok2){
								self.setError(erk,"ベリ予定日は、本日以降設定可能です。");
								self.setErrorMessage("#"+erk,self.getError(erk));
							}
						}
					}


					// 非対応日時のチェック処理
					var isIncompatible = false;
					var tm1 = $(this).find(".time1").val();
					var tm2 = $(this).find(".time2").val();
					if(b_date && tm1 && tm2){
						isIncompatible = _.beriIncompatible(beri_master, b_date+' '+tm1,b_date+' '+tm2);
						if(isIncompatible){
							self.setError(erk,"申し訳ございませんがNCB臨時休業時間中のご指定となっておりますのでご変更をお願い致します。");
							self.setErrorMessage("#"+erk,self.getError(erk));
						}
					}
				});
			}else{
				this.setError(errorKey,"ベリ希望日時が正確ではありません。ご確認ください。");
				this.setErrorMessage("#"+errorKey,this.getError(errorKey));
			}

		}

	},

	checkBeriType:function(){
		// エラーのキー
		var errorKey = "beri_type";

		// まず削除
		this.delError(errorKey);

		var home = this.$("#beri_hope_type-1").prop("checked");
		var mobile = this.$("#beri_hope_type-2").prop("checked");
		var company = this.$("#beri_hope_type-3").prop("checked");
		var counter = this.$("#beri_hope_type-4").prop("checked");

		if(home || mobile || company || counter){
			if(home){
				var phone1 = this.$("#home_phone1").val();
				var phone2 = this.$("#home_phone2").val();
				var phone3 = this.$("#home_phone3").val();
				if(phone1 && phone2 && phone3){

				}else{
					this.setError(errorKey,"ご自宅（固定電話）の電話番号は入力されておりません。再度ご確認ください。");
					this.setErrorMessage("#"+errorKey,this.getError(errorKey));
				}
			}
			if(mobile){
				var phone1 = this.$("#mobile_phone1").val();
				var phone2 = this.$("#mobile_phone2").val();
				var phone3 = this.$("#mobile_phone3").val();
				if(phone1 && phone2 && phone3){

				}else{
					this.setError(errorKey,"携帯番号は入力されておりません。再度ご確認ください。");
					this.setErrorMessage("#"+errorKey,this.getError(errorKey));
				}
			}
			if(company){
				var phone1 = this.$("#company_tel2_1").val();
				var phone2 = this.$("#company_tel2_2").val();
				var phone3 = this.$("#company_tel2_3").val();
				if(phone1 && phone2 && phone3){

				}else{
					this.setError(errorKey,"お勤め先の電話番号は入力されておりません。再度ご確認ください。");
					this.setErrorMessage("#"+errorKey,this.getError(errorKey));
				}
			}
		}else{
			this.setError(errorKey,"確認先は必ずどれか一つは選択してください。");
			this.setErrorMessage("#"+errorKey,this.getError(errorKey));
		}
	},

	// メールアドレス
	checkMailAddress:function(){
		// // エラーのキー
		// var errorKey = "mailAddressArea";

		// // まず削除
		// this.delError(errorKey);

		// if(!isApproval){
		// 	var $mail = this.$('#mail_address_input');

		// 	var $sel = this.$('[name="mail_address_select_domain"]');
		// 	var account = this.$('[name="mail_address_input_account"]').val();
		// 	var domain = ($sel.val() == 'other') ? this.$('[name="mail_address_input_domain"]').val() : $sel.find('option:selected').text();

		// 	// 小文字に変換してアドレス渡す
		// 	var address = account.toLowerCase() + '@' + domain.toLowerCase();
		// 	$mail.val(address);

		// 	if (!account) {
		// 		this.setError(errorKey,"メールアドレスの「@」マークより前の部分は必ず入力してください。");
		// 		this.setErrorMessage("#"+errorKey,this.getError(errorKey));

		// 	} else if (($sel.val() == 'other') && !domain) {
		// 		//「その他」でドメイン未入力の場合
		// 		this.setError(errorKey,"その他の場合は、必ずその他入力欄にメールアドレスの「@」マークより後ろの部分を入力してください。");
		// 		this.setErrorMessage("#"+errorKey,this.getError(errorKey));

		// 	} else if (!Validator.isMail(address)) {
		// 		this.setError(errorKey,"メールアドレスが正しくない可能性があります。");
		// 		this.setErrorMessage("#"+errorKey,this.getError(errorKey));
		// 	}
		// }
	},

/*=====  End of Checker  ======*/


























































/*================================
=            function            =
================================*/



/*=====  End of function  ======*/
































































	/*=============================
	=            Event            =
	=============================*/
	onChangeLiveType:function(){
		var $liveType = this.$("#live_type");
		var val = $liveType.val();

		this.$("#partner_radios").css("opacity",1);
		this.$("#partner_type-2").prop("disabled",false);
		this.$("#partner_type-3").prop("disabled",false);

		var partner = this.$("#partner_type-1").prop("checked");

		if(val == 2 && partner){
			this.$("#now_loan-2").prop("checked",true).prop("disabled",true);
			this.$("#now_loan-1").prop("checked",false).prop("disabled",true);
			this.$("#now_loan_ul").css("opacity",0.6);
			this.$("#live_people_count_select").prop("disabled",false).css("opacity",1);
		}else if(val == 8){
			this.$("#now_loan-2").prop("disabled",false);
			this.$("#now_loan-1").prop("disabled",false);
			this.$("#now_loan_ul").css("opacity",1);
			this.$("#live_people_count_select").val(1).prop("disabled",true).css("opacity",0.6);
			this.onChangePeopleCount();
			this.$("#partner_type-1").prop("checked",true);
			this.$("#partner_type-2").prop("disabled",true);
			this.$("#partner_type-3").prop("disabled",true);
			this.$("#partner_radios").css("opacity",0.6);
		}else{
			this.$("#now_loan-2").prop("disabled",false);
			this.$("#now_loan-1").prop("disabled",false);
			this.$("#now_loan_ul").css("opacity",1);
			this.$("#live_people_count_select").prop("disabled",false).css("opacity",1);
		}
	},

	onChangePeopleCount:function(){
		var count = this.$("#live_people_count_select").val();
		if(count === "1"){
			this.$("#maintainer_select").val("1").prop("disabled",true).css("opacity",0.6);
			this.onChangeMaintainer();
		}else{
			this.$("#maintainer_select").prop("disabled",false).css("opacity",1);
		}
	},

	onChangeMaintainer:function(){
		var mtnr = this.$("#maintainer_select").val();
		if(mtnr === "9"){
			this.$("#other_maintainer").show();
		}else{
			this.$("#other_maintainer").hide();
		}

		// if(mtnr === "1"){
		// 	this.$("#live_in_maintainer-2").prop("checked",true).prop("disabled",true);
		// 	this.$("#live_in_maintainer-1").prop("checked",false).prop("disabled",true);
		// 	this.$("#live_in_maintainer_ul").css("opacity",0.6);
		// 	this.$("#live_in_maintainer").find("#live_in_maintainer_hidden_input").remove();
		// 	this.$("#live_in_maintainer").append("<input type='hidden' name='ctcus_is_cohabitation_householder' value='1' id='live_in_maintainer_hidden_input'>");
		// }else{
		// 	this.$("#live_in_maintainer-2").prop("disabled",false);
		// 	this.$("#live_in_maintainer-1").prop("disabled",false);
		// 	this.$("#live_in_maintainer_ul").css("opacity",1);
		// 	this.$("#live_in_maintainer").find("#live_in_maintainer_hidden_input").remove();
		// }
	},

	onChangeSaveMoney:function(){
		this.$("#save_money_area").find(".error_message").hide();
		this.checkSaveMoney();
	},

	onChangeNowWork:function(){
		var job = this.$("#now_work-1").prop("checked");
		var noJob = this.$("#now_work-2").prop("checked");
		var noJob2 = this.$("#now_work-3").prop("checked");
		this.$(".has_job").hide();
		this.$(".has_job2").hide();

		// 可決のときの処理
		if(isApproval){
			var input = this.$("#now_work_hidden_input").val();
			if(input == 1){
				this.has_job = true;
				this.has_job2 = false;
				this.$(".has_job").show();
				job = true;
				noJob = false;
			}else if(input==3){
				this.has_job = false;
				this.has_job2 = true;
				this.$(".has_job2").show();
				job = false;
				noJob2 = true;
			}else{
				this.has_job = false;
				this.has_job2 = false;
			}
		}

		var $companyForm = this.$(".group2.service_form");

		if(noJob || (!job && !noJob && !noJob2)){
		//無職または未入力の場合
			//クレジット利用明細書送付先「勤務先」選択不可に。「店頭」がなければ「自宅」にチェック
			this.$("#dtl_addressee-2").prop("checked",false).prop("disabled",true);
			if(!canSendOverTheCounter){
				this.$("#dtl_addressee-1").prop("checked",true);
			}
			//無職の場合お勤め先オフ
			if(noJob){
				$companyForm.find(".service_form_alert").show();
				$companyForm.find("input").val("").attr("readonly",true);
				$companyForm.find("select").val("").prop("disabled",true);
			}

			this.has_job = false;
		}else if(job){
			//有職の場合
			this.$("#dtl_addressee-2").prop("disabled",false);

			$companyForm.find(".service_form_alert").hide();
			$companyForm.find("input:not([type='hidden'])").attr("readonly",false);
			$companyForm.find("select").prop("disabled",false);
			this.has_job = true;
			this.has_job2 = false;
			this.$(".has_job").show();
		}else if(noJob2){
			//年金受給の場合
			this.$("#dtl_addressee-2").prop("checked",false).prop("disabled",true);

			if(!canSendOverTheCounter){
				this.$("#dtl_addressee-1").prop("checked",true);
			}

			var income = $companyForm.find('#year_income_input').val();

			$companyForm.find(".service_form_alert").show();
			$companyForm.find("input").val("").attr("readonly",true);
			$companyForm.find("select").val("").prop("disabled",true);

			$companyForm.find('#year_income_input').attr("readonly",false).val(income);

			this.has_job = false;
			this.has_job2 = true;
			this.$(".has_job2").show();
		}

		this.onChangeAddressee();
	},

	onChangeAddressee:function(){
		var company = this.$("#dtl_addressee-2").prop("checked");

		this.$(".has_add_job").hide();
		if(company){
			this.has_add_job = true;
			this.$(".has_add_job").show();
		}else{
			this.has_add_job = false;
		}
	},

	onChangeGotDocment:function(){

		// エラーのキー
		var errorKey = "got_docment";

		this.$("#"+errorKey).find(".error_message").hide();

		var year = this.$("#got_docment_year").val();
		var month = this.$("#got_docment_month").val();
		var day = this.$("#got_docment_day").val();

		var year2 = this.$("#apply_year").val();
		var month2 = this.$("#apply_month").val();
		var day2 = this.$("#apply_day").val();

		// バリデートする
		if(!Validator.isDate(year,month,day) || !_.validDate(year,month,day)){
			this.setErrorMessage("#"+errorKey,"日付が正しくありません。");
		}else{
			// お申込み日が正常かどうか
			if(Validator.isDate(year2,month2,day2)){
				if(moment(year2+"/"+month2+"/"+day2).isBefore(year+"/"+month+"/"+day)){
					this.setErrorMessage("#"+errorKey,"申込年月日以降の日付は、選択できません。");
				}
			}
		}
	},

	onChangeBeriAfterHope:function(){
		var $hope_date = this.$("#beri_hope_area .hope_date");
		var $after = this.$("#beri_after_hope");

		var is_after = $after.prop("checked");
		if(is_after){
			$hope_date.each(function(){
				$(this).find("select").val("");
				$(this).find("input").val("");
			});
		}

	},

	onChangeBeriSelect:function(){
		// this.funcRealtimeBeriCheck();
	},

	onChangeBirthday:function(){
		var year = this.$("#birthday_year").val();
		var month = this.$("#birthday_month").val();
		var day = this.$("#birthday_day").val();

		if(Validator.isDate(year,month,day)){
			$birth = moment(year+"/"+month+"/"+day);
			$today = moment();

			if(_.validDate(year,month,day)){
				var $age = moment($today).diff($birth,"year");
				if($age >= minAge && $age < maxAge+1){
					this.$("#birthday").find(".error_message").fadeOut(200);
				}else{
					this.setErrorMessage("#birthday","受付年齢対象外です");
				}
				this.$("#birthday_view").text($age + "歳");
			}else{
				this.setErrorMessage("#birthday","ご入力日付が正しくありません。");
				this.$("#birthday_view").text("");
			}
		}else{
			this.$("#birthday_view").text("");
		}
	},

	onChangeSubIncomeContent1:function(){
		// エラーのキー
		var errorKey = "sub_income_content_area1";

		this.$("#"+errorKey).find(".error_message").hide();

		var check = this.$("#sub_income_content_area1").find(".sub_income_content1").val();

		// バリデートする
		if(check){
			var val = this.$(".sub_income_input1").val();
			if(val==""){
				this.$(".sub_income1").show();
			}
		}else{
			var val = this.$(".sub_income_input1").val();
			if(val==""){
				this.$(".sub_income1").hide();
			}
		}
	},

	onChangeSubIncomeContent2:function(){
		// エラーのキー
		var errorKey = "sub_income_content_area2";

		this.$("#"+errorKey).find(".error_message").hide();

		var check = this.$("#sub_income_content_area2").find(".sub_income_content2").val();

		// バリデートする
		if(check){
			var val = this.$(".sub_income_input2").val();
			if(val==""){
				this.$(".sub_income2").show();
			}
		}else{
			var val = this.$(".sub_income_input2").val();
			if(val==""){
				this.$(".sub_income2").hide();
			}
		}
	},

	onChangeSubIncomeMoney1:function(){
		// エラーのキー
		var errorKey = "sub_income_area1";

		this.$("#"+errorKey).find(".error_message").hide();

		var check = this.$("#sub_income_area1").find(".sub_income_input1").val();
		
		// バリデートする
		if(check){
			if(_.isStrNum(check)){

			}else{
				this.setErrorMessage("#"+errorKey,"税込年収は整数（万単位）のみ入力可能です。小数点以下は入力出来ません。");
			}
			var val = this.$(".sub_income_content1").val();
			if(val==""){
				this.$(".sub_income1").show();
			}
		}else{
			var val = this.$(".sub_income_content1").val();
			if(val==""){
				this.$(".sub_income1").hide();
			}
		}
	},

	onChangeSubIncomeMoney2:function(){
		// エラーのキー
		var errorKey = "sub_income_area2";

		this.$("#"+errorKey).find(".error_message").hide();

		var check = this.$("#sub_income_area2").find(".sub_income_input2").val();

		// バリデートする
		if(check){
			if(_.isStrNum(check)){

			}else{
				this.setErrorMessage("#"+errorKey,"税込年収は整数（万単位）のみ入力可能です。小数点以下は入力出来ません。");
			}
			var val = this.$(".sub_income_content2").val();
			if(val==""){
				this.$(".sub_income2").show();
			}
		}else{
			var val = this.$(".sub_income_content2").val();
			if(val==""){
				this.$(".sub_income2").hide();
			}
		}
	},

	onChangeMailDomainSelect:function(){
		//「その他」ならフォーム表示
		var $sel = this.$('[name="mail_address_select_domain"]');
		var $other = this.$('[name="mail_address_input_domain"]');
		if ($sel.val() == 'other') {
			$sel.addClass('show_other');
			$other.show();
		} else {
			$sel.removeClass('show_other');
			$other.hide();
		}
	},

	onClickCopyAddressToHomeAddress:function(e){
		$("[name='ctcus_var_parent_home_postal_code']").val($("[name='ctcus_var_main_postal_code']").val());
		var address = '';
		address += $("[name='ctcus_var_main_address1']").val();
		address += $("[name='ctcus_var_main_address2']").val();
		address += $("[name='ctcus_var_main_address3']").val();
		$("[name='ctcus_var_parent_home_address']").val(address);
		return false;
	},

	onClickCopyKana2:function(e){
		$("[name='ctcus_var_main_address2_kana']").val($("[name='ctcus_var_main_address2']").val());
	},
	onClickCopyKana3:function(e){
		$("[name='ctcus_var_main_address3_kana']").val($("[name='ctcus_var_main_address3']").val());
	},
	onClickCopyComKana2:function(e){
		$("[name='ctcom_var_address2_kana']").val($("[name='ctcom_var_address2']").val());
	},
	onClickCopyComKana3:function(e){
		$("[name='ctcom_var_address3_kana']").val($("[name='ctcom_var_address3']").val());
	},

	onChangeBeriDate:function(e){
		var $hopeAreas = $('.hope_date');
		$hopeAreas.each(function(){
			var $self = $(this);
			var $date = $self.find('.select-date');
			var date_val = $date.val();
			var today = moment();
			var today_val = today.format('YYYY/MM/DD');
			var $time = $self.find('.beri_select');
			$time.find('option:nth-child(2)').prop('disabled',false);
			$time.find('option:nth-child(3)').prop('disabled',false);
			$time.find('option:nth-child(4)').prop('disabled',false);
			$time.find('option:nth-child(5)').prop('disabled',false);
			var time_val = $time.val();
			if(date_val === today_val){
				var now_time = Number(today.format('HHmm'));
				if(now_time < 1200){
					$time.find('option:nth-child(2)').prop('disabled',false);
					$time.find('option:nth-child(3)').prop('disabled',false);
					$time.find('option:nth-child(4)').prop('disabled',false);
					$time.find('option:nth-child(5)').prop('disabled',false);
				}else if(now_time < 1300){
					$time.find('option:nth-child(2)').prop('disabled',true);
					$time.find('option:nth-child(3)').prop('disabled',false);
					$time.find('option:nth-child(4)').prop('disabled',false);
					$time.find('option:nth-child(5)').prop('disabled',false);
					if(time_val == 1){ $time.val('0'); }
				}else if(now_time < 1700){
					$time.find('option:nth-child(2)').prop('disabled',true);
					$time.find('option:nth-child(3)').prop('disabled',true);
					$time.find('option:nth-child(4)').prop('disabled',false);
					$time.find('option:nth-child(5)').prop('disabled',false);
					if(time_val == 1){ $time.val('0'); }
					if(time_val == 2){ $time.val('0'); }
				}else if(now_time < 2000){
					$time.find('option:nth-child(2)').prop('disabled',true);
					$time.find('option:nth-child(3)').prop('disabled',true);
					$time.find('option:nth-child(4)').prop('disabled',true);
					$time.find('option:nth-child(5)').prop('disabled',false);
					if(time_val == 1){ $time.val('0'); }
					if(time_val == 2){ $time.val('0'); }
					if(time_val == 3){ $time.val('0'); }
				}else{
					$time.find('option:nth-child(2)').prop('disabled',true);
					$time.find('option:nth-child(3)').prop('disabled',true);
					$time.find('option:nth-child(4)').prop('disabled',true);
					$time.find('option:nth-child(5)').prop('disabled',true);
					$time.val('0');
				}

			}
		});
	},

	onCheckStart:function(e){
		var $target = $(e.currentTarget);
		var check = $target.data("check");
		this.checkdata(check);

		return true;
	},

	/*=====  End of Event  ======*/










































	/*===============================
	=            住所を検索            =
	===============================*/
	//郵便番号 住所を検索ボタンを押したとき
	onClickSearchZipCode:function(e){
		var $e = this.$(e.currentTarget);
		var target_num = $e.data("target");
		var $input = $(".input_zip_"+target_num+" input");
		$input.val(this.deleteHyphen($input.val()));
		var _token = $('meta[name="csrf-token"]').attr('content');
		$(".input_zip_"+target_num).find(".error_message").hide();
		if($input.val()){
			this.ajax(
				__AJAX_ADDRESS_URL,
				{
					zipcode:$input.val(),
					_token: _token
				},
				function(res){
					if(!_.isEmpty(res)){
						this.setZipModal(target_num,res);
					}else{
						this.showErrorZip(target_num,"ご住所が見つかりません。郵便番号を再度ご確認ください。");
					}
				},
				function(){
					this.showErrorZip(target_num,"ご住所が見つかりません。郵便番号を再度ご確認ください。");
				});
			this.$zip_select_modal.modal("show");
			this.$zip_select_modal.find(".zip_list").empty().waitMe({effect : 'progressBar'});
		}else{
			this.showErrorZip(target_num,"先に郵便番号をご入力ください。");
		}
		return false;
	},

	//json住所データをモーダル内に入れる
	setZipModal:function(target_num,res){
		var modal_zip_list = this.$zip_select_modal.find(".zip_list");
		var html = "";
		_.each(res,function(v){
			v.target = target_num;
			v.address = v.pn_vc_refectures+v.pn_vc_city+v.pn_vc_town;
			v.address_kana = v.pn_vc_refectures_kana+" "+v.pn_vc_city_kana+" "+v.pn_vc_town_kana;
			// v.address = v.pn_vc_city+v.pn_vc_town;
			// v.address_kana = v.pn_vc_city_kana+" "+v.pn_vc_town_kana;
			html += this.zip_select_list_modal_template(v);
		},this);

		modal_zip_list.html(html);
	},

	//モーダル内のアドレスボタンを押したとき
	onClickSetAdressItem:function(e){
		var $e = this.$(e.currentTarget);
		var target_num = $e.data("target");
		$(".input_zip_kan_"+target_num+" .address_kan").val($e.data("address"));
		$(".input_zip_kana_"+target_num+" .address_kana").val($e.data("address_kana"));
		this.$zip_select_modal.modal("hide");
		return false;
	},

	//住所が見つからなかったとき
	showErrorZip:function(target_num,text){
		var $input_zip = $(".input_zip_"+target_num);
		if($input_zip.find(".error_message").length){
			$input_zip.find(".error_message").html("<p>"+text+"</p>");
		}else{
			$input_zip.find(".zip_form").after("<div class='error_message'><p>"+text+"</p></div>");
		}

		$input_zip.find(".error_message").fadeIn(200);

		$(".input_zip_kan_"+target_num+" .address_kan").val("");
		$(".input_zip_kana_"+target_num+" .address_kana").val("");
		this.$zip_select_modal.modal("hide");
		return false;
	},

	/*===============================
	=            住所を検索 end       =
	===============================*/

	onBlurZipCode:function(e){
		var $e = this.$(e.currentTarget);
		var $zip = this.deleteHyphen(String($e.val()));
		$e.val($zip);
		this.checkdata(50);
	},

	deleteHyphen:function(str){
		str = str.split('-').join('');
		str = str.split('－').join('');
		str = str.split('ー').join('');
		return str;
	},








});
