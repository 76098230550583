var RedInvoiceForm = Backbone.View.extend({

	events:{
		"click #getContractDetailBtn":"onClickGetContractDetail",
		"change #inquiry_check" : "onCheckInquiryCheck",
		//"change #select_seisan1" : "onCheckSeisan",
		//"change #select_seisan2" : "onCheckSeisan",

		"change .inquire_type_class" : "onChangeInquiryType",

		"change .return_money_check" : "onCheckReturnMoney",
		"change #return_money-2" : "onCheckAgencyBox",

		"change #agency_check-1" : "onCheckAgencyCheck1",
		"change #agency_check-2" : "onCheckAgencyCheck2",

		"change #form_seisan_offer_month" : "onChangeOfferMonth",
		"change #form_seisan_offer_day" : "onChangeOfferDay",


		"click #red_invoice_confirm" : "onClickRedInvoiceConfirm",
		"click #red_invoice_post" : "onClickRedInvoicePost",
		
		"change #mail_address_select_domain":"onClickSelectDomain",

		"focus #return_money_num" : "onFocusChangeMoneyToNum",
		"blur #return_money_num" : "onBlurChangeNumToMoney",

		"change #calendar" : "onChangeCalendar",
		"input #birthday" : "onInputBirthday",

		"click #clear_btn" : "onClickClearBtn",
	},

	payoffMoneyTotal:0, // 既払金トータル
	via: null, // 再照会判別用
	withoutPayOffDay: false, // 清算日なしでの照会なら正
	canExecAndHandle: false, // 赤伝照会と実行がどちらも正なら正

	endOfEra: {
		//昭和
		1: '1989-01-07',
		//平成
		2: null
	},

	initialize:function(){
		this.onInputBirthday();
		this.via = via;
		this.canExecAndHandle = canExecAndHandle;
	},

	onCheckInquiryCheck:function(){
		if($("#inquiry_check").prop("checked")){
			$("#inquiry_form_btns").fadeIn(500);
		}else{
			$("#inquiry_form_btns").fadeOut(500);
		}
	},

	onClickGetContractDetail:function(){
		this.withoutPayOffDay = false;

		// 清算日なければフラグ立てる
		var $payOffMonth = this.$("#form_seisan_offer_month");
		var $payOffDay = this.$("#form_seisan_offer_day");

		if (!$payOffMonth.val() && !$payOffDay.val() && this.$('#inquire_type-0').prop('checked')) this.withoutPayOffDay = true;

		var self = this;
		var error = false;

		this.$(".form_error").hide();
		this.$(".form_error2").hide();

		//枝番なければ 1 にする
		var $branch_num = this.$("#branch_num");
		if ($branch_num.val() === '') $branch_num.val(1);

		var ctsp_id = this.$("#number").val();
		var branch_num = $branch_num.val();
		var name = this.$("#name").val();
		var calendar = this.$("#calendar").val() - 0;
		var birthday = this.$("#birthday").val();

		//和暦西暦変換とヴァリデート
		var westernDate = this.convertToWesternMoment(calendar, birthday);
		if (-1 < westernDate.toString().indexOf('nvalid')) {
			error = true;
			this.$(".form_error").text('生年月日が無効な日付です。').show();
		}
		birthday = westernDate.format('YYYYMMDD');

		var type = this.$('input[name=inquire_type]:checked').val();

		// 清算日
		var seisan_offer = null;

		if(ctsp_id && name && birthday){
			// 検索上部エラー無し
			if (!/^[\u30a0-\u30ff｡-ﾟ 　]+$/.test(name)) {
				error = true;
				this.$(".form_error").text('氏名カナに入力できない文字が含まれています。').show();
			}
		}else{
			error = true;
			this.$(".form_error").text('必ず全てご入力ください。').show();
		}

		if (!this.withoutPayOffDay) {
			// 振込の加盟店の場合
			if(payoff_type == 0){

				var month = $payOffMonth.val();
				var day = $payOffDay.val();

				if(month && day){
					var now = moment();
					var year = now.year();

					// 正常の日付かをチェック
					if(_.validDate(year,month,day)){

						var payoff_date = moment(year+"/"+month+"/"+day);

						if(moment().isAfter(payoff_date,"day")){
							payoff_date.add(1,"year");
						}

						// 翌々月末を取得
						var diff = moment().add(2,"M").endOf("M");

						// 翌々月末以内かどうかを比較
						if(diff.isAfter(payoff_date)){
							
							// 土日、清算日不可チェック
							if(payoff_date.days() == 6 || payoff_date.days() == 0){
								error = true;
								this.$(".form_error2").text("土日祝日は解約出来ません。").show();
							}else{
								// 清算非対応日チェック
								var is_no_offer = false;
								var check = payoff_date.format("YYYYMMDD");
								_.each(no_offer_days,function(days){
									if(days==check){
										is_no_offer = true;
									}
								});

								if(is_no_offer){
									error = true;
									this.$(".form_error2").text("土日祝日は解約出来ません。").show();
								}

								seisan_offer = payoff_date;
							}

						}else{
							error = true;
							this.$(".form_error2").text("解約は翌々月末まで可能です。").show();
						}


					}else{
						error = true;
						this.$(".form_error2").text("清算日が正しくありません。").show();
					}

				}else{
					error = true;
					this.$(".form_error2").text("清算日は必ず選択してください。").show();
					//80066934
				}
			}
			// 加盟店が、相殺設定になっている場合
			else{
				// 立替締切日設定されていると処理が走る
				if(advence_days.length){

					if(this.saveOffer_date){
						seisan_offer = this.saveOffer_date;
					}else{
						error = true;
						this.$(".form_error2").text("清算日が選択されておりません。").show();
					}
					// if(type==0){
						

					// }else{
					// 	seisan_offer = this.saveOffer_date;
					// }
				}else{ // 立替締切日設定に値がない
					error = true;
					this.$(".form_error2").text("現在照会出来ません。申し訳ありませんが、日本プラムまでご連絡ください。").show();
				}
			}
		}

		if(!error){
			if(branch_num){
				ctsp_id = ctsp_id+"-"+branch_num;
			}

			if(this.withoutPayOffDay){
				this.$(".seisan_form").hide();
				seisan_offer = today;
			}

			this.setSearch(ctsp_id,name,birthday,type,seisan_offer);
		}
	},

	// 照会検索実行
	setSearch:function(ctsp_id,name,birthday,type,offer){
		var self = this;

		this.$(".redinvoice_loading").show();
		this.$(".redinvoice_loading .loading_box").waitMe({effect : 'progressBar',color : "#298470"});
		this.$(".red_invoice_error").hide();
		this.$(".redinvoice_error").hide();

		$("#getContractDetailBtn").attr("disabled",1);
		$("#clear_btn").attr("disabled",1);

		// 清算日を自動セット
		this.saveOffer_date = offer;

		// 照会検索実行
		this.ajax(SEARCH_URL,{
			"ctsp_id":ctsp_id,
			"name":name,
			"birthday":birthday,
			"inquire_type":type,
			"offer":offer.format("YYYY-MM-DD"),
		},function(res){
			self.searchContactDetail(res["ctiq_big_id"]);
		});
	},

	// 相殺の場合、解約形式によって、こちらの処理が走る
	onChangeInquiryType:function(){
		this.saveOffer_date = null;

		if(payoff_type == 1){

			var type = this.$('input[name=inquire_type]:checked').val();
			if(type==1){

				this.$("#form_seisan_offer_month").hide().val("");
				this.$("#form_seisan_offer_day").hide().val("");
				this.$(".seisan_view_date").show();

				this.$(".seisan_view_date").text("計算中");

				// 立替締切日設定されていると処理が走る
				if(advence_days.length){

					this.saveOffer_date = false;

					var now = moment();

					while(!this.saveOffer_date){

						var m_year = now.year();
						var m_month = now.month()+1;

						// 立替日の数だけループ
						for (var i = 0; i < advence_days.length; i++) {
							if(this.saveOffer_date){ break; }

							var m_day = advence_days[i];

							var valid = true;

							// 立替日正常チェック
							while(valid){
								var check = moment(m_year+"/"+m_month+"/"+m_day);
								if(check.isValid() && _.validDate(m_year,m_month,m_day)){
									// 土日、清算日不可チェック
									if(check.days() == 6 || check.days() == 0){
										
									}else{
										// 清算非対応日チェック
										var is_no_offer = false;
										var _check = check.format("YYYYMMDD");
										_.each(no_offer_days,function(days){
											if(days==_check){
												is_no_offer = true;
											}
										});

										if(!is_no_offer){
											if(moment().isBefore(check,"day")){
												this.saveOffer_date = check;
												valid = false;
											}
										}
									}
								}
								if(valid){
									// 1日削る
									m_day--;
									if(!m_day){
										valid = false;
									}
								}
							}
						}

						if(!this.saveOffer_date){ now.add("1","M"); }
					}
					
					if(this.saveOffer_date){
						this.$(".seisan_view_date").text(this.saveOffer_date.format("YYYY/MM/DD"));
					}

				}

			}else{
				this.$("#form_seisan_offer_month").show();
				this.$("#form_seisan_offer_day").show();
				this.$(".seisan_view_date").hide();
			}
		}
	},

	onChangeOfferMonth:function(){
		if(payoff_type == 1){
			this.$("#form_seisan_offer_day").val("");
			this.$(".form_error2").hide();
			this.$(".seisan_view_date").text("");
			this.saveOffer_date = null;
		}
	},

	onChangeOfferDay:function(){

		this.saveOffer_date = null;
		this.$(".form_error2").hide();
		this.$(".seisan_view_date").text("");

		var now = moment();
		var year = now.year();
		var month = this.$("#form_seisan_offer_month").val();
		var select_day = this.$("#form_seisan_offer_day").val();
		var day = 1;
		var seisan_offer = null;

		if(payoff_type == 1 && select_day){

			var type = this.$('input[name=inquire_type]:checked').val();
			if(type==0){

				if(month){

					// 清算月でmoment作成（月初設定）
					var payoff_date = moment(year+"/"+month+"/"+day);

					// 今月より過去であれば来年に変更
					if(moment().isAfter(payoff_date,"M")){
						payoff_date.add(1,"year");
					}

					// 翌々月末を取得
					var diff = moment().add(2,"M").endOf("M");

					// 翌々月末以内かどうかを比較
					if(diff.isAfter(payoff_date)){
						
						// 立替年
						var m_year = payoff_date.year();
						var m_month = payoff_date.month()+1;

						// 選択立替日の数だけループ
						var m_day = select_day;

						var valid = true;

						// 立替日正常チェック
						while(valid){
							var check = moment(m_year+"/"+m_month+"/"+m_day);
							if(check.isValid() && _.validDate(m_year,m_month,m_day)){
								// 土日、清算日不可チェック
								if(check.days() == 6 || check.days() == 0){
									
								}else{
									// 清算非対応日チェック
									var is_no_offer = false;
									var _check = check.format("YYYYMMDD");
									_.each(no_offer_days,function(days){
										if(days==_check){
											is_no_offer = true;
										}
									});

									if(!is_no_offer){
										if(moment().isBefore(check,"day")){
											seisan_offer = check;
											valid = false;
										}
									}
								}
							}
							if(valid){
								// 1日削る
								m_day--;
								if(!m_day){
									valid = false;
								}
							}
						}

						if(seisan_offer){
							this.saveOffer_date = seisan_offer;
							this.$(".seisan_view_date").show();
							this.$(".seisan_view_date").text(seisan_offer.format("YYYY/MM/DD"));
						}else{
							seisan_offer = null;
							this.$(".seisan_view_date").text("");
							this.$(".form_error2").text("選択された日付は解約不可です。お手数ですが、日本プラムまでご連絡ください。").show();
						}

					}else{
						this.$(".form_error2").text("解約月は翌々月まで可能です。").show();
					}
					
				}else{
					this.$(".form_error2").text("清算相殺月は必ず選択してください。").show();
				}
			}
		}
	},

	searchContactDetail:function(id){
		var self = this;

		setTimeout(function(){
			self.searchPolingContractDetail(id);
		},5000);
	},

	searchPolingContractDetail:function(id){
		var self = this;

		this.ajax(POLING_DATA_URL,{
			"ctsp_id":id,
			"via":this.via,
		},function(res){
			if(!res.ctiq_ts_got_at){
				self.searchContactDetail(id);
				//self.getContactDetail();
			}else{
				if(!Number(res.ctiq_tint_error)){
					self.getContactDetail(res);
				}else{
					self.getContactError(res.ctiq_tint_error);
					//reload();
				}
			}
		});
	},

	getContactDetail:function(data){

		//if (!this.canExecAndHandle) this.$('#agency_pdf_area').remove();

		this.$(".red_invoice_form").slideUp(200);
		this.$("#header_inquiry_link").show();

		var $detailTable = this.$(".table-red_invoice-detail");

		// 承認ID
		$detailTable.find(".data_ctsp_id").text(data.ctiq_key_ctsp_id);

		// ご契約者名
		var name = data.ctiq_var_name;
		var name = name + "("+data.ctiq_var_kana+")";
		$detailTable.find(".data_name").text(name);

		// 生年月日
		var birthday = "";
		if(data.ctiq_dt_birthday){
			birthday = moment(data.ctiq_dt_birthday).format("YYYY年MM月DD日");
		}
		$detailTable.find(".data_birthday").text(birthday);

		// 照会形式
		var intype = "中途解約";
		this.red_type = 0;
		if(Number(data.ctiq_tint_red_type)){
			intype = "買取解約";
			this.red_type = 1;
			$("#return_select_area").hide();
			$("#agency_pdf_area").hide();
			$("#modal_return_tr").hide();
			$("#modal_return_money_tr").hide();
		}
		$detailTable.find(".data_inquiry_type").text(intype);

		// 照会日
		var indate = "";
		if(data.ctiq_ts_ask_at){
			indate = moment(data.ctiq_ts_ask_at).format("YYYY年MM月DD日");
		}
		$detailTable.find(".data_inquiry_date").text(indate);

		// 既払金
		var paydmoney = "";
		if(Number(data.ctiq_int_paymented_money)){
			this.payoffMoneyTotal = data.ctiq_int_paymented_money;
			paydmoney = changeNumFomatMoney(data.ctiq_int_paymented_money)+"円";
		}else{
			paydmoney = "0円";
			this.payoffMoneyTotal = 0;
			this.$("#return_select_area").hide();
			this.$("#agency_pdf_area").hide();
		}
		$detailTable.find(".data_paymented_money").text(paydmoney);

		// 申込年月日
		var appdate = "";
		if(data.ctiq_dt_apply){
			appdate = moment(data.ctiq_dt_apply).format("YYYY年MM月DD日");
		}
		$detailTable.find(".data_apply").text(appdate);

		// 受取立替金
		var gadmoney = "";
		if(data.ctiq_int_got_advance_money){
			gadmoney = changeNumFomatMoney(data.ctiq_int_got_advance_money)+"円";
		}
		$detailTable.find(".data_got_advance_money").text(gadmoney);

		// 契約年月日
		var condate = "";
		if(data.ctiq_dt_contract){
			condate = moment(data.ctiq_dt_contract).format("YYYY年MM月DD日");
		}
		$detailTable.find(".data_contract").text(condate);

		// 受取手数料
		var gcommmoney = "";
		if(data.ctiq_int_got_commission_money){
			gcommmoney = changeNumFomatMoney(data.ctiq_int_got_commission_money)+"円";
		}
		$detailTable.find(".data_got_commission_money").text(gcommmoney);

		// 立替年月日
		var addate = "";
		if(data.ctiq_dt_advanced){
			addate = moment(data.ctiq_dt_advanced).format("YYYY年MM月DD日");
		}
		$detailTable.find(".data_advanced").text(addate);

		// 受取延滞金
		var gdmoney = "";
		if(data.ctiq_int_got_delay_money){
			gdmoney = changeNumFomatMoney(data.ctiq_int_got_delay_money)+"円";
		}
		$detailTable.find(".data_got_delay_money").text(gdmoney);

		// 支払開始年月日
		var fstreturn = "";
		if(data.ctiq_dt_first_return){
			fstreturn = moment(data.ctiq_dt_first_return).format("YYYY年MM月DD日");
		}
		$detailTable.find(".data_first_return").text(fstreturn);

		// 立替金
		var admoney = "";
		if(data.ctiq_int_advance_money){
			admoney = changeNumFomatMoney(data.ctiq_int_advance_money)+"円";
		}
		$detailTable.find(".data_advance_money").text(admoney);

		// 前取引年月日
		var lstreturn = "";
		if(data.ctiq_dt_last_return){
			lstreturn = moment(data.ctiq_dt_last_return).format("YYYY年MM月DD日");
		}
		$detailTable.find(".data_last_return").text(lstreturn);

		// 立替金残
		var adrmoney = "";
		if(data.ctiq_int_advance_remnant_money){
			adrmoney = changeNumFomatMoney(data.ctiq_int_advance_remnant_money)+"円";
		}
		$detailTable.find(".data_advance_remnant_money").text(adrmoney);

		// 契約支払回数
		var paycount = data.ctiq_int_payment_count;
		$detailTable.find(".data_payment_count").text(paycount+"回");

		// 先取手数料
		var fstcomm = "";
		if(data.ctiq_int_first_commission){
			fstcomm = changeNumFomatMoney(data.ctiq_int_first_commission)+"円";
		}
		$detailTable.find(".data_first_commission").text(fstcomm);

		// 既払回数（入金済回数）
		var paydcount = data.ctiq_int_paymented_count;
		$detailTable.find(".data_paymented_count").text(paydcount+"回");

		// 解約手数料
		var lstcomm = "";
		if (!this.withoutPayOffDay) {
			if(data.ctiq_int_last_commission){
				lstcomm = changeNumFomatMoney(data.ctiq_int_last_commission)+"円";
			}
		}
		$detailTable.find(".data_last_commission").text(lstcomm);

		// 経過月数
		var durmnth = data.ctiq_int_duration_months;
			// // 先取り手数料が無い場合は、経過月数自動計算
			// if(durmnth == 0 && fstcomm == 0){
			// 	var advancedInt =  Number(moment(data.ctiq_dt_advanced).format("YYYYMM"));
			// 	var seisanInt = Number(this.saveOffer_date.format("YYYYMM"));
			// 	durmnth = seisanInt - advancedInt;
			// }
		$detailTable.find(".data_duration_months").text(durmnth+"ヶ月");

		// 戻し手数料
		var bckcomm = "";
		if(data.ctiq_int_back_commission){
			bckcomm = changeNumFomatMoney(data.ctiq_int_back_commission)+"円";
		}
		$detailTable.find(".data_back_commission").text(bckcomm);

		// 基本返済額
		var bsmoney = "";
		if(data.ctiq_int_base_money){
			bsmoney = changeNumFomatMoney(data.ctiq_int_base_money)+"円";
		}
		$detailTable.find(".data_base_money").text(bsmoney);

		// 清算金額
		var poffmoney = "";
		if (!this.withoutPayOffDay) {
			if(data.ctiq_int_payoff_money){
				poffmoney = changeNumFomatMoney(data.ctiq_int_payoff_money)+"円";
			}
			$detailTable.find(".data_payoff_money").text(poffmoney);
		} else {
			this.$(".with_payoff_date").remove();
		}

		if(Number(data.def_is_cancellation_charge_offset)){
			this.$(".seisan_select").text("相殺");
			this.$("#seisan_offer_day").hide();
			this.cancellationCharge = 1;
		}else{
			this.$(".seisan_select").text("振込");
			this.$("#seisan_offer_day").show();
			this.cancellationCharge = 0;
		}

		// 清算日
		this.$(".seisan_day").text(this.saveOffer_date.format("YYYY年MM月DD日"));

		// フォームにIDを入れる
		$("#red_invoice_id").val(data.ctiq_big_id);

		var $agDlBtn = $("#agency_dl_btn");
		if ($agDlBtn.length) $agDlBtn.prop('href', $agDlBtn.prop('href').replace('/0/', '/'+data.ctiq_big_id+'/'));

		// モーダルの中身
		
		$("#modal_contract_id").text(data.ctiq_key_ctsp_id);
		if(Number(data.def_is_cancellation_charge_offset)){
			this.$("#modal_seisan_type").text("相殺");
		}else{
			this.$("#modal_seisan_type").text("振込");
		}

		this.$(".redinvoice_loading").hide();
		this.$(".redinvoice_detail").show();
		$("#getContractDetailBtn").attr("disabled",0);
		$("#clear_btn").attr("disabled",0);

	},

	getContactError:function(error_num){
		if(error_num == 1){
			this.$("#redinvoice_error_message").text("該当顧客無し。");
		}
		if(error_num == 2){
			this.$("#redinvoice_error_message").text("完済顧客です。");
		}
		if(error_num == 3){
			this.$("#redinvoice_error_message").text("解約済みです。");
		}
		if(error_num == 4){
			this.$("#redinvoice_error_message").text("照会対象外顧客です。詳細はカスタマセンター（075-803-6168）へお問合せ下さい。");
		}
		if(error_num == 5){
			this.$("#redinvoice_error_message").text("照会対象外顧客です。詳細は割賦センター（075-803-6161）へお問合せ下さい。");
		}
		if(error_num == 6){
			this.$("#redinvoice_error_message").text("エラー");
		}
		if(error_num == 7){
			this.$("#redinvoice_error_message").text("照会エラー 再度やり直してください 7");
		}

		this.$(".redinvoice_loading").hide();
		this.$(".redinvoice_error").show();
		$("#getContractDetailBtn").removeAttr("disabled");
		$("#clear_btn").removeAttr("disabled");
	},

	onCheckReturnMoney:function(){
		var returnFlg1 = this.$("#return_money-1").prop("checked");
		var returnFlg2 = this.$("#return_money-2").prop("checked");

		if(returnFlg1 && returnFlg2){
			this.$(".return_box").show();
		}else{
			this.$(".return_box").hide();
		}
	},

	// onCheckSeisan:function(){
	// 	if($("#select_seisan1").prop("checked")){
	// 		$("#seisan_offer_day").show();
	// 	}else{
	// 		$("#seisan_offer_day").hide();
	// 	}
	// },


	onCheckAgencyBox:function(){
		var returnFlg2 = this.$("#return_money-2").prop("checked");
		if(returnFlg2){
			this.$(".agency_agree_table").show();
		}else{
			this.$(".agency_agree_table").hide();
		}
	},

	onCheckAgencyCheck1:function(){
		var agencyFlg = this.$("#agency_check-1").prop("checked");

		if(agencyFlg){
			this.$(".client_mail_check").show();
			this.$(".shop_check").hide();
		}
	},

	onCheckAgencyCheck2:function(){
		var agencyFlg = this.$("#agency_check-2").prop("checked");

		if(agencyFlg){
			this.$(".client_mail_check").hide();
			this.$(".shop_check").show();
		}
	},


	onClickSelectDomain:function(){
		var selectDomain = this.$("#mail_address_select_domain").val();
		if(selectDomain=="other"){
			this.$(".other_domain").css("display","inline-block");
		}else{
			this.$(".other_domain").css("display","none");
		}

	},

	onClickRedInvoiceConfirm:function(){
		var agency_error = false;

		// var seisan_offer_month = this.$("#seisan_offer_month").val();
		// var seisan_offer_day = this.$("#seisan_offer_day").val();

		var seisan_offer = null;

		// if(this.cancellationCharge){
		// 	if(seisan_offer_month){
		// 		this.$("#red_invoice_seisanoffer_error").hide();

		// 		// 清算月の計算（当月より昔を選択の場合、１年繰り越す）
		// 		var month = Number(seisan_offer_month);
		// 		month = month - 1;

		// 		var offer_month = moment().date(1).month(month);
		// 		if(
		// 			Number(moment().format("YYYYMM"))
		// 			>=
		// 			Number(offer_month.format("YYYYMM"))
		// 		){
		// 			offer_month.add(1,"Y");
		// 		}
		// 		seisan_offer = offer_month;

		// 	}else{
		// 		this.$("#red_invoice_seisanoffer_error p").text("相殺清算月は必ず指定してください。");
		// 		this.$("#red_invoice_seisanoffer_error").show();
		// 		agency_error = true;
		// 	}
		// }else{
		// 	if(seisan_offer_month && seisan_offer_day){
		// 		var now = moment();
		// 		var year = now.year();
		// 		var date_str = year+"/"+seisan_offer_month+"/"+seisan_offer_day;

		// 		// 指定清算日を数値で表現
		// 		var offerNum = year;
		// 		offerNum = offerNum + (Number(seisan_offer_month) < 10 ? "0"+seisan_offer_month : seisan_offer_month);
		// 		offerNum = offerNum + (Number(seisan_offer_day) < 10 ? "0"+seisan_offer_day : seisan_offer_day);
		// 		offerNum = Number(offerNum);

		// 		// 今日を数値で表現
		// 		var nowNum = Number(now.format("YYYYMMDD"));

		// 		// 清算日をmoment化
		// 		var offer = moment(date_str);

		// 		// 今日未満が指定清算日の場合、+1年する
		// 		if(offerNum <= nowNum){
		// 			year = year+1;
		// 			date_str = year+"/"+seisan_offer_month+"/"+seisan_offer_day;
		// 			offer = moment(date_str);
		// 		}

		// 		if(date_str != offer.format("YYYY/M/D")){
		// 			this.$("#red_invoice_seisanoffer_error p").text("清算日が不正です。");
		// 			this.$("#red_invoice_seisanoffer_error").show();
		// 			agency_error = true;
		// 		}else{

		// 			// 土日チェック
		// 			if(offer.days() == 6 || offer.days() == 0){
		// 				this.$("#red_invoice_seisanoffer_error p").text("土日祝は、選択出来ません。");
		// 				this.$("#red_invoice_seisanoffer_error").show();
		// 				agency_error = true;
		// 			}else{

		// 				// 清算非対応日チェック
		// 				var is_no_offer = false;
		// 				var check = offer.format("YYYYMMDD");
		// 				_.each(no_offer_days,function(days){
		// 					if(days==check){
		// 						is_no_offer = true;
		// 					}
		// 				});

		// 				if(is_no_offer){
		// 					this.$("#red_invoice_seisanoffer_error p").text("この日は清算非対応日です。");
		// 					this.$("#red_invoice_seisanoffer_error").show();
		// 					agency_error = true;
		// 				}else{
		// 					seisan_offer = offer;
		// 					this.$("#red_invoice_seisanoffer_error").hide();
		// 				}

		// 			}
		// 		}
		// 	}else{
		// 		this.$("#red_invoice_seisanoffer_error p").text("清算日は必ず指定してください。");
		// 		this.$("#red_invoice_seisanoffer_error").show();
		// 		agency_error = true;
		// 	}
		// }

		if(!this.red_type){

			if(this.payoffMoneyTotal){

				var return_money1 = this.$("#return_money-1").prop("checked");
				var return_money2 = this.$("#return_money-2").prop("checked");

				if(return_money1 || return_money2){
					this.$("#red_invoice_back_error").hide();

					// 既払金返金額チェック
					if(return_money1 && return_money2){
						var returnMoneyNum = this.$("#return_money_num").val();
						returnMoneyNum = changeNumFomatInt(returnMoneyNum);
						if(returnMoneyNum){
							if(this.payoffMoneyTotal < returnMoneyNum){
								this.$("#red_invoice_back_error p").text("販売店への返金額が、既払金を超えております。");
								this.$("#red_invoice_back_error").show();
								agency_error = true;
							}
						}else{
							this.$("#red_invoice_back_error p").text("販売店への返金額は必ずご入力ください。");
							this.$("#red_invoice_back_error").show();
							agency_error = true;
						}
						
					}


					if(return_money2){
						var agencyCheck1 = this.$("#agency_check-1").prop("checked");
						var agencyCheck2 = this.$("#agency_check-2").prop("checked");

						if(agencyCheck1 || agencyCheck2){
							this.$("#red_invoice_agency_back_error").hide();


							// if(agencyCheck1){
							// 	var $mail = this.$('#mail_address_input');
							// 	var $sel = this.$('[name="mail_address_select_domain"]');
							// 	var account = this.$('[name="mail_address_input_account"]').val();
							// 	var domain = ($sel.val() == 'other') ? this.$('[name="mail_address_input_domain"]').val() : $sel.find('option:selected').text();

							// 	// 小文字に変換してアドレス渡す
							// 	var address = account.toLowerCase() + '@' + domain.toLowerCase();
							// 	$mail.val(address);
							
							// 	if (!account) {
							// 		this.$("#red_invoice_agency_check_back_error p").text("メールアドレスの「@」マークより前の部分は必ず入力してください。");
							// 		this.$("#red_invoice_agency_check_back_error").show();
							// 		agency_error = true;
							// 	} else if (($sel.val() == 'other') && !domain) {
							// 		//「その他」でドメイン未入力の場合
							// 		this.$("#red_invoice_agency_check_back_error p").text("その他の場合は、必ずその他入力欄にメールアドレスの「@」マークより後ろの部分を入力してください。");
							// 		this.$("#red_invoice_agency_check_back_error").show();
							// 		agency_error = true;

							// 	} else if (!Validator.isMail(address)) {
							// 		this.$("#red_invoice_agency_check_back_error p").text("メールアドレスが正しくない可能性があります。");
							// 		this.$("#red_invoice_agency_check_back_error").show();
							// 		agency_error = true;
							// 	}else{
							// 		this.$("#red_invoice_agency_check_back_error").hide();
							// 	}
							// }

							if(agencyCheck2){
								var check = this.$("#agency_shop_agree").prop("checked");
								if(!check){
									this.$("#red_invoice_agency_check_back_error p").text("必ず同意してください。");
									this.$("#red_invoice_agency_check_back_error").show();
									agency_error = true;
								}else{
									this.$("#red_invoice_agency_check_back_error").hide();
								}
							}


						}else{
							this.$("#red_invoice_agency_back_error p").text("既払金代理受領申請は必ず選択してください。");
							this.$("#red_invoice_agency_back_error").show();
							agency_error = true;
						}
					}

				}else{
					this.$("#red_invoice_back_error p").text("既払金返金先は必ず選択して下さい。");
					this.$("#red_invoice_back_error").show();
					agency_error = true;
				}
			}
		}

		// モーダル表示
		if(!agency_error){

			this.$("#modal_seisan_offer").text("");
			this.$("#modal_return").text("");
			$("#modal_return_money").text("");
			//this.$("#modal_email_address").text("");

			//if(seisan_offer){

				if(this.cancellationCharge){
					this.$("#modal_seisan_offer").text(this.saveOffer_date.format("YYYY年MM月相殺"));
				}else{
					this.$("#modal_seisan_offer").text(this.saveOffer_date.format("YYYY年MM月DD日"));
				}

				this.$("#red_invoice_offer_date").val(this.saveOffer_date.format("YYYY-MM-DD"));
			//}


			if(return_money1 && return_money2){
				this.$("#modal_return").text("契約者・販売店");
				var returnMoneyNum = this.$("#return_money_num").val();
				returnMoneyNum = changeNumFomatInt(returnMoneyNum);
				var clientReturnMoney = changeNumFomatMoney(this.payoffMoneyTotal-returnMoneyNum);
				var memberReturnMoney = changeNumFomatMoney(returnMoneyNum);

				var returnMoneyView = "契約者:"+clientReturnMoney+"円";
				returnMoneyView = returnMoneyView + "　販売店:"+memberReturnMoney+"円";

				$("#modal_return_money").text(returnMoneyView);

			}else{
				var returnMoneyView = changeNumFomatMoney(this.payoffMoneyTotal);
				if(return_money1){
					this.$("#modal_return").text("契約者");
				}
				if(return_money2){
					this.$("#modal_return").text("販売店");
				}
				$("#modal_return_money").text(returnMoneyView+"円");
				if(!this.payoffMoneyTotal){
					this.$("#modal_return").text("");
					$("#modal_return_money").text("");
				}
			}

			//this.$("#modal_email_address").text(this.$('#mail_address_input').val());

			this.$("#red_invoice_modal").modal("show");
		}

		return false;
	},

	onClickRedInvoicePost:function(){
		// 確定処理


		if (!this.withoutPayOffDay) $("#red_invoice_comp_form").submit();
	},

	onChangeCalendar:function(){
		var cal = this.$('#calendar').val() - 0;
		var $bd = this.$('#birthday');
		switch (cal) {
			case 0:  $bd.prop('placeholder', '20010101'); break;
			default: $bd.prop('placeholder', '020101'); break;
		}
		this.onInputBirthday();
	},

	onInputBirthday:function(){
		var cal = this.$('#calendar').val() - 0;
		var bd = this.$('#birthday').val();
		var $adYear = this.$("#ad_year");

		if (bd.length === 0) {
			$adYear.text('');
			return;
		}

		var date = this.convertToWesternMoment(cal, bd);
		$adYear.text((date.toString().indexOf('nvalid') === -1) ? date.format('YYYY年M月D日生') : '無効な日付です');
	},

	onClickClearBtn:function(){
		$form = this.$('.red_invoice_form');
		$form.find('input:not([type="radio"])').val('');
		$form.find('select').prop('selectedIndex', 0);
		this.$("#ad_year").val('');
		this.$('.red_invoice_error_message').hide();
		this.$(".seisan_form").show();
	},

	//西暦も和暦も moment に変換。ヴァリデートにも利用する
	convertToWesternMoment:function(calendar, date) {
		calendar -= 0;
		if (/[０-９]/.test(date)) date = date.replace(/[０-９]/g, this.convertToHalfWidthChar);
		date = date.replace(/^\D+/g, '');
		var ymd;

		if (/\D/.test(date)) {
			ymd = date.split(/\D+/);
			ymd[0] -= 0;
			ymd[1] -= 1;
			ymd[2] -= 0;
		} else {
			ymd = [];
			ymd[0] = date.substring(0, date.length-4) - 0;
			ymd[1] = date.substr(-4, 2) - 1;
			ymd[2] = date.substr(-2) - 0;
		}

		//0年や0日は弾く
		if (!ymd[0] || !ymd[2]) return moment.invalid();

		switch (calendar) {
			case 1: ymd[0] = 1926 + ymd[0] - 1; break;
			case 2: ymd[0] = 1989 + ymd[0] - 1; break;
		}

		var westernDate = moment({y: ymd[0], M: ymd[1], d: ymd[2]});
		var endOfEra = this.endOfEra[calendar];

		// 元号の終わりを超えている場合は弾く
		return (endOfEra && westernDate.isAfter(endOfEra)) ? moment.invalid() : westernDate;
	},

	convertToHalfWidthChar:function(char) {
		return String.fromCharCode(char.charCodeAt() - 65248);
	}
});
