//---------------------------------------------------------------------------
// プラムページ　インフォ追加・編集
//---------------------------------------------------------------------------
var PlumInformation = Backbone.View.extend({
	events: {
		'submit [name="post_info"]': "onSubmitValidate",
	},

	initialize: function(){
	},

/*=============================
=            Event            =
=============================*/
	onSubmitValidate: function(e) {
		var isValid = true;

		var $form = $(e.currentTarget);

		var d_year = $form.find('[name="date_year"]').val();
		var d_month = $form.find('[name="date_month"]').val();
		var d_day = $form.find('[name="date_day"]').val();

		var t_year = $form.find('[name="term_year"]').val();
		var t_month = $form.find('[name="term_month"]').val();
		var t_day = $form.find('[name="term_day"]').val();

		var title = $form.find('#info_title').val();
		var content = $form.find('#info_content').val();

		var err = $form.find('.error_message');

		if (d_year && d_month && d_day) {
			if (!_.validDate(d_year,d_month,d_day)) {
				$(err[0]).text("選択された日付が正しくありません。ご確認ください。");
				$(err[0]).show();
				isValid = false;
			} else {
				$(err[0]).hide();
			}
		} else {
			$(err[0]).text("日付は必ず入力してください。");
			$(err[0]).show();
			isValid = false;
		}

		if (t_year && t_month && t_day) {
			if (!_.validDate(t_year,t_month,t_day)) {
				$(err[1]).text("選択された日付が正しくありません。ご確認ください。");
				$(err[1]).show();
				isValid = false;
			} else {
				$(err[1]).hide();
			}
		} else {
			$(err[1]).text("日付は必ず入力してください。");
			$(err[1]).show();
			isValid = false;
		}

		if (!title) {
			$(err[2]).show();
			isValid = false;
		} else {
			$(err[2]).hide();
		}

		if (!content) {
			$(err[3]).show();
			isValid = false;
		} else {
			$(err[3]).hide();
		}

		if (isValid) {
			$form.find('[type="submit"]').prop('disabled', true);
		} else {
			return false;
		}
	},

/*=====  End of Event  ======*/

});