//---------------------------------------------------------------------------
// プラムページ　仲介の加盟店追加
//---------------------------------------------------------------------------
var BrokerStores = Backbone.View.extend({
	events:{
		"click .list_tr .add":"onClickAdd",
		"click .list_tr .remove":"onClickRemove",
	},

	addTpl: '<button class="add btn btn-sm btn-primary"><i class="fa fa-plus" aria-hidden="true"></i> 追加</button>',
	removeTpl: '<button class="remove btn btn-sm btn-warning"><i class="fa fa-minus" aria-hidden="true"></i> 外す</button>',

	isLoading:false,


	initialize:function(){
		this.$('#datatable-stores').dataTable({
			ordering: false,
		});

	},

	onClickAdd:function(e){
		var $target = $(e.currentTarget);
		var $tr = $target.parents(".list_tr");
		if(!this.isLoading){
			$target.prop("disabled",true);
			this.isLoading = true;
			this.ajax(__ADD_STORE_URL,{"store_id":$tr.data("id")},function(res){
				if(res.status){
					$tr.find(".btns").empty().append(this.removeTpl);
				}else{
					$target.prop("disabled",false);
					$tr.find(".btns").append(" Error");
				}
				
				this.isLoading = false;
			},function(){
				$target.prop("disabled",false);
				$tr.find(".btns").append(" Error");
				this.isLoading = false;
			});
		}
	},

	onClickRemove:function(e){
		var $target = $(e.currentTarget);
		var $tr = $target.parents(".list_tr");
		if(!this.isLoading){
			$target.prop("disabled",true);
			this.isLoading = true;
			this.ajax(__REMOVE_STORE_URL,{"store_id":$tr.data("id")},function(res){
				if(res.status){
					$tr.find(".btns").empty().append(this.addTpl);
				}else{
					$target.prop("disabled",false);
					$tr.find(".btns").append(" Error");
				}
				
				this.isLoading = false;
			},function(){
				$target.prop("disabled",false);
				$tr.find(".btns").append(" Error");
				this.isLoading = false;
			});
		}
	},


/*=============================
=            Event            =
=============================*/
	
	

/*=====  End of Event  ======*/



});