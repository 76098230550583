//---------------------------------------------------------------------------
// プラムページ　リスト
//---------------------------------------------------------------------------
var PlumAdvanceProgramList = Backbone.View.extend({
	events:{
		"click #set_program_btn":"onClickProgram",
		"click #set_program_submit":"onClickProgramSubmit",
		"click .select_clear_btn" : "onClickClearSelect",
	},



	initialize:function(){

	},


/*=============================
=            Event            =
=============================*/
	onClickProgram:function(){
		var $start = this.$("#date_start");

		var s_year = $start.find(".select-year").val();
		var s_month = $start.find(".select-month").val();
		var s_day = $start.find(".select-day").val();

		if(s_year && s_month && s_day){
			var startValid = _.validDate(s_year,s_month,s_day);
			if(startValid){
				var m = moment(s_year+"/"+s_month+"/"+s_day);
				this.$("#program_set_modal").find(".program_date").text(m.format("YYYY年MM月DD日"));
				this.$("#program_set_modal").modal("show");
			}
		}

		var action_flg = this.$("#action_flg").prop("checked");
		if(action_flg){
			this.$("#program_set_modal").find(".action_flg").show();
		}else{
			this.$("#program_set_modal").find(".action_flg").hide();
		}

		return false;
	},

	onClickProgramSubmit:function(){
		this.$("#program_set_form").submit();
		return false;
	},

	onClickClearSelect:function(e){
		var $this = $(e.currentTarget);
		var $parent = $this.parents(".clear_td");
		var $selects = $parent.find("select");
		$selects.each(function(){
			$(this).val("");
		});
	},

	

/*=====  End of Event  ======*/



});