//---------------------------------------------------------------------------
// 契約書のリストページ用JS
//---------------------------------------------------------------------------
var ContractList = Backbone.View.extend({
	events:{
		"click .select_clear_btn" : "onClickClearSelect",
		"click #search_form .panel-action-toggle" : "onClickSearchToggle",
	},

	initialize:function(){
		if(auto_reload){
			setTimeout(function(){
				location.reload();
			},reload_time*1000);
		}

		var search_toggle = Cookies.get('store_search_toggle');
		if(!search_toggle){
			this.$("#search_form").removeClass("panel-collapsed");
		}
	},

/*===============================
=            Program            =
===============================*/

/*=====  End of Program  ======*/

/*=============================
=            Event            =
=============================*/
	onClickClearSelect:function(e){
		var $this = $(e.currentTarget);
		var $parent = $this.parents(".clear_td");
		var $selects = $parent.find("select");
		$selects.each(function(){
			$(this).val("");
		});
	},

	onClickSearchToggle:function(){
		var self = this;
		setTimeout(function(){
			var col = self.$("#search_form").hasClass("panel-collapsed");
			if(col){
				Cookies.set('store_search_toggle', 1);
			}else{
				Cookies.remove('store_search_toggle');
			}
		},1000);
	},

/*=====  End of Event  ======*/

});