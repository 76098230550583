//---------------------------------------------------------------------------
// 契約書お客様の新規作成 確認画面JS
//---------------------------------------------------------------------------
var MyContractConfirm = Backbone.View.extend({

	hasBack:false,

	initialize:function(){

		// 戻るボタン制御
		if(!USE_BACK){
			// 戻るボタン制御
			history.pushState(null, null, null);
			var self = this;

			$(window).on('pageshow', function(event) {
		        if (event.originalEvent.persisted) {
		            // ここにBack Forward Cacheから復元された際の処理を記述する
		            self.hasBack = true;
		            setTimeout(function(){
		            	self.hasBack = false;
		            },50);
		        }
		    });

			window.addEventListener("popstate", function(e) {
				if(!self.hasBack){
					self.backAlert();
				}
				//alert("ブラウザの「戻る」ボタンはご利用になれません。お申込み内容の修正を行う場合は、最下部の「内容を修正する」ボタンより、お戻りください。");
				history.pushState(null, null, null);
				return false;
			});
		}

	},

/*===============================
=            Checker            =
===============================*/


/*=====  End of Checker  ======*/

});