//---------------------------------------------------------------------------
// カレンダーの動作
//---------------------------------------------------------------------------
var CalenderPicker = Backbone.View.extend({

	events:{
		"show":"onShow",
		"changeDate":"onChangeDate",
		"click select":"onClickSelect"
	},


	initialize:function(){
		
		var self = this;
		var $this = $(self.$el);
		var isBirthday = $this.hasClass("birthday");

		var option = {
			language:"ja",
			autoclose:true,
			todayHighlight: true,
			//daysOfWeekHighlighted:"0,6",
			startDate:self.getStartYear($this)+"-01-01",
			endDate:self.getLastYear($this)+"-12-31",
		};
		if(isBirthday){
			var mmt_obj = moment().subtract(20,'years');
			option["endDate"] = mmt_obj.format("YYYY-M-D");
		}

		$this.datepicker(option);

	},

	onShow:function(e){
		var self = this;
		var $this = $(self.$el);

		var year = this.getYear($this);
		var month = this.getMonth($this);
		var day = this.getDay($this);

		var has_month = month >= 0;

		if(year && has_month && day){
			this.$el.datepicker('update', new Date(year, month, day));
		}
	},
	onChangeDate:function(e){
		var date = e.date;
		this.setYear(this.$el,date);
		this.setMonth(this.$el,date);
		this.setDay(this.$el,date);
	},
	onClickSelect:function(e){
		return false;
	},

	getYear:function($cp){
		var val = $cp.find(".select-year").val();
		if(val != ""){
			return Number(val);
		}
		return false;
	},
	getMonth:function($cp){
		var val = $cp.find(".select-month").val();
		if(val != ""){
			return Number(val)-1;
		}
		return false;
	},
	getDay:function($cp){
		var val = $cp.find(".select-day").val();
		if(val != ""){
			return Number(val);
		}
		return false;
	},

	setYear:function($cp,date){
		$cp.find(".select-year").val(date.getFullYear());
	},
	setMonth:function($cp,date){
		$cp.find(".select-month").val(date.getMonth()+1);
	},
	setDay:function($cp,date){
		$cp.find(".select-day").val(date.getDate());
	},

	getStartYear:function($cp){
		var $options = $cp.find(".select-year option");
		var $value = $options.first().attr("value");
		if(!$value){
			$value = $($options.get(1)).attr("value");
		}
		return $value;
	},
	getLastYear:function($cp){
		return $cp.find(".select-year option").last().attr("value");
	},

});

//---------------------------------------------------------------------------
// カレンダーの動作
//---------------------------------------------------------------------------
var CalenderPickerBeri = Backbone.View.extend({

	events:{
		"show":"onShow",
		"changeDate":"onChangeDate",
		"click select":"onClickSelect"
	},


	initialize:function(){
		
		var self = this;
		var $this = $(self.$el);

		var $selectDate = this.$(".select-date");

		var option = {
			language:"ja",
			autoclose:true,
			todayHighlight: false,
			//daysOfWeekHighlighted:"0,6",
			startDate:$selectDate.find("option").eq(1).attr("value"),
			endDate:$selectDate.find("option").last().attr("value"),
		};

		$this.datepicker(option);

		var disable = [];
		setTimeout(function(){
			if(beri_master){
				_(beri_master).each( function( value, key, beri_master ) {
					if(value.is_all){
						var $start = moment(value.view_start2);
						var $end = moment(value.view_end2);

						var $now = null;

						var diff = $end.diff($start,"days")+1;
						for(var i = 0; i < diff; i++){
							if(_($now).isNull()){
								$now = $start;
							}else{
								$now.add(1,"day");
							}
							disable.push($now.format("YYYY/MM/DD"));
						}
					}
				});

				$this.datepicker('setDatesDisabled',disable);
			}
		},10);

	},

	onShow:function(e){
		var self = this;
		var $this = $(self.$el);

		var select = $this.find(".select-date").val();

		if(select){
			this.$el.datepicker('update', select);
		}
	},
	onChangeDate:function(e){
		var date = e.date;
		var self = this;
		var $this = $(self.$el);

		var select = $this.find(".select-date").val(moment(date).format("YYYY/MM/DD"));
	},
	onClickSelect:function(e){
		return false;
	},


});

//---------------------------------------------------------------------------
// ジェネレーター
//---------------------------------------------------------------------------
(function(){
    SCRIPTS["CalenderPicker"] = function(commonScript){
        commonScript.$(".calender-picker").each(function(){
            new CalenderPicker({el:this});
        });
    };
    SCRIPTS["CalenderPickerBeri"] = function(commonScript){
        commonScript.$(".calender-picker-beri").each(function(){
            new CalenderPickerBeri({el:this});
        });
    };
})();