//---------------------------------------------------------------------------
// サイン用JS
//---------------------------------------------------------------------------
var MyContractSign = Backbone.View.extend({
	events:{

		//"change #pdf_save_check":"onChangePdfSaveCheck",
		
		"click .sign_complate":"onComplateSign",
	},

    signTimeout:60000,
    signTimeoutSet:false,

    hasBack:false,

	initialize:function(){
		this.setSign();

        // 戻るボタン制御
        history.pushState(null, null, null);
        var self = this;

        $(window).on('pageshow', function(event) {
            if (event.originalEvent.persisted) {
                // ここにBack Forward Cacheから復元された際の処理を記述する
                self.hasBack = true;
                setTimeout(function(){
                    self.hasBack = false;
                },50);
            }
        });

        window.addEventListener("popstate", function(e) {
            if(!self.hasBack){
                self.backAlert("ブラウザの「戻る」ボタンはご利用になれません。");
            }
            //alert("ブラウザの「戻る」ボタンはご利用になれません。お申込み内容の修正を行う場合は、最下部の「内容を修正する」ボタンより、お戻りください。");
            history.pushState(null, null, null);
            return false;
        });
	},

	signed:false,

	setSign:function(){
		var self = this;
        var $signbox = $(".sign_box");
        var $canvas = $signbox.find(".canvas");
        var $clearbtn = $signbox.find(".sign_clear_btn");
        var $window = $(window);

        // 必要な変数を宣言しておく
        var canvas = $canvas[0];
        var c = canvas.getContext("2d");
        var drawing = false;
        var oldPos;

        canvas.width = 500;
        canvas.height = 200;
        c.strokeStyle = "#000000";
        c.lineWidth = 3;
        c.lineJoin = "round";
        c.lineCap = "round";

        //var image = c.getImageData(0, 0, canvas.width, canvas.height);

        // タップ開始時に、絵を描く準備をする
        canvas.addEventListener("touchstart", function (event) {
            drawing = true;
            oldPos = getPosT(event);
        }, false);
        
        // タップ終了時に、絵を描く後処理を行う
        canvas.addEventListener("touchend", function () {
            drawing = false;
        }, false);
        
        // gestureイベント（２本指以上で触ると発生するやつ）の
        // 終了時にも絵を描く後処理を行う
        canvas.addEventListener("gestureend", function () {
            drawing = false;
        }, false);

        // 実際に絵を描く処理
        // 前回に保存した位置から現在の位置迄線を引く
        canvas.addEventListener("touchmove", function (event) {
            var pos = getPosT(event);
            if (drawing) {
                c.beginPath();
                c.moveTo(oldPos.x, oldPos.y);
                c.lineTo(pos.x, pos.y);
                c.stroke();
                c.closePath();
                oldPos = pos;

                self.signed = true;
            }
        }, false);

        // タップ開始時に、絵を描く準備をする
        canvas.addEventListener("mousedown", function (event) {
            drawing = true;
            oldPos = getPosT(event);
        }, false);
        
        // タップ終了時に、絵を描く後処理を行う
        canvas.addEventListener("mouseup", function () {
            drawing = false;
        }, false);

        // タップ終了時に、絵を描く後処理を行う
        canvas.addEventListener("mouseout", function () {
            drawing = false;
        }, false);

        // 実際に絵を描く処理
        // 前回に保存した位置から現在の位置迄線を引く
        canvas.addEventListener("mousemove", function (event) {
            var pos = getPosT(event);
            if (drawing) {
                c.beginPath();
                c.moveTo(oldPos.x, oldPos.y);
                c.lineTo(pos.x, pos.y);
                c.stroke();
                c.closePath();
                oldPos = pos;

                self.signed = true;
            }
        }, false);


        // タップ位置を取得する為の関数群
        //function scrollX(){return document.documentElement.scrollLeft || document.body.scrollLeft;}
        //function scrollY(){return document.documentElement.scrollTop || document.body.scrollTop;}
        function scrollX(){return $(window).scrollLeft();}
        function scrollY(){return $(window).scrollTop();}
        function getPosT (event) {
            var mouseX = 0;
            var mouseY = 0;
            if(_(event.touches).isUndefined()){
                mouseX = event.clientX - $(canvas).offset().left + scrollX();
                mouseY = event.clientY - $(canvas).offset().top + scrollY();
            }else{
                mouseX = event.touches[0].clientX - $(canvas).offset().left + scrollX();
                mouseY = event.touches[0].clientY - $(canvas).offset().top + scrollY();
            }
            return {x:mouseX, y:mouseY};
        }

        $clearbtn.click(function () {
            c.clearRect(0, 0, canvas.width, canvas.height);
            c.beginPath();

            self.signed = false;
        });

        document.addEventListener("touchstart", this.stopDefault, {passive: false});
        document.addEventListener("touchmove", this.stopDefault, {passive: false});
        document.addEventListener("touchend", this.stopDefault, {passive: false});
        document.addEventListener("gesturestart", this.stopDefault, {passive: false});
        document.addEventListener("gesturechange", this.stopDefault, {passive: false});
        document.addEventListener("gestureend", this.stopDefault, {passive: false});

	},

	stopDefault:function(event) {
        if(event.touches.length){
            $target = $(event.touches[0].target);
            if($target.data("canvas") == "canvasData"){
                event.preventDefault();
            }
        }
        //event.preventDefault();
    },

	complete:function(){
		var base64 = document.getElementById("sign_canvas").toDataURL("image/png").replace('data:image/png;base64,', '');
		//var base64 = document.getElementById("sign_canvas").toDataURL("image/png");

		var data = {
            "base64":base64,
            "id":__SIGN_ID,
        };

        var self = this;

        this.$(".signErrorMessage").text("");

        this.signTimeoutSet = setTimeout(function(){
            $("body").waitMe("hide");
            clearTimeout(self.signTimeoutSet);
            if(self.xhrs[__SIGN_URL]){
                self.xhrs[__SIGN_URL].abort();
            }
            self.$(".signErrorMessage").text("署名送信タイムアウト。もう一度送信してください。");
        },this.signTimeout);

        this.ajax(__SIGN_URL,data,function(res){
            clearTimeout(self.signTimeoutSet);
        	window.location.href = __COMPLATE_URL;
        },function(){
            clearTimeout(self.signTimeoutSet);
            this.$(".signErrorMessage").text("署名送信タイムアウト。もう一度送信してください。");
        });
        /*
        self.ajaxModel.ajax('/helper/upload_sign/',data,function(res){
            if(!res.has_error){

            }else{
                alert(res.message);
            }
        });
        */
	},

	onComplateSign:function(){

		if(this.signed){
			$("body").waitMe({effect : 'progressBar'});
			this.complete();
		}else{
			alert("署名は必須です。")
		}

		return false;
	},





});