//---------------------------------------------------------------------------
// 契約書のリストページ用JS
//---------------------------------------------------------------------------
var ContractComplete = Backbone.View.extend({
	events:{
		"keydown #crepico_code" : "onEnterDontSubmit",
		"keyup #crepico_code" : "onBlurCrepicoCode",

		"click #crepico_submit_btn" : "onClickCrepicoSubmit",
		"click #crepico_update" : "onUpdateCrepico",
	},

	hasBack:false,

	initialize:function(){
		// 戻るボタン制御
		history.pushState(null, null, null);
		var self = this;

		$(window).on('pageshow', function(event) {
	        if (event.originalEvent.persisted) {
	            // ここにBack Forward Cacheから復元された際の処理を記述する
	            self.hasBack = true;
	            setTimeout(function(){
	            	self.hasBack = false;
	            },50);
	        }
	    });

		window.addEventListener("popstate", function(e) {
			if(!self.hasBack){
				self.backAlert("ブラウザの「戻る」ボタンはご利用になれません。");
			}
			//alert("ブラウザの「戻る」ボタンはご利用になれません。お申込み内容の修正を行う場合は、最下部の「内容を修正する」ボタンより、お戻りください。");
			history.pushState(null, null, null);
			return false;
		});

		this.$('#crepico_image').on('uploaded',function(){
			self.checkBtn();
		});
		this.$('#crepico_image').on('click','.remove-btn',function(){
			setTimeout(function(){self.checkBtn();},15);
		});
	},

/*===============================
=            Program            =
===============================*/

/*=====  End of Program  ======*/

/*=============================
=            Event            =
=============================*/

	onBlurCrepicoCode:function(){
		this.checkBtn();
	},

	checkBtn:function(){
		var val = this.$("#crepico_code").val();
		var crepico_img = this.$("#crepico_image").find(".id_data").val();
		if(_.isNumber(Number(val)) && !_.isNaN(Number(val)) && val.length == 5 && crepico_img){
			this.$("#crepico_submit_btn").attr("disabled",false);
		}else{
			this.$("#crepico_submit_btn").attr("disabled",true);
		}
	},

	onClickCrepicoSubmit:function(){
		$("#print").find("#crepico_image_upload_error").remove();
		var $modal = this.$("#crepico_modal");
		var $img = this.$("#crepico_image").find(".file-uploaded img");
		$modal.find(".img").empty();
		if($img.size()){
			var $cp = $img.clone(true);
			$cp.attr("width","100%");
			$modal.find(".img").append($cp);
		}
		$modal.find(".code").text(this.$("#crepico_code").val());
		$modal.modal("show");
		
		return false;
	},

	onUpdateCrepico:function(){
		this.$("#crepico_form").submit();
		this.$el.waitMe({effect : 'progressBar'});
		return false;
	},

/*=====  End of Event  ======*/

});