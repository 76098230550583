//---------------------------------------------------------------------------
// アイテムのセッティング
//---------------------------------------------------------------------------
var ItemSetting = Backbone.View.extend({
	events:{
		"click .add_item_btn":"onAddItem",

		"focus .money_input" : "onFocusChangeMoneyToNum",
		"blur .money_input" : "onBlurChangeNumToMoney",
		"keydown .no_enter" : "onEnterDontSubmit",

		"click .delete_item_btn" : "onClickDeleteItem",
		"click .edit_item_btn" : "onClickEditItem",

		"click #delete_item" : "onClickDelete",
		"click #edit_item" : "onClickEdit",

	},



	initialize:function(){
		
	},

/*===============================
=            Program            =
===============================*/

	itemCheck:function($form){
		var name = $form.find(".item_name").val();
		var code = $form.find(".item_code").val();
		var code_name = $form.find(".item_code_name").val();

		var month = $form.find(".item_month").val();
		var amount = $form.find(".item_amount").val();
		var price = $form.find(".item_price").val();

		var noError = true;

		if(code==""){
			$form.find(".error_code").show(200).find("p").text("商品コードは必須です");
			noError = false;
		}else{
			$form.find(".error_code").hide(200);
		}

		if(code_name==""){
			$form.find(".error_code_name").show(200).find("p").text("商品コード名は必須です");
			noError = false;
		}else{
			$form.find(".error_code_name").hide(200);
		}

		if(month!=""){
			if(_.isStrNum(month)){
				var num = Number(month);
				if(month < 1){
					$form.find(".error_month").show(200).find("p").text("提供期間は1以上の数字をご入力ください。");
					noError = false;
				}else{
					$form.find(".error_month").hide(200);
				}
			}else{
				$form.find(".error_month").show(200).find("p").text("提供期間は半角数字のみ入力可能です。");
				noError = false;
			}
		}else{
			$form.find(".error_month").hide(200);
		}

		if(amount!=""){
			if(_.isStrNum(amount)){
				var num = Number(amount);
				if(amount < 1){
					$form.find(".error_amount").show(200).find("p").text("数量は1以上の数字をご入力ください。");
					noError = false;
				}else{
					$form.find(".error_amount").hide(200);
				}
			}else{
				$form.find(".error_amount").show(200).find("p").text("数量は半角数字のみ入力可能です。");
				noError = false;
			}
		}else{
			$form.find(".error_amount").hide(200);
		}

		if(price!=""){
			var num = Number(price);
			if(price < 1){
				$form.find(".error_price").show(200).find("p").text("値段は1以上の数字をご入力ください。");
				noError = false;
			}else{
				$form.find(".error_price").hide(200);
			}
		}else{
			if(name && price==""){
				$form.find(".error_price").show(200).find("p").text("商品名がある場合、価格は必須です。");
				noError = false;
			}else{
				$form.find(".error_price").hide(200);
			}
		}

		return noError;
	},
	
/*=====  End of Program  ======*/

/*=============================
=            Event            =
=============================*/
	onAddItem:function(){
		var noError = this.itemCheck(this.$("#add_item"));
		if(noError){
			this.$el.waitMe({effect : 'progressBar'});
		}
		return noError;
	},

	onClickDeleteItem:function(e){
		var $this = $(e.currentTarget).parents(".item_box");
		var $modal = this.$("#item_delete_modal");

		$modal.find(".item_id").val($this.data("id"));

		$modal.find(".name").text($this.find(".name").text());
		$modal.find(".code").text($this.find(".code").text());
		$modal.find(".code_name").text($this.find(".code_name").text());
		$modal.find(".month").text($this.find(".month").text());
		$modal.find(".amount").text($this.find(".amount").text());
		$modal.find(".price").text($this.find(".price").text());

		$modal.modal("show");
		return false;
	},

	onClickEditItem:function(e){
		var $this = $(e.currentTarget).parents(".item_box");
		var $modal = this.$("#item_edit_modal");

		$modal.find(".item_id").val($this.data("id"));

		$modal.find(".item_name").val($this.find(".name").text());
		$modal.find(".item_code").val($this.find(".code").text());
		$modal.find(".item_code_name").val($this.find(".code_name").text());
		$modal.find(".item_month").val($this.find(".month").text());
		$modal.find(".item_amount").val($this.find(".amount").text());
		$modal.find(".item_price").val($this.find(".price").data("price"));

		$modal.modal("show");
		return false;
	},

	onClickEdit:function(e){
		var noError = this.itemCheck(this.$("#edit_item_form"));
		if(noError){
			this.$("#edit_item_form").submit();
			this.$el.waitMe({effect : 'progressBar'});
		}
		return false;
	},

	onClickDelete:function(e){
		this.$("#delete_item_form").submit();
		this.$el.waitMe({effect : 'progressBar'});
		return false;
	},




/*=====  End of Event  ======*/

});