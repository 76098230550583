//---------------------------------------------------------------------------
// プラムページ　リスト
//---------------------------------------------------------------------------
var AdvanceCheckList = Backbone.View.extend({
	events:{
		"click #set_program_btn":"onClickProgram",

		"click #set_program_submit" : "onClickSubmit",
	},



	initialize:function(){

	},


/*=============================
=            Event            =
=============================*/
	onClickProgram:function(){
		this.$("#program_set_modal").modal("show");

		return false;
	},

	onClickSubmit:function(){
		this.$("#program_set_form").submit();
		return false;
	},

	

/*=====  End of Event  ======*/



});