//---------------------------------------------------------------------------
// 契約書お客様の同意エリアJS
//---------------------------------------------------------------------------
var MyContractText = Backbone.View.extend({
	events:{

		"change .textCheckbox" : "onCheckCheckbox",

		"click .agree_btn_area .agree a" : "onClickAgreeBtn",

	},

	hasAgreeBtn : false,

	hasBack:false,

	initialize:function(){
		// 戻るボタン制御
		if(!USE_BACK){
			// 戻るボタン制御
			history.pushState(null, null, null);
			var self = this;

			$(window).on('pageshow', function(event) {
		        if (event.originalEvent.persisted) {
		            // ここにBack Forward Cacheから復元された際の処理を記述する
		            self.hasBack = true;
		            setTimeout(function(){
		            	self.hasBack = false;
		            },50);
		        }
		    });

			window.addEventListener("popstate", function(e) {
				if(!self.hasBack){
					self.backAlert("ブラウザの「戻る」ボタンはご利用になれません。");
				}
				//alert("ブラウザの「戻る」ボタンはご利用になれません。お申込み内容の修正を行う場合は、最下部の「内容を修正する」ボタンより、お戻りください。");
				history.pushState(null, null, null);
				return false;
			});
		}


		if(this.$(".agree_btn_area .agree").size()){
			this.hasAgreeBtn = true;
		}

		this.onCheckCheckbox();
	},

	onCheckCheckbox:function(){
		var check = true;

		this.$(".textCheckbox").each(function(){
			var $this = $(this);
			if(check){
				check = $this.prop("checked");
			}
		});

		if(this.hasAgreeBtn){
			if(this.$(".agree_btn_area .agree").hasClass("checked")){
				// チェックされている
			}else{
				check = false;
			}
		}

		if(check){
			this.$(".submit_btn_area").fadeIn(400);
		}else{
			this.$(".submit_btn_area").hide();
		}
	},

	onClickAgreeBtn:function(){
		if(this.$(".agree_btn_area .agree").hasClass("checked")){
			this.$(".agree_btn_area .agree").removeClass("checked");
		}else{
			this.$(".agree_btn_area .agree").addClass("checked");
		}

		this.onCheckCheckbox();
		return false;
	},

});