//---------------------------------------------------------------------------
// プラムページ　マニュアル追加・更新アップ
//---------------------------------------------------------------------------
var PlumManual = Backbone.View.extend({
	events:{
		'change #manual_pdf' : 'onChangeFile',
		'submit [name="post_manual"]' : 'onSubmitForm'
	},
	initialize:function() {
	},

/*=============================
=            Event            =
=============================*/

	onChangeFile: function() {
		var path = this.$('#manual_pdf').val();
		var $p = this.$('#manual_btn + p');
		if (path) $p.text(path.substr(path.lastIndexOf('\\') + 1));
	},

/*=====  End of Event  ======*/

});