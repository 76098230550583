//---------------------------------------------------------------------------
// 担当者のセッティング
//---------------------------------------------------------------------------
var StaffSetting = Backbone.View.extend({
	events:{
		"click .edit_btn":"onEditStaff",
		"click .add_btn":"onAddStaff",

		"click .delete_btn":"onDeleteStaff",

		"keydown .name1" : "onEnterDontSubmit",
		"keydown .name2" : "onEnterDontSubmit",

		"click #delete_staff" : "onClickDelete",

	},



	initialize:function(){
		
	},

/*===============================
=            Program            =
===============================*/

	staffCheck:function($form){
		var name1 = $form.find(".name1").val();
		var name2 = $form.find(".name2").val();

		var noError = true;

		if(name1=="" || name2==""){
			$form.find(".error_message").show(200).find("p").text("担当者氏名は必須です。");
			noError = false;
		}else{
			$form.find(".error_message").hide(200);
		}
		return noError;
	},
	
/*=====  End of Program  ======*/

/*=============================
=            Event            =
=============================*/
	onEditStaff:function(e){

		var $form = $(e.currentTarget).parents(".staff_form_area");

		var noError = this.staffCheck($form);
		if(noError){
			this.$el.waitMe({effect : 'progressBar'});
			$form.submit();
		}
		return false;
	},

	onAddStaff:function(e){

		var $form = $(e.currentTarget).parents(".staff_form_area");

		var noError = this.staffCheck($form);
		if(noError){
			this.$el.waitMe({effect : 'progressBar'});
			$form.submit();
		}
		return false;
	},

	onDeleteStaff:function(e){
		var $this = $(e.currentTarget).parents(".staff_form_area");
		var $modal = this.$("#staff_delete_modal");

		$modal.find(".name").text($this.find(".name1").val()+" "+$this.find(".name2").val());
		$modal.find(".staff_id").val($this.find(".staff_id").val());

		$modal.modal("show");
		return false;
	},


	onClickDelete:function(e){
		this.$("#delete_staff_form").submit();
		this.$el.waitMe({effect : 'progressBar'});
		return false;
	},




/*=====  End of Event  ======*/

});