//---------------------------------------------------------------------------
// PDFのダウンロードページ
//---------------------------------------------------------------------------
var MyContractPdf = Backbone.View.extend({
	events:{

		"change #pdf_save_check":"onChangePdfSaveCheck",
		"click #complate_btn":"onClickComplateBtn",
		

	},

	initialize:function(){
		this.onChangePdfSaveCheck();
	},

	onChangePdfSaveCheck:function(){
		var check = this.$("#pdf_save_check").prop("checked");
		if(check){
			this.$(".submit_btn_area").fadeIn(400);
		}else{
			this.$(".submit_btn_area").fadeOut(400);
		}
	},

	onClickComplateBtn:function(){
		this.$el.waitMe({effect : 'progressBar'});
		this.$("#complate_form").submit();
		return false;
	},



});