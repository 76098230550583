//---------------------------------------------------------------------------
// 契約書の新規作成JS
//---------------------------------------------------------------------------
var ContractEdit = Backbone.View.extend({
	events:{
		// フォームの送信（チェック）
		"submit #MainForm" : "onSubmit",
		"click #form_submit_bt2" : "onSubmit",

		// 金額のフォームを自動的にお金の表記に変更し、なおかつ、エンター押してもsubmitさせない
		"focus .money_input" : "onFocusChangeMoneyToNum",
		"blur .money_input" : "onBlurChangeNumToMoney",
		"keydown .money_input" : "onEnterDontSubmit",

		// 申込予定年月日
		"change #create_apply_year":"onChangeCreateApply",
		"change #create_apply_month":"onChangeCreateApply",
		"change #create_apply_day":"onChangeCreateApply",
		"changeDate #create_apply_calender":"onChangeCreateApply",

		// 提供するサービス
		"change #sales_style-1" : "onChangeService", // 特定継続的役務販売 -> 指定役務固定
		"change #sales_style-2" : "onChangeService", // 物品販売※特役業者使用
		"change #sales_style-3" : "onChangeService", // 未使用
		"change #sales_style-4" : "onChangeService", // メディカル（特役対象） -> 指定役務固定
		"change #sales_style-5" : "onChangeService", // 未使用
		"change #sales_style-6" : "onChangeService", // 訪問販売
		"change #sales_style-7" : "onChangeService", // 連鎖販売
		"change #sales_style-8" : "onChangeService", // 電話勧誘販売
		"change #sales_style-9" : "onChangeService", // 業務提供誘引販売
		"change #sales_style-10" : "onChangeService", // メディカル -> 指定外役務固定
		"change #sales_style-11" : "onChangeService", // 企業間取引
		"change #sales_style-12" : "onChangeService", // 物品販売（特定契約外）
		"change #sales_style-13" : "onChangeService", // 役務提供（特定契約外） -> 指定外役務固定
		"change #sales_style-14" : "onChangeService", // 通信販売

		"click #sale_type_clear_btn" : "onClickClearService",

		// 移転開始時期
		"change #start_of_offer_year":"onChangeStartOffer",
		"change #start_of_offer_month":"onChangeStartOffer",
		"change #start_of_offer_day":"onChangeStartOffer",
		"changeDate #start_of_offer_calender":"onChangeStartOffer",

		"change #end_of_offer_year":"onChangeEndOffer",
		"change #end_of_offer_month":"onChangeEndOffer",
		"change #end_of_offer_day":"onChangeEndOffer",

		// 引渡し時期の利用処理
		//"change #sales_style-2" : "onUseAttendItemDelivery",
		"change #is_attend_item-1" : "onUseAttendItemDelivery",
		"change #is_attend_item-2" : "onUseAttendItemDelivery",

		"change #attend_item_delivery_select" : "onChangeAttendItemDelivery",

		"change #is_specific_sale-1" : "onChangeSpecificSale",
		"change #is_specific_sale-2" : "onChangeSpecificSale",





		// アイテムエリアに関する処理
		"click .item_show_select_btn" : "onClickShowSelectItem",
		"click .modal_select_item" : "onClickModalSelectItem",
		"click .item_empty_btn"	: "onClickItemEmpty",

		"blur .input_item_name_area" : "onStartItemAreaReload",
		"change .select_item_amount_area" : "onStartItemAreaReload",
		"blur .input_item_price_area" : "onStartItemAreaReload",
		"keyup .input_item_price_area" : "onKeyUpItemPriceArea",

		"change #discount_item_price" : "onChangeDiscountItemPrice",
		"blur #discount_item_price" : "onStartItemAreaReload",
		"keyup #discount_item_price" : "onKeyUpItemPriceArea",

		// お支払い方法に関するエリア
		"keyup #loan_deposit" : "onKeyUpLoanDeposit",
		"change #loan_deposit" : "onEventLoanSimlation",

		"change #burden_commission" : "onChangeBurdenCommission",
		//"change #burden_commission" : "onEventLoanSimlation",

		"change #is_payment_type-1" : "onChangePaymentType",
		"change #is_payment_type-2" : "onChangePaymentType",
		"change #is_payment_type-3" : "onChangePaymentType",
		"change #is_payment_type-4" : "onChangePaymentType",

		"keyup #twoPayInput1AreaInput" : "onKeyUpTwoPayMoney",

		"change #payment_manual_count" : "onCheckBurdenCommision", //onEventLoanSimlation
		"change #paymentBurdenCommissionNumSelect" : "onCheckBurdenCommision", //onEventLoanSimlation
		"change #paymentBurdenCommissionNumSelectOneType" : "onCheckBurdenCommision", //onEventLoanSimlation
		"change #paymentBurdenCommissionNumSelectTwoType" : "onCheckBurdenCommision", //onEventLoanSimlation

		"change #bonusPayMoneyInput"     : "onCheckBonusMoney",
		//"change #bonusPayMoneyInput"     : "onEventLoanSimlation",
		"change #bonus_pay_month_form" : "onChangeBonusPayMonthForm",

		"change #pay_bonus_summer_month" : "onEventLoanSimlation",
		"change #pay_bonus_winter_month" : "onEventLoanSimlation",

		"change #onePaySelect1" : "onSetBonusPaymentCount", //onEventLoanSimlation

		"change #twoPaySelect1" : "onChangeTwoPaySelect1", //onEventLoanSimlation
		// "change #twoPaySelect1" : "onSetBonusPaymentCount", //onEventLoanSimlation
		"change #twoPaySelect2" : "onSetBonusPaymentCount", //onEventLoanSimlation
		"change #twoPayInput1AreaInput" : "onEventLoanSimlation",

		"click .simlation_reload" : "onEventLoanSimlation",


		"change #staff_select" : "onSelectStaff",

		"change [name='mail_address_select_domain']" : "onChangeMailDomainSelect",

	},

	$item_select_area:null,
	$item_select_modal:null,

	is_buppin:false,
	is_medical:false,

	pay_type:null,

	is_calculation:false,
	do_next_calculation:false,
	load_loan_calc:true,

	is_simlation_error:false,

	select_specific_type_select:"",

	simTimeout:60000,
	simTimeoutSet:false,

	initialize:function(){

		// エラーメッセージを非表示にする
		// this.resetErrorMessage();

		// デフォルトの役務（権利）の種類を入れる
		this.select_specific_type_select = this.$("#specific_type_select").val();
		
		// 指定役務を決める
		//this.onChangeSpecificSale();
		this.onChangeService();

		// 引渡し時期の設定
		this.onUseAttendItemDelivery();
		this.onChangeAttendItemDelivery();

		this.onKeyUpLoanDeposit();

			this.onChangePaymentType();

			// 一括、2回払いの月計算を行う。
			this.funcSetPayMonthCol();

			// スタッフの情報を変更
		// if(!isAfAcst){
		// 	this.onSelectStaff();
		// }
		this.onSelectStaff();

		// メールアドレス（その他かどうか）
		this.onChangeMailDomainSelect();

		this.$item_select_modal = this.$("#item_list_modal");

		this.$("#form_submit_btn").prop("disabled",false);

		var $saleTypeArea = this.$('#salesStyleArea');
		if (!canChangeSaleType) {
			$saleTypeArea.css({ opacity: 0.6 });
			$saleTypeArea.find('[type="checkbox"]').prop('disabled', true);
		}
		$saleTypeArea.removeClass('pe-none');
	},

	onSubmit:function(){
		if(!this.checkdata()){
			this.toErrorMessageFirst();
			return false;
		}

		this.$('#salesStyleArea [type="checkbox"]').prop('disabled', false);
		this.$('#specificSaleArea [type="radio"]').prop('disabled', false);

		if(this.isSpecifixBuppin()){
			this.$("#is_specific_sale_fix").prop('disabled', true);
		}

		this.$el.waitMe({effect : 'progressBar'});
		return true;
	},

	// フォームのチェック
	checkdata:function(){
		this.resetErrorMessage();

		// 申込予定日
		if(!isAfAcst){
			this.checkCreateApplyDate();
		}

		// 販売形態
		this.checkSaleStyle();

		// 指摘役務の別に関するバリデータ（複数）
		this.checkServiceTypeContent();

		// 役務提供期間
		this.checkServiceOfferRange();

		// 引き渡し期間のヴァリデーション
		this.checkDeliveryTime();

		// 販売する商品情報
		this.checkSaleItemList();

		// 値引き
		this.checkDiscount();

		// 頭金が入っているかどうか
		this.checkLoanDeposit();

		// 申し込み金額の上限制御
		this.checkLoanApplyMoney();

		// アドオン月利を処理
		this.checkAddon();

		// お支払区分をチェック
		this.checkPayment();

		// 支払い回数を確定
		//if(!isAfAcst){
			this.checkLoanCount();
		//}

		//if(!isAfAcst){
			// ボーナス支払いの記述
			this.checkPayBonus();
			this.checkPayOne();
			this.checkPayTwo();
		//}

		// シミュレーションのエラーがあるかどうか
		this.checkSimlationError();

		//スタッフのチェック
		// if(!isAfAcst){
		// 	this.checkContractStaff();
		// }
		this.checkContractStaff();

		if(!isTablet){

			//メールアドレスの名前チェック
			this.checkMailAddressName();

			//メールアドレスのチェック
			this.checkMailAddress();
		}else{
			// 売買契約書のチェック
			this.checkFiles();
		}
		//return false;
		return !this.hasError();
	},

/*===============================
=            Checker            =
===============================*/


	// 契約書作成年月日
	checkCreateApplyDate:function(){
		// エラーのキー
		var errorKey = "createApplyDate";

		// まず削除
		this.delError(errorKey);

		var year = this.$("#create_apply_year").val();
		var month = this.$("#create_apply_month").val();
		var day = this.$("#create_apply_day").val();

		var isValid = !_.validDate(year,month,day);

		// バリデートする
		if(!Validator.isDate(year,month,day) || isValid){
			this.setError(errorKey,"正しい日付をご入力ください。");
			this.setErrorMessage("#"+errorKey,this.getError(errorKey));
			this.delHiddenInput("#"+errorKey,"ctsp_dt_created");
		}else{
			var check2;
			if(!isTablet){
				check2 = moment().add(1,"M").endOf("month").isSameOrAfter(year+"/"+month+"/"+day);
				if(check2){
					this.setHiddenInput("#"+errorKey,"ctsp_dt_created",year+"-"+month+"-"+day);
				}else{
					this.setError(errorKey,"申込予定年月日は翌月末以降は選択できません。");
					this.setErrorMessage("#"+errorKey,this.getError(errorKey));
					this.delHiddenInput("#"+errorKey,"ctsp_dt_created");
				}
			}else{
				check2 = moment().isSameOrAfter(year+"/"+month+"/"+day);
				if(check2){
					this.setHiddenInput("#"+errorKey,"ctsp_dt_created",year+"-"+month+"-"+day);
				}else{
					this.setError(errorKey,"申込年月日は、翌日以降は選択出来ません。");
					this.setErrorMessage("#"+errorKey,this.getError(errorKey));
					this.delHiddenInput("#"+errorKey,"ctsp_dt_created");
				}
			}

		}
	},

	// 販売形態
	checkSaleStyle:function(){
		// エラーのキー
		var errorKey = "salesStyleArea";

		// まず削除
		this.delError(errorKey);

		var arrays = [];

		arrays.push(this.$("#sales_style-1").prop("checked"));
		arrays.push(this.$("#sales_style-2").prop("checked"));
		//arrays.push(this.$("#sales_style-3").prop("checked"));
		arrays.push(this.$("#sales_style-4").prop("checked"));
		//arrays.push(this.$("#sales_style-5").prop("checked"));
		arrays.push(this.$("#sales_style-6").prop("checked"));
		arrays.push(this.$("#sales_style-7").prop("checked"));
		arrays.push(this.$("#sales_style-8").prop("checked"));
		arrays.push(this.$("#sales_style-9").prop("checked"));
		arrays.push(this.$("#sales_style-10").prop("checked"));
		arrays.push(this.$("#sales_style-11").prop("checked"));
		arrays.push(this.$("#sales_style-12").prop("checked"));
		arrays.push(this.$("#sales_style-13").prop("checked"));
		arrays.push(this.$("#sales_style-14").prop("checked"));

		// バリデートする
		if(!Validator.isSome(arrays)){
			this.setError(errorKey,"どれか一つはチェックしてください");
			this.setErrorMessage("#"+errorKey,this.getError(errorKey));
			//this.delHiddenInput("#"+errorKey,"ctsp_create_apply_date");
		}else{
			//this.setHiddenInput("#"+errorKey,"ctsp_create_apply_date",year+"-"+month+"-"+day);
		}
	},


	// 指摘役務の別に関するバリデータ（複数）
	checkServiceTypeContent:function(){
		// エラーのキー
		//var errorKey1 = "specificSaleArea";
		//var errorKey2 = "specificContentArea";
		var errorKey3 = "specificTypeArea";

		// まず削除
		//this.delError(errorKey1);
		//this.delError(errorKey2);
		this.delError(errorKey3);

		// 指定役務（権利）、指定役務（権利）外のvalue
		// var $checked = this.$('#specificSaleArea input:checked');
		// var value = $checked.val();

		// var arrays = [];

		// arrays.push(this.$("#sales_style-1").prop("checked"));
		// arrays.push(this.$("#sales_style-3").prop("checked"));
		// arrays.push(this.$("#sales_style-3").prop("checked"));
		// arrays.push(this.$("#sales_style-3").prop("checked"));

		var hissu = this.$("#tr_specific_type .ekimu_category_hissu").is(':visible');


		// 指定役務、権利販売のどちらかにチェックがあるかどうか
		if(hissu){
			var type_select_val = this.$("#specific_type_select").val();
			if(!type_select_val){
				this.setError(errorKey3,"どれか一つはチェックしてください");
				this.setErrorMessage("#"+errorKey3,this.getError(errorKey3));
			}
		}else{
		}

		
	},

	//役務提供期間
	checkServiceOfferRange:function(){
		// エラーのキー
		var errorKey = "serviceRangeDate";

		// まず削除
		this.delError(errorKey);
		this.delHiddenInput("#"+errorKey,"ctsp_dt_start_of_offer");
		this.delHiddenInput("#"+errorKey,"ctsp_dt_end_of_offer");

		//役務提供かどうか
		var isServiceOffer = this.$("#sales_style-11").prop("checked");

		var syear = this.$("#start_of_offer_year").val();
		var smonth = this.$("#start_of_offer_month").val();
		var sday = this.$("#start_of_offer_day").val();

		var eyear = this.$("#end_of_offer_year").val();
		var emonth = this.$("#end_of_offer_month").val();
		var eday = this.$("#end_of_offer_day").val();

		if(isServiceOffer || (!this.isBuppin() && !this.isNotSpecific())){
			// バリデートする

			var isValid1 = !_.validDate(syear,smonth,sday);
			var isValid2 = !_.validDate(eyear,emonth,eday);

			if(!Validator.isDate(syear,smonth,sday) || !Validator.isDate(eyear,emonth,eday) || isValid1 || isValid2){
				this.setError(errorKey,"正しい日付をご入力ください。");
				this.setErrorMessage("#"+errorKey,this.getError(errorKey));
				this.delHiddenInput("#"+errorKey,"ctsp_dt_start_of_offer");
				this.delHiddenInput("#"+errorKey,"ctsp_dt_end_of_offer");
			}else{
				if(!Validator.isRangeDate(syear,smonth,sday,eyear,emonth,eday)){
					this.setError(errorKey,"正しい期間を選択してください");
					this.setErrorMessage("#"+errorKey,this.getError(errorKey));
					this.delHiddenInput("#"+errorKey,"ctsp_dt_start_of_offer");
					this.delHiddenInput("#"+errorKey,"ctsp_dt_end_of_offer");
				}else{
					this.setHiddenInput("#"+errorKey,"ctsp_dt_start_of_offer",syear+"-"+smonth+"-"+sday);
					this.setHiddenInput("#"+errorKey,"ctsp_dt_end_of_offer",eyear+"-"+emonth+"-"+eday);
				}
			}
		}
	},

	// 引き渡し時期の入力
	checkDeliveryTime:function(){
		// エラーのキー
		var errorKey = "deliveryTimeArea";

		// まず削除
		this.delError(errorKey);

		if(this.funcCheckUseDeliveryTime()){
			var delVal = this.$("#attend_item_delivery_select").val();

			if(delVal){
				var year = this.$("#create_apply_year").val();
				var month = this.$("#create_apply_month").val();
				var day = this.$("#create_apply_day").val();

				// バリデートする
				var moment_obj = moment(year+"/"+month+"/"+day).add(delVal, 'days');
				var text = moment_obj.format("（YYYY年MM月DD日頃）");

			}else{
				this.setError(errorKey,"引渡し時期を設定してください");
				this.setErrorMessage("#"+errorKey,this.getError(errorKey));
			}
		}else{
		}
	},

	checkSaleItemList:function(){
		// エラーのキー
		var errorKey = "itemsErrorArea";
		var errorKeyItem1 = "sale_item1";
		var errorKeyItem2 = "sale_item2";
		var errorKeyItem3 = "sale_item3";
		var errorKeyItem4 = "sale_item4";

		// まず削除
		this.delError(errorKey);
		this.delError(errorKeyItem1);
		this.delError(errorKeyItem2);
		this.delError(errorKeyItem3);
		this.delError(errorKeyItem4);

		var total = this.$("#total_item_price").val();
		total = changeNumFomatInt(total);

		// アイテムが一つ目は必ず必須
		var $first = this.$(".sale_item_tr .items").first();

		var id = $first.find(".input_item_id_area").val();
		var name = $first.find(".item_name input").val();
		var amount = $first.find(".item_amount input").val();
		var price = $first.find(".item_price input").val();

		if(id=="" || name=="" || amount=="" || amount=="0" || price==""){
			if(id==""){
				this.setError(errorKeyItem1,"商品1は、必ずどれかを商品を選択してください。");
			}else{
				this.setError(errorKeyItem1,"商品1は、必ず商品名、数量、価格全て、ご入力ください。また、数量は、「1」以上の入力となります。");
			}
			
			this.setErrorMessage("#"+errorKeyItem1,this.getError(errorKeyItem1));
		}else{
			if(total && (total > 0)){
			}else{
				this.setError(errorKey,"販売する商品の情報が異常です。ご確認ください。");
				this.setErrorMessage("#"+errorKey,this.getError(errorKey));
			}

			var self = this;

			var $items = this.$(".sale_item_tr .items");
			$items.each(function(index){
				var int = index+1;
				var $this = $(this);
				var nid = $this.find(".input_item_id_area").val();
				var name = $this.find(".item_name input").val();
				var amount = $this.find(".item_amount input").val();
				var price = $this.find(".item_price input").val();
				if(nid!="" && nid!="0"){
					if(name=="" || amount=="" || amount=="0" || price=="" || !Validator.isNumber(amount)){
						var error_id = eval("errorKeyItem"+int);
						self.setError(error_id,"入力項目に不備があります。正しい情報をご入力ください。数量は、半角数字のみ入力可能です。");
						self.setErrorMessage("#"+error_id,self.getError(error_id));
					}
				}
			});
		}
	
	},

	checkDiscount:function(){
		// エラーのキー
		var errorKey = "discountArea";

		// まず削除
		this.delError(errorKey);

		var val = this.$("#discountArea").find("#discount_item_price").val();
		
		val = changeNumFomatInt(val);

		this.delHiddenInput("#"+errorKey,"ctsp_int_discount");
		if(val){
			this.setHiddenInput("#"+errorKey,"ctsp_int_discount",val);
		}else{
			this.setHiddenInput("#"+errorKey,"ctsp_int_discount",0);
		}

	},
	checkLoanDeposit:function(){
		// エラーのキー
		var errorKey = "loanDepositArea";
		var errorKey2 = "loanApplyArea";

		// まず削除
		this.delError(errorKey);
		this.delError(errorKey2);

		var val = this.$("#loanDepositArea").find("#loan_deposit").val();
		var val_total = this.$("#loanApplyArea").find("#loan_total").val();
		var val_price_total = this.$("#total_item_price2").val();
		
		val = changeNumFomatInt(val);
		val_total = changeNumFomatInt(val_total);
		val_price_total = changeNumFomatInt(val_price_total);

		if(val!==""){
			// ローンのトータル金額
			if(val_total > 0){
				this.setHiddenInput("#"+errorKey,"ctsp_int_deposit",val);
				this.setHiddenInput("#"+errorKey,"ctsp_int_total_deposit",val_price_total);
			}else{
				this.setError(errorKey2,"残金が正しくありません。");
				this.setErrorMessage("#"+errorKey2,this.getError(errorKey2));
				this.delHiddenInput("#"+errorKey,"ctsp_int_deposit");
				this.delHiddenInput("#"+errorKey,"ctsp_int_total_deposit");
			}			
		}else{
			this.setError(errorKey,"頭金は必ず、設定してください。");
			this.setErrorMessage("#"+errorKey,this.getError(errorKey));
			this.delHiddenInput("#"+errorKey,"ctsp_int_deposit");
			this.delHiddenInput("#"+errorKey,"ctsp_int_total_deposit");
		}

	},

	checkLoanApplyMoney:function(){
		// エラーのキー
		var errorKey = "loanApplyArea";

		// まず削除
		this.delError(errorKey);
		this.hideErrorMessage("#"+errorKey);

		var loanApplyMoney = changeNumFomatInt(this.$("#loan_total").val());
		if(!loanApplyMoney){
			return false;
		}

		if(loanApplyMoney > maxLoanApplyMoney || loanApplyMoney < minLoanApplyMoney){
			this.setError(errorKey,"残金をご確認ください。(上限"+changeNumFomatMoney(maxLoanApplyMoney)+"円以下:下限"+changeNumFomatMoney(minLoanApplyMoney)+"円以上）");
			this.setErrorMessage("#"+errorKey,this.getError(errorKey));

			return false;
		}

		return true;
	},

	checkAddon:function(){
		// 支払い回数
		var count = 0;
		if(this.pay_type==1 || this.pay_type==2){
			count = this.$("#payment_manual_count").val();
		}else if(this.pay_type==3){
			count = 1;
		}else if(this.pay_type==4){
			count = 2;
		}

		// 手数料全額負担かどうか
		var total          = changeNumFomatInt(this.$("#total_item_price2").val());
        var down           = changeNumFomatInt(this.$("#loan_deposit").val());
        if(!down){
        	down = 0;
        }
		var addon = this.funcGetAddon(total-down,count);
		var shop_addon = this.funcGetShopAddon(total-down,count);

		// if(count == 1 || count == 2){
		// 	addon = 0;
		// 	shop_addon = 0;
		// }

		//console.log(addon,total,down,count);
		this.$("#addon_num").val(addon);
		this.$("#shop_addon_num").val(shop_addon);
	},

	checkPayment:function(){
		// エラーのキー
		var errorKey = "paymentTypeArea";

		// まず削除
		this.delError(errorKey);

		if(!this.pay_type){
			this.setError(errorKey,"お支払区分は必ず選択してください。");
			this.setErrorMessage("#"+errorKey,this.getError(errorKey));
		}
	},

	checkLoanCount:function(){
		// エラーのキー
		var errorKey = "paymentNumArea";
		var errorKeyManual = "pay_manual_count";
		var errorKeyBurdenCount = "paymentBurdenCommissionNumArea";
		this.delError(errorKeyManual);
		this.delError(errorKeyBurdenCount);

		this.delHiddenInput("#"+errorKey,"ctsp_tint_payment_count");

		var $area = this.$("#paymentTypeArea");
		var $num_area = this.$("#paymentNumArea");
		var value = this.pay_type;

		var $burdenCount = this.$("#paymentBurdenCommissionNumSelect");
		var $burdenCountOne = this.$("#paymentBurdenCommissionNumSelectOneType");
		var $burdenCountTwo = this.$("#paymentBurdenCommissionNumSelectTwoType");

		// 手数料全額負担かどうか
		var burden_commission = 0;
		if(this.$("#burden_commission").prop("type") == "checkbox"){
			burden_commission = this.$("#burden_commission").prop("checked");
		}else{
			burden_commission = Number(this.$("#burden_commission").val());
		}

		if(value == 1 || value == 2){
			var count = $num_area.find(".pay_manual").find("select").val();
			if(count){
				this.setHiddenInput("#"+errorKey,"ctsp_tint_payment_count",$num_area.find(".pay_manual").find("select").val());
				if($burdenCount.size() && !burden_commission){
					var burdenCount = $burdenCount.val();
					if(Number(burdenCount) > Number(count)-1){
						this.setError(errorKeyBurdenCount,"全額負担の場合は上記にある「加盟店全額負担」にチェックを入力します。");
						this.setErrorMessage("#"+errorKeyBurdenCount,this.getError(errorKeyBurdenCount));
					}else{
						this.setHiddenInput("#"+errorKey,"ctsp_int_burden_commission_number",burdenCount);
					}
				}
			}else{
				this.setError(errorKeyManual,"お支払い回数は必ずご選択ください。");
				this.setErrorMessage("#"+errorKeyManual,this.getError(errorKeyManual));
			}
		}else if(value == 3){
			if($burdenCountOne.size() && !burden_commission){
				var burdenCount = $burdenCountOne.val();
				if(Number(burdenCount) == 1){
					this.setError(errorKeyBurdenCount,"全額負担の場合は上記にある「加盟店全額負担」にチェックを入力します。");
					this.setErrorMessage("#"+errorKeyBurdenCount,this.getError(errorKeyBurdenCount));
				}else{
					this.setHiddenInput("#"+errorKey,"ctsp_int_burden_commission_number",burdenCount);
				}
			}
			this.setHiddenInput("#"+errorKey,"ctsp_tint_payment_count",1);

		}else if(value == 4){
			if($burdenCountTwo.size() && !burden_commission){
				var burdenCount = $burdenCountTwo.val();
				if(Number(burdenCount) == 2){
					this.setError(errorKeyBurdenCount,"全額負担の場合は上記にある「加盟店全額負担」にチェックを入力します。");
					this.setErrorMessage("#"+errorKeyBurdenCount,this.getError(errorKeyBurdenCount));
				}else{
					this.setHiddenInput("#"+errorKey,"ctsp_int_burden_commission_number",burdenCount);
				}
			}
			this.setHiddenInput("#"+errorKey,"ctsp_tint_payment_count",2);
		}
		//ctsp_int_burden_commission_number
	},

	checkPayBonus:function(){
		// エラーのキー
		var errorKey1 = "bonusPayMonthArea";
		var errorKey2 = "bonusPayMoneyArea";

		// まず削除
		this.delError(errorKey1);
		this.delError(errorKey2);

		var value = this.pay_type;
		if(value == 2){
			var summer = this.$("#pay_bonus_summer_month").val();
			var winter = this.$("#pay_bonus_winter_month").val();
			if(summer && winter){

			}else{
				this.setError(errorKey1,"ボーナス月は必ず設定してください。");
				this.setErrorMessage("#"+errorKey1,this.getError(errorKey1));
			}

			var val = this.$("#bonusPayMoneyInput").val();
			val = changeNumFomatInt(val);
			if(val){
				if(val % 1000 != 0){
					this.setError(errorKey2,"ボーナス加算金は必ず千円単位で設定してください。");
					this.setErrorMessage("#"+errorKey2,this.getError(errorKey2));
					this.delHiddenInput("#"+errorKey2,"ctsp_int_payment_bunus_money");
				}else{
					this.setHiddenInput("#"+errorKey2,"ctsp_int_payment_bunus_money",val);
				}

			}else{
				this.setError(errorKey2,"ボーナス加算金は必ず設定してください。");
				this.setErrorMessage("#"+errorKey2,this.getError(errorKey2));
				this.delHiddenInput("#"+errorKey2,"ctsp_int_payment_bunus_money");
			}

		}
	},
	checkPayOne:function(){
		// エラーのキー
		var errorKey = "onePaySelectArea";

		var value = this.pay_type;
		if(value == 3){
			var select = this.$("#onePaySelect1").val();
			this.delHiddenInput("#"+errorKey,"ctsp_dt_payment_turn1");
			this.setHiddenInput("#"+errorKey,"ctsp_dt_payment_turn1",select);
		}
	},
	checkPayTwo:function(){
		// エラーのキー
		var errorKey1 = "twoPaySelect1Area";
		var errorKey2 = "twoPaySelect2Area";
		var errorKey3 = "twoPayInput1Area";

		// まず削除
		this.delError(errorKey1);
		this.delError(errorKey2);
		this.delError(errorKey3);

		this.delHiddenInput("#"+errorKey1,"ctsp_dt_payment_turn1");
		this.delHiddenInput("#"+errorKey2,"ctsp_dt_payment_turn2");
		this.delHiddenInput("#"+errorKey3,"ctsp_int_payment_turn1_money");
		this.delHiddenInput("#"+errorKey3,"ctsp_int_payment_turn2_money");

		var value = this.pay_type;
		if(value == 4){
			var select1 = this.$("#twoPaySelect1").val();
			var select2 = this.$("#twoPaySelect2").val();

			var val = this.$("#twoPayInput1AreaInput").val();
			var val2 = this.$("#twoPayInput2AreaInput").val();

			val = changeNumFomatInt(val);
			val2 = changeNumFomatInt(val2);

			if(moment(select1.replace(/-/g, "/")).isBefore(select2.replace(/-/g, "/"))){
				this.setHiddenInput("#"+errorKey1,"ctsp_dt_payment_turn1",select1);
				this.setHiddenInput("#"+errorKey2,"ctsp_dt_payment_turn2",select2);
			}else{
				this.setError(errorKey2,"正常な年月を選択してください。");
				this.setErrorMessage("#"+errorKey2,this.getError(errorKey2));
			}

			if(val){
				this.setHiddenInput("#"+errorKey3,"ctsp_int_payment_turn1_money",val);
				this.setHiddenInput("#"+errorKey3,"ctsp_int_payment_turn2_money",val2);
			}else{
				this.setError(errorKey3,"1回目の支払金額は必ずご入力ください。");
				this.setErrorMessage("#"+errorKey3,this.getError(errorKey3));
				this.delHiddenInput("#"+errorKey3,"ctsp_int_payment_turn1_money");
				this.delHiddenInput("#"+errorKey3,"ctsp_int_payment_turn2_money");
			}
		}

	},

	// ファイルのチェック（売買契約書等）
	checkFiles:function(){
		// エラーのキー
		var errorKey = "attach_file";

		// まず削除
		this.delError(errorKey);

		if(this.$("#attach_file").size()){
			var date = this.$("#attach_file .image-uploader .id_data");

			if(date.size()){

			}else{
				this.setError(errorKey,"売買契約書等は、必ず1枚はアップロードしてください。");
				this.setErrorMessage("#"+errorKey,this.getError(errorKey));
			}
		}

		if(this.$("#attach_file").find(".waitMe").size()){
			this.setError(errorKey,"アップロード中です。アップロードが完了するまで次に進めません。");
			this.setErrorMessage("#"+errorKey,this.getError(errorKey));
		}
	},

	// スタッフの名前が入っているかどうか
	checkContractStaff:function(){
		// エラーのキー
		var errorKey = "contractStaffIdArea";

		// まず削除
		this.delError(errorKey);

		// var staff = this.$("#"+errorKey).find("#staff_select").val();
		// if(staff){
			var val1 = this.$("#"+errorKey).find(".name1").val();
			var val2 = this.$("#"+errorKey).find(".name2").val();
			if(val1 && val2){

			}else{
				this.setError(errorKey,"ご担当者様の氏名を必ず入力してください。");
				this.setErrorMessage("#"+errorKey,this.getError(errorKey));
			}
		// }else{
		// 	this.setError(errorKey,"ご担当者様は必ず選択してください。");
		// 	this.setErrorMessage("#"+errorKey,this.getError(errorKey));
		// }
	},

	// メールアドレス名前
	checkMailAddressName:function(){
		// エラーのキー
		var errorKey = "mailAddressNameArea";

		// まず削除
		this.delError(errorKey);

		var val1 = this.$("#mailAddressNameArea .name1").val();
		var val2 = this.$("#mailAddressNameArea .name2").val();
		if(val1 && val2){
			
		}else{
			this.setError(errorKey,"お客様のお名前は必ず入力してください");
			this.setErrorMessage("#"+errorKey,this.getError(errorKey));
		}
	},

	// メールアドレス
	checkMailAddress:function(){
		// エラーのキー
		var errorKey = "mailAddressArea";

		// まず削除
		this.delError(errorKey);

		var $mail = this.$('#mail_address_input');

		var $sel = this.$('[name="mail_address_select_domain"]');
		var account = this.$('[name="mail_address_input_account"]').val();
		var domain = ($sel.val() == 'other') ? this.$('[name="mail_address_input_domain"]').val() : $sel.find('option:selected').text();

		// 小文字に変換してアドレス渡す
		if (account) {
			var address = account.toLowerCase() + '@' + domain.toLowerCase();
			$mail.val(address);

			if (!account) {
				this.setError(errorKey,"メールアドレスの「@」マークより前の部分は必ず入力してください。");
				this.setErrorMessage("#"+errorKey,this.getError(errorKey));

			} else if (($sel.val() == 'other') && !domain) {
				//「その他」でドメイン未入力の場合
				this.setError(errorKey,"その他の場合は、必ずその他入力欄にメールアドレスの「@」マークより後ろの部分を入力してください。");
				this.setErrorMessage("#"+errorKey,this.getError(errorKey));

			} else if (!Validator.isMail(address)) {
				this.setError(errorKey,"メールアドレスが正しくない可能性があります。");
				this.setErrorMessage("#"+errorKey,this.getError(errorKey));
			}
		}
	},

	// checkSimlationError:function(){
	// 	// エラーのキー
	// 	var errorKey = "simulationArea";

	// 	if(this.is_simlation_error){
	// 		this.setError(errorKey,"シミュレーションにエラーがあります。");
	// 		this.$("#"+errorKey+" .error_message").show();
	// 	}else{
	// 		this.delError(errorKey);
	// 	}
	// },

	checkSimlationError:function(){
		// エラーのキー
		var errorKey = "simulationArea";

		if(this.is_simlation_error){
			this.setError(errorKey,"シミュレーションにエラーがあります。");
			this.$("#"+errorKey+" .error_message").show();
			//this.setErrorMessage("#"+errorKey,this.getError(errorKey));
		}else{
			if(this.load_loan_calc){
				this.setError(errorKey,"シミュレーションが正常に終わっておりません。");
				this.$("#"+errorKey+" .error_message").show();
				this.setErrorMessage("#"+errorKey,this.getError(errorKey));
			}else{
				this.delError(errorKey);
			}
		}
	},

	
/*=====  End of Checker  ======*/


	
/*===============================
=            Program            =
===============================*/
	funcServiceStyle:function(){
		
	},

	// 引渡し時期を使うかどうかを決定する
	funcCheckUseDeliveryTime:function(){
		//var articleCheck = this.$("#sales_style-2").prop("checked");
		var articleCheck = this.hasBuppin();
		if(this.$("#is_attend_item-1").prop("type")=="radio"){
			var coItem = this.$("#is_attend_item-1").prop("checked");
		}else{
			var coItem = this.$(".is_attend_item_hidden").val() == 1 ? 1 : 0;
		}

		if(articleCheck || coItem){
			return true;
		}else{
			return false;
		}
	},

	// トータルと頭金の計算
	funcReloadDepositMoney:function(){

		var self = this;
		var $items = this.$("#sale_items_area").find(".items");
		var $discountPrice = this.$("#discount_item_price");
		var $totalItemPrice = this.$("#total_item_price");
		var $totalItemPrice2 = this.$("#total_item_price2");
		var total = 0;
		$items.each(function(){
			var $this = $(this);
			var category = $this.data("category");
			var price = $this.find(".item_price input").val();
			var amount = $this.find(".item_amount input").val();

			price = price.replace(/[Ａ-Ｚａ-ｚ０-９]/g, function(s) {
				return String.fromCharCode(s.charCodeAt(0) - 65248);
			});

			amount = amount.replace(/[Ａ-Ｚａ-ｚ０-９]/g, function(s) {
				return String.fromCharCode(s.charCodeAt(0) - 65248);
			});

			price = changeNumFomatInt(price);

			if(price && amount){
				//total += price*amount;
				total += price;
			}
		});

		var discount = changeNumFomatInt($discountPrice.val());

		total = total - Math.abs(discount);

		$totalItemPrice.val(changeNumFomatMoney(total));
		$totalItemPrice2.val(changeNumFomatMoney(total));

		var val = this.$("#loan_deposit").val();
		var total = $totalItemPrice2.val();

        val = val.replace(/[Ａ-Ｚａ-ｚ０-９]/g, function(s) {
            return String.fromCharCode(s.charCodeAt(0) - 65248);
		});
		val = changeNumFomatInt(val);
		
        total = total.replace(/[Ａ-Ｚａ-ｚ０-９]/g, function(s) {
            return String.fromCharCode(s.charCodeAt(0) - 65248);
		});
		total = changeNumFomatInt(total);
		
		var ans = total-val;
		if(_.isNaN(ans)){
			ans = "";
		}
		this.$("#loan_total").val(changeNumFomatMoney(ans));
	},

	// 申込予定情報から計算一括の時、2回払いの時のoptionを計算して作る。
	funcSetPayMonthCol:function(){
		var year = this.$("#create_apply_year").val();
		var month = this.$("#create_apply_month").val();
		var day = this.$("#create_apply_day").val();

		if(!year || !month || !day){
			// 何もしない。
			return false;
		}

		var cmoment = moment(year+"/"+month+"/"+day);
		var secondPayMoment = moment(year+"/"+month+"/"+'01');

		if(addictionCoolingOff){
			//cmoment.add(7,"d");
			cmoment.add(coolingOffTerm-1,"d"); // クーリングオフ デフォルト利用
			year = cmoment.year();
			month = cmoment.month()+1;
		}

		if(isTablet){

			var today = moment();

			var notTodayContract = false;

			var diff1 = Number(cmoment.format("YYYYMMDD"));
			var diff2 = Number(today.format("YYYYMMDD"));
			if(diff1 >= diff2){
				// 今日までは、cmoment（申込年月日で日付計算）
			}else{
				// 修正日優先
				cmoment = today;
				year = cmoment.year();
				month = cmoment.month()+1;

				// 昨日以前のお申込み
				notTodayContract = true;
			}

			// 初回支払のチェック。初回支払い設定を超えていると、月を跨ぐように設定
			var amoment = cmoment;
			if(!_.has(nextMonthPayParam,Number(amoment.format("YYYYMM")))){
				return false;
			}
			var checkdate = _.clone(nextMonthPayParam[cmoment.format("YYYYMM")]);
			checkdate.date = _.str_replace(checkdate.date,"-","/");
			var isNextMonthPay = false;
			if(addictionCoolingOff){
				if(amoment.isAfter(checkdate.date)){
					isNextMonthPay = true;
				}
			}else{

				// 20171212 タテモト修正
				if(isAfAcst && notTodayContract){ // 訂正の時は一日ズラす
					checkdate.date = moment(checkdate.date).add(1,"d").format("YYYY-MM-DD");
				}

				if(amoment.isSameOrAfter(checkdate.date)){
					isNextMonthPay = true;
				}
			}

			// 次月に変更する
			if(isNextMonthPay){
				amoment.add(1,"M");
				year = amoment.year();
				month = amoment.month()+1;
			}
		}

		var $select = null;
		var nowVal = "";
		var html = "";

		// 一括払い
		$select = this.$("#onePaySelect1");
		nowVal = $select.val();
		$select.empty();
		html = "";

		_.times(isNextMonthPay ? 6 : 7,function(n){
			var d = moment(year+"/"+month+"/"+1).add(n+1, 'months');
			var val = d.format("YYYY-MM-DD");
			var name = d.format("YYYY 年 MM 月");
			var selected = "";
			if(val==nowVal){
				selected = " selected";
			}
			var h = "<option value='"+val+"'"+selected+">"+name+"</option>";
			html += h;
		},this);


		$select.html(html);

		// 2回払い 1回目
		$select = this.$("#twoPaySelect1");
		nowVal = $select.val();
		$select.empty();
		html = "";

		_.times(isNextMonthPay ? 5 : 6,function(n){
			var d = moment(year+"/"+month+"/"+1).add(n+1, 'months');
			var val = d.format("YYYY-MM-DD");
			var name = d.format("YYYY 年 MM 月");
			var selected = "";
			if(val==nowVal){
				selected = " selected";
			}
			var h = "<option value='"+val+"'"+selected+">"+name+"</option>";
			html += h;
		},this);

		$select.html(html);

		// 2回払い 2回目
		$select = this.$("#twoPaySelect1");
		firstVal = $select.val();
		$select = this.$("#twoPaySelect2");
		nowVal = $select.val();
		$select.empty();
		html = "";

		var firstPayMoment = moment(firstVal);
		var diff = secondPayMoment.add(12,'months').diff(firstPayMoment,'months');

		_.times(isNextMonthPay ? diff : diff,function(n){
				var d = firstPayMoment.add(1, 'months');
			var val = d.format("YYYY-MM-DD");
			var name = d.format("YYYY 年 MM 月");
			var selected = "";
			if(val==nowVal){
				selected = " selected";
			}
			var h = "<option value='"+val+"'"+selected+">"+name+"</option>";
			html += h;
		},this);

		$select.html(html);

		this.funcLoanSimlation();
	},

	// addon月利を取得
	funcGetAddon:function(money,count){
		// 手数料全額負担かどうか
		var val = 0;
		if(this.$("#burden_commission").prop("type") == "checkbox"){
			val = this.$("#burden_commission").prop("checked");
		}else{
			val = Number(this.$("#burden_commission").val());
		}
		if(val){
			addon = 0;
		}else{
			var list = _.filter(AddonList,function(v){
				if(money >= v.addon_int_money){
					return true;
				}
				if(v.addon_int_money==0){
					return true;
				}
				return false;
			});
			addon = _.first(list).addon_dic_addon;

		}

		if(this.pay_type == 3 || this.pay_type == 4){
			if(!can_bonus_burden){
				addon = 0;
			}
		}
		return addon;
	},

	// addon月利を取得
	funcGetShopAddon:function(money,count){
		// 手数料全額負担かどうか
		if(0 > money){
			return 0;
		}
		var val = 0;
		if(this.$("#burden_commission").prop("type") == "checkbox"){
			val = this.$("#burden_commission").prop("checked");
		}else{
			val = Number(this.$("#burden_commission").val());
		}
		if(val){
			addon = 0;
		}else{
			var list = _.filter(AddonList,function(v){
				return money >= v.addon_int_money;
			});
			addon = _.first(list).addon_dic_shop_addon;
		}

		return addon;
	},

	// ２回払の最初の支払額を整える
	funcSetTwoPayFirstMoney:function(){
		if(this.pay_type == 4){
			var loan_total = changeNumFomatInt($('#loan_total').val());
			if(loan_total){
				var firstMoney = Math.floor(loan_total / 2);
				var secondMoney = Math.ceil(loan_total / 2);
				var remainingMoney = secondMoney % 1000;
				firstMoney = firstMoney + remainingMoney;
				$('#twoPayInput1AreaInput').val(changeNumFomatMoney(firstMoney));
			}
		}
	},

	// ローンシミュレーションを動かす
	funcLoanSimlation:function(){

		// お申込み金額上限制御
		var checkLoanApplyMoney = this.checkLoanApplyMoney();
		if(!checkLoanApplyMoney){
			return false;
		}

		// 2回払いの場合、先に計算を更新する
		if(this.pay_type == 4){
			this.onKeyUpTwoPayMoney();
			this.funcSetTwoPayFirstMoney();
		}

		var cyear = this.$("#create_apply_year").val();
		var cmonth = this.$("#create_apply_month").val();
		var cday = this.$("#create_apply_day").val();

		if(!cyear || !cmonth || !cday){
			return false;
		}

		var cmoment = moment(cyear+"/"+cmonth+"/"+cday);

		// 一括、二回は、こちらをBaseに作る
		var oneTwoPayCmoment = moment(cyear+"/"+cmonth+"/"+cday);

		if(!isTablet){
			oneTwoPayCmoment = contract_created_at;
		}

		// クーリングオフの制御
		if(addictionCoolingOff){
			//cmoment.add(7,"d");
			cmoment.add(coolingOffTerm-1,"d");  // クーリングオフ デフォルト利用
		}

		if(isTablet){

			var today = moment();

			var notTodayContract = false;

			var diff1 = Number(cmoment.format("YYYYMMDD"));
			var diff2 = Number(today.format("YYYYMMDD"));
			if(diff1 >= diff2){
				// 今日までは、cmoment（申込年月日で日付計算）
			}else{
				// 修正日優先
				cmoment = today;

				// 昨日以前のお申込み
				notTodayContract = true;
			}

			// 初回支払のチェック。初回支払い設定を超えていると、月を跨ぐように設定
			if(!_.has(nextMonthPayParam,Number(cmoment.format("YYYYMM")))){
				return false;
			}
			var checkdate = _.clone(nextMonthPayParam[cmoment.format("YYYYMM")]);
			checkdate.date = _.str_replace(checkdate.date,"-","/");
			var isNextMonthPay = false;
			if(addictionCoolingOff){
				if(cmoment.isAfter(checkdate.date)){
					isNextMonthPay = true;
				}
			}else{

				// 20171212 タテモト修正
				if(isAfAcst && notTodayContract){ // 訂正の時は一日ズラす
					checkdate.date = moment(checkdate.date).add(1,"d").format("YYYY-MM-DD");
				}

				if(cmoment.isSameOrAfter(checkdate.date)){
					isNextMonthPay = true;
				}
			}
			// 次月に変更する
			if(isNextMonthPay){
				cmoment.add(1,"M");
			}
		}
		


		// 支払い月
		var nextTwoMonth = cmoment.add(1,"M");
		var year = nextTwoMonth.year();
		var month = nextTwoMonth.month()+1;

		// 来月の支払い
		var nextTwoNextOneMonth = nextTwoMonth.clone().add(1,"M"); // 来月支払い
		var year_nextOne = nextTwoNextOneMonth.year();
		var month_nextOne = nextTwoNextOneMonth.month()+1;

		// 支払い回数
		var count = 0;
		var burdenCommissionCount = 0;

		// 加盟店負担、全負担かどうか
		var isAllBurdenCommission = 0;
		if(this.$("#burden_commission").prop("type") == "checkbox"){
			isAllBurdenCommission = this.$("#burden_commission").prop("checked");
		}else{
			isAllBurdenCommission = Number(this.$("#burden_commission").val());
		}

		// 支払い方法によって動作変える
		if(this.pay_type==1 || this.pay_type==2){
			count = Number(this.$("#payment_manual_count").val());
			if(!count){
				return false;
			}
			
			if(isAllBurdenCommission){
				burdenCommissionCount = count;
			}else{
				if($("#paymentBurdenCommissionNumSelect").size()){
					burdenCommissionCount = $("#paymentBurdenCommissionNumSelect").val();
				}else{
					// 非表示の時は何もしない
				}
			}

		}else if(this.pay_type==3){
			count = 1;

			if(isAllBurdenCommission){
				burdenCommissionCount = count;
			}else{
				if($("#paymentBurdenCommissionNumSelectOneType").size()){
					burdenCommissionCount = $("#paymentBurdenCommissionNumSelectOneType").val();
				}else{
					// 非表示の時は何もしない
				}
			}

		}else if(this.pay_type==4){
			count = 2;

			if(isAllBurdenCommission){
				burdenCommissionCount = count;
			}else{
				if($("#paymentBurdenCommissionNumSelect").size()){
					burdenCommissionCount = $("#paymentBurdenCommissionNumSelectTwoType").val();
				}else{
					// 非表示の時は何もしない
				}
			}

		}

		// if(burdenCommissionCount > count){
		// 	return false;
		// }

		// 手数料全額負担かどうか
		var total  = changeNumFomatInt(this.$("#total_item_price2").val());
        var down   = changeNumFomatInt(this.$("#loan_deposit").val());
		var addon  = this.funcGetAddon(total-down,count);
		var shop_addon  = this.funcGetShopAddon(total-down,count);

		// 一括、または2回払いの時利用
		var first_date = null;
		var first_date_next = null;
		var second_date = null;
		var second_date_next = null;
		if(this.pay_type==3){
			first_date = this.$("#onePaySelect1").val();

			// 来月チェック --------
			first_date_next = this.$("#onePaySelect1").val();
			var _check = Number(moment(first_date).format("YYYYMM"));
			if(_check < Number(nextTwoNextOneMonth.format("YYYYMM"))){
				first_date_next = nextTwoNextOneMonth.format("YYYY-MM-01");
			}
			// -----------------
			

		}else if(this.pay_type==4){
			first_date = this.$("#twoPaySelect1").val();
			second_date = this.$("#twoPaySelect2").val();

			// 来月チェック --------
			first_date_next = this.$("#twoPaySelect1").val();
			var _check = Number(moment(first_date).format("YYYYMM"));
			if(_check < Number(nextTwoNextOneMonth.format("YYYYMM"))){
				first_date_next = nextTwoNextOneMonth.format("YYYY-MM-01");
			}
			second_date_next = this.$("#twoPaySelect2").val();
			if(first_date_next == second_date_next){
				second_date_next = nextTwoNextOneMonth.add(1,"M").format("YYYY-MM-01");
			}
			// -----------------
		}

		// 一括、または2回払いの時利用
		var first_pay = null;
		if(this.pay_type==3){
		}else if(this.pay_type==4){
			first_pay = this.$("#twoPayInput1AreaInput").val();
		}

        var firstyear      = year;
        var firstmonth     = month;
        var numpay         = count;
        var bonuspay       = this.pay_type==2 ? changeNumFomatInt(this.$("#bonusPayMoneyInput").val()) : null;
        var bonus1_m       = this.pay_type==2 ? this.$("#pay_bonus_summer_month").val() : null;
        var bonus2_m       = this.pay_type==2 ? this.$("#pay_bonus_winter_month").val() : null;
        var saving_depo    = null; // 預貯金額はnull

        var shop_pay_num   = burdenCommissionCount;
        var base_date      = firstyear+"-"+firstmonth;
        var first_date     = first_date;
        var first_pay      = first_pay;
        var second_date    = second_date;

        if(this.pay_type==3 || this.pay_type==4){
        	base_date = oneTwoPayCmoment.format("YYYY-MM");
        }

        var post = {
        	"total"			: total,
        	"down"			: down,
        	"firstyear"		: firstyear,
        	"firstmonth"	: firstmonth,
        	"numpay"		: numpay,
        	"bonuspay"		: bonuspay,
        	"bonus1_m"		: bonus1_m,
        	"bonus2_m"		: bonus2_m,
        	"saving_depo"	: saving_depo,
        	"shop_pay_num"	: shop_pay_num,
        	"base_date"		: base_date,
        	"first_date"	: first_date,
        	"first_pay"		: first_pay,
        	"second_date"	: second_date,
        	"addon"			: addon,
        	"shop_addon"	: shop_addon,
        };

        var firstyear_next      = year_nextOne;
        var firstmonth_next     = month_nextOne;

        var shop_pay_num   = burdenCommissionCount;
        var base_date      = firstyear_next+"-"+firstmonth_next;
        var first_date     = first_date_next;
        var first_pay      = first_pay;
        var second_date    = second_date_next;

        if(this.pay_type==3 || this.pay_type==4){
        	base_date = oneTwoPayCmoment.format("YYYY-MM");
        }

        var post2 = {
        	"total"			: total,
        	"down"			: down,
        	"firstyear"		: firstyear_next,
        	"firstmonth"	: firstmonth_next,
        	"numpay"		: numpay,
        	"bonuspay"		: bonuspay,
        	"bonus1_m"		: bonus1_m,
        	"bonus2_m"		: bonus2_m,
        	"saving_depo"	: saving_depo,
        	"shop_pay_num"	: shop_pay_num,
        	"base_date"		: base_date,
        	"first_date"	: first_date,
        	"first_pay"		: first_pay,
        	"second_date"	: second_date,
        	"addon"			: addon,
        	"shop_addon"	: shop_addon,
        };

        // ボーナス 万単位加算は通さない
        var bonuspass = true;
        if(this.pay_type==2){
        	if(bonuspay % 1000 != 0 || bonuspay==0){
        		//bonuspass = false;
        	}
        }

        // ajaxで計算を走らす
        if(total && bonuspass && (!this.is_calculation)){
        	this.is_calculation = true;
        	this.load_loan_calc = true;
        	this.$("#simulationArea").waitMe({effect : 'progressBar',color : "#298470"});
        	clearTimeout(this.simTimeoutSet);
        	var simSelf = this;
        	this.simTimeoutSet = setTimeout(function(){
        		clearTimeout(simSelf.simTimeoutSet);
        		if(simSelf.xhrs[__AJAX_LOAN_CALC_URL]){
        			simSelf.xhrs[__AJAX_LOAN_CALC_URL].abort();
        		}
        		simSelf.$("#simulationArea").waitMe("hide");
        		simSelf.is_calculation = false;
        		simSelf.load_loan_calc = true;

        		simSelf.is_simlation_error = true;
				simSelf.$("#simulationArea .simlation_error").show();

				simSelf.$("#simulationArea .simlation_error p").html("シミュレーションがタイムアウトしました。再度シミュレーションを行ってください。");
        	},this.simTimeout);
        	this.ajax(__AJAX_LOAN_CALC_URL,post,function(res){
        		// clearTimeout(simSelf.simTimeoutSet);
        		// this.$("#simulationArea").waitMe("hide");
        		// this.load_loan_calc = false;
        		// this.funcSetLoanMoney(res);

        		// 来月承認処理
        		this.ajax(__AJAX_LOAN_CALC_URL,post2,function(res2){
        			clearTimeout(simSelf.simTimeoutSet);
	        		this.$("#simulationArea").waitMe("hide");
	        		this.load_loan_calc = false;
	        		this.funcSetLoanMoney(res,res2);
        		},function(){
        			clearTimeout(simSelf.simTimeoutSet);
        			this.$("#simulationArea").waitMe("hide");
        			this.is_calculation = false;
        			this.load_loan_calc = true;
        		});
        		// -----------------
        	},function(){
        		clearTimeout(simSelf.simTimeoutSet);
        		this.$("#simulationArea").waitMe("hide");
        		this.is_calculation = false;
        		this.load_loan_calc = true;
        	});
        }else{
        	this.load_loan_calc = true;
        	this.do_next_calculation = true;
        }

	},

	// 結果を反映
	funcSetLoanMoney:function(result,result2){

		this.$("#I_Total").val(result["I_Total"]);
		this.$("#I_Down").val(result["I_Down"]);
		this.$("#O_Remaining").val(result["O_Remaining"]);
		this.$("#O_InstallmentFee").val(result["O_InstallmentFee"]);
		this.$("#O_InstallmentsTotal").val(result["O_InstallmentsTotal"]);
		this.$("#O_TotalPay1").val(result["O_TotalPay1"]);

		var initialDay = moment(result["I_InitialPayDay"].replace(/-/g, "/")).format("YYYY年MM月27日");
		var finalDay = "";
		if(result["O_FinalPayDay"]){
			finalDay = moment(result["O_FinalPayDay"].replace(/-/g, "/")).format("YYYY年MM月27日");
		}

		if(this.pay_type==1){
			this.$("#O_MANUAL_FirstPay").text(result["O_FirstPay"] + "円 × " + result["O_FirstPayNum"] + "回");
			this.$("#O_MANUAL_SecondPay").text(result["O_SecondPay"] + "円 × " + result["O_SecondPayNum"] + "回");
			this.$("#I_MANUAL_InitialPayDay").html("初回支払月 " + initialDay + "<br>" + "最終支払月 " + finalDay);
		}

		if(this.pay_type==2){

			// ボーナス加算月を入れる
			var month = [];
			_.each(result["O_BonusMonth"],function(v){
				var m = moment(v.replace(/-/g, "/"));
				month.push(m.format("YYYY年MM月"));
			});

			this.$("#O_BONUS_FirstPay").text(result["O_FirstPay"] + "円 × " + result["O_FirstPayNum"] + "回");
			this.$("#O_BONUS_SecondPay").text(result["O_SecondPay"] + "円 × " + result["O_SecondPayNum"] + "回");
			this.$("#I_BONUS_Pay").text(result["I_BonusPay"] + "円 × " + result["O_BonusNumberPay"] + "回");
			this.$("#O_BonusMonth").html(month.join("<br>"));
			this.$("#I_BONUS_InitialPayDay").html("初回支払月 " + initialDay + "<br>" + "最終支払月 " + finalDay);
		}

		if(this.pay_type==3){
			this.$("#O_ONEPAY_FirstPay").text(result["O_FirstPay"] + "円");
			this.$("#O_ONEPAY_FinalPayDay").text(finalDay);
		}

		if(this.pay_type==4){
			if(result["I_FirstDate"]){
				var initialDayTwoPay = moment(result["I_FirstDate"].replace(/-/g, "/")).format("YYYY年MM月27日");
				
				this.$("#O_TWOPAY_FirstPay").text(result["O_FirstPay"] + "円");
				this.$("#O_TWOPAY_FirstPayDay").text(initialDayTwoPay);

				this.$("#O_TWOPAY_SecondPay").text(result["O_SecondPay"] + "円");
				this.$("#O_TWOPAY_FinalPayDay").text(finalDay);
			}
		}

		// ------------------------------------------------------
		// ------- 来月の処理（値追加） -------------------------------
		// ------------------------------------------------------

		var isChangeNextMonth = false;

		if(result["I_Total"] != result2["I_Total"]){
			this.$("#I_Total_2").val(result2["I_Total"]);
			isChangeNextMonth = true;
		}else{
			this.$("#I_Total_2").val("");
		}

		if(result["I_Down"] != result2["I_Down"]){
			this.$("#I_Down_2").val(result2["I_Down"]);
			isChangeNextMonth = true;
		}else{
			this.$("#I_Down_2").val("");
		}

		if(result["O_Remaining"] != result2["O_Remaining"]){
			this.$("#O_Remaining_2").val(result2["O_Remaining"]);
			isChangeNextMonth = true;
		}else{
			this.$("#O_Remaining_2").val("");
		}

		if(result["O_InstallmentFee"] != result2["O_InstallmentFee"]){
			this.$("#O_InstallmentFee_2").val(result2["O_InstallmentFee"]);
			isChangeNextMonth = true;
		}else{
			this.$("#O_InstallmentFee_2").val("");
		}

		if(result["O_InstallmentsTotal"] != result2["O_InstallmentsTotal"]){
			this.$("#O_InstallmentsTotal_2").val(result2["O_InstallmentsTotal"]);
			isChangeNextMonth = true;
		}else{
			this.$("#O_InstallmentsTotal_2").val("");
		}

		if(result["O_TotalPay1"] != result2["O_TotalPay1"]){
			this.$("#O_TotalPay1_2").val(result2["O_TotalPay1"]);
			isChangeNextMonth = true;
		}else{
			this.$("#O_TotalPay1_2").val("");
		}
		

		var initialDay2 = moment(result2["I_InitialPayDay"].replace(/-/g, "/")).format("YYYY年MM月27日");
		var finalDay2 = "";
		if(result2["O_FinalPayDay"]){
			finalDay2 = moment(result2["O_FinalPayDay"].replace(/-/g, "/")).format("YYYY年MM月27日");
		}

		if(this.pay_type==1){
			if(result["O_FirstPay"] != result2["O_FirstPay"]){
				this.$("#O_MANUAL_FirstPay_2").text(result2["O_FirstPay"] + "円 × " + result2["O_FirstPayNum"] + "回");
				isChangeNextMonth = true;
			}else{
				this.$("#O_MANUAL_FirstPay_2").text("");
			}

			if(result["O_SecondPay"] != result2["O_SecondPay"]){
				this.$("#O_MANUAL_SecondPay_2").text(result2["O_SecondPay"] + "円 × " + result2["O_SecondPayNum"] + "回");
				isChangeNextMonth = true;
			}else{
				this.$("#O_MANUAL_SecondPay_2").text("");
			}

			//if(initialDay != initialDay2 || finalDay != finalDay2){
			if(isChangeNextMonth){
				this.$("#I_MANUAL_InitialPayDay_2").html("初回支払月 " + initialDay2 + "<br>" + "最終支払月 " + finalDay2);
			}else{
				this.$("#I_MANUAL_InitialPayDay_2").html("");
			}
		}

		if(this.pay_type==2){

			// ボーナス加算月を入れる
			var month2 = [];
			_.each(result2["O_BonusMonth"],function(v){
				var m = moment(v.replace(/-/g, "/"));
				month2.push(m.format("YYYY年MM月"));
			});

			if(result["O_FirstPay"] != result2["O_FirstPay"]){
				this.$("#O_BONUS_FirstPay_2").text(result2["O_FirstPay"] + "円 × " + result2["O_FirstPayNum"] + "回");
				isChangeNextMonth = true;
			}else{
				this.$("#O_BONUS_FirstPay_2").text("");
			}

			if(result["O_SecondPay"] != result2["O_SecondPay"]){
				this.$("#O_BONUS_SecondPay_2").text(result2["O_SecondPay"] + "円 × " + result2["O_SecondPayNum"] + "回");
				isChangeNextMonth = true;
			}else{
				this.$("#O_BONUS_SecondPay_2").text("");
			}

			if(result["O_BonusNumberPay"] != result2["O_BonusNumberPay"]){
				this.$("#I_BONUS_Pay_2").text(result2["I_BonusPay"] + "円 × " + result2["O_BonusNumberPay"] + "回");
				isChangeNextMonth = true;
			}else{
				this.$("#I_BONUS_Pay_2").text("");
			}

			if(result["O_BonusNumberPay"] != result2["O_BonusNumberPay"]){
				this.$("#I_BONUS_Pay_2").text(result2["I_BonusPay"] + "円 × " + result2["O_BonusNumberPay"] + "回");
				isChangeNextMonth = true;
			}else{
				this.$("#I_BONUS_Pay_2").text("");
			}

			if(month.join("<br>") != month2.join("<br>")){
				this.$("#O_BonusMonth_2").html(month2.join("<br>"));
				isChangeNextMonth = true;
			}else{
				this.$("#O_BonusMonth_2").html("");
			}

			//if(initialDay != initialDay2 || finalDay != finalDay2){
			if(isChangeNextMonth){
				this.$("#I_BONUS_InitialPayDay_2").html("初回支払月 " + initialDay2 + "<br>" + "最終支払月 " + finalDay2);
			}else{
				this.$("#I_BONUS_InitialPayDay_2").html("");
			}

		}

		if(this.pay_type==3){

			if(result["O_FirstPay"] != result2["O_FirstPay"]){
				this.$("#O_ONEPAY_FirstPay_2").text(result2["O_FirstPay"] + "円");
				isChangeNextMonth = true;
			}else{
				this.$("#O_ONEPAY_FirstPay_2").text("");
			}

			if(finalDay != finalDay2){
			//if(isChangeNextMonth){
				this.$("#O_ONEPAY_FinalPayDay_2").text(finalDay2);
			}else{
				this.$("#O_ONEPAY_FinalPayDay_2").text("");
			}
		}

		if(this.pay_type==4){
			if(result2["I_FirstDate"]){
				var initialDayTwoPay2 = moment(result2["I_FirstDate"].replace(/-/g, "/")).format("YYYY年MM月27日");
				
				if(result["O_FirstPay"] != result2["O_FirstPay"]){
					this.$("#O_TWOPAY_FirstPay_2").text(result2["O_FirstPay"] + "円");
					isChangeNextMonth = true;
				}else{
					this.$("#O_TWOPAY_FirstPay_2").text("");
				}


				if(result["O_SecondPay"] != result2["O_SecondPay"]){
					this.$("#O_TWOPAY_SecondPay_2").text(result2["O_SecondPay"] + "円");
					isChangeNextMonth = true;
				}else{
					this.$("#O_TWOPAY_SecondPay_2").text("");
				}

				if(initialDayTwoPay != initialDayTwoPay2){
				//if(isChangeNextMonth){
					this.$("#O_TWOPAY_FirstPayDay_2").text(initialDayTwoPay2);
				}else{
					this.$("#O_TWOPAY_FirstPayDay_2").text("");
				}

				if(finalDay != finalDay2){
				//if(isChangeNextMonth){
					this.$("#O_TWOPAY_FinalPayDay_2").text(finalDay2);
				}else{
					this.$("#O_TWOPAY_FinalPayDay_2").text("");
				}
			}
		}

		// ------------------------------------------------------
		// ------- 来月の処理（値追加完了） ---------------------------
		// ------------------------------------------------------

		// 完了
		this.is_calculation = false;

		// すぐに計算をしなければ行けない場合はここが走る
		if(this.do_next_calculation){
			this.do_next_calculation = false;
			this.funcLoanSimlation();
		}else{
			// 本来のシミュレーション
			var error = result["O_Message"].join("<br>");
			// ボーナス加算回数チェック用変数（0であれば、true）
			var bonusError = !(this.pay_type==2 ? result["O_BonusNumberPay"] : 1);
			if(error || bonusError){
				if(bonusError){
					error += error ? "<br>" : "";
					error += 'ご選択頂いたお支払回数ではボーナス加算月未到来となります。夏・冬いずれかのボーナス加算月を経過するお支払回数へ修正して下さい。';
				}
				this.is_simlation_error = true;
				this.$("#simulationArea .simlation_error").show();
				this.$("#simulationArea .simlation_error p").html(error);
			}else{
				this.is_simlation_error = false;
				this.$("#simulationArea .simlation_error").hide();
			}
			
		}
	},


	isBuppin:function(){
		var style1 = this.$("#sales_style-1").prop("checked");		// 特定継続的役務販売
		var style2 = this.$("#sales_style-2").prop("checked");		// 物販
		var style3 = this.$("#sales_style-3").prop("checked");		// 未使用
		var style4 = this.$("#sales_style-4").prop("checked");		// 指定メディカル
		var style5 = this.$("#sales_style-5").prop("checked");		// 未使用
		var style6 = this.$("#sales_style-6").prop("checked");		// 物販
		var style7 = this.$("#sales_style-7").prop("checked");		// 物販
		var style8 = this.$("#sales_style-8").prop("checked");		// 指定外メディカル
		var style9 = this.$("#sales_style-9").prop("checked");		// 物販
		var style10 = this.$("#sales_style-10").prop("checked");	// 物販
		var style11 = this.$("#sales_style-11").prop("checked");	// 役務提供（特定契約外）
		var style12 = this.$("#sales_style-12").prop("checked");	// 物販
		var style13 = this.$("#sales_style-13").prop("checked");	// 物販
		var style14 = this.$("#sales_style-14").prop("checked");	// 物販
		// var style15 = this.$("#sales_style-15").prop("checked");
		// var style16 = this.$("#sales_style-16").prop("checked");

		// 物販以外を選択しているかどうか
		if(!style1 && !style4 && !style8 && !style11){
			if(style2 || style6 || style7 || style9 || style10 || style12 || style13 || style14){
				return true;
			}
			return false;
		}else{
			return false;
		}
	},

	isSpecifixBuppin:function(){
		var style1 = this.$("#sales_style-1").prop("checked");		// 特定継続的役務販売
		var style2 = this.$("#sales_style-2").prop("checked");		// 物販
		var style3 = this.$("#sales_style-3").prop("checked");		// 未使用
		var style4 = this.$("#sales_style-4").prop("checked");		// 指定メディカル
		var style5 = this.$("#sales_style-5").prop("checked");		// 未使用
		var style6 = this.$("#sales_style-6").prop("checked");		// 物販
		var style7 = this.$("#sales_style-7").prop("checked");		// 物販
		var style8 = this.$("#sales_style-8").prop("checked");		// 指定外メディカル
		var style9 = this.$("#sales_style-9").prop("checked");		// 物販
		var style10 = this.$("#sales_style-10").prop("checked");	// 物販
		var style11 = this.$("#sales_style-11").prop("checked");	// 役務提供（特定契約外）
		var style12 = this.$("#sales_style-12").prop("checked");	// 物販
		var style13 = this.$("#sales_style-13").prop("checked");	// 物販
		var style14 = this.$("#sales_style-14").prop("checked");	// 物販
		// var style15 = this.$("#sales_style-15").prop("checked");
		// var style16 = this.$("#sales_style-16").prop("checked");

		// 物販以外を選択しているかどうか
		if(style2 || style10){
			if(!style1 && !style3 && !style4 && !style5 && !style6 && !style7 && !style8 && !style9 && !style11 && !style12 && !style13 && !style14){
				return true;
			}
			return false;
		}else{
			return false;
		}
	},

	hasBuppin:function(){
		var style1 = this.$("#sales_style-1").prop("checked");		// 特定継続的役務販売
		var style2 = this.$("#sales_style-2").prop("checked");		// 物販
		var style3 = this.$("#sales_style-3").prop("checked");		// 未使用
		var style4 = this.$("#sales_style-4").prop("checked");		// 指定メディカル
		var style5 = this.$("#sales_style-5").prop("checked");		// 未使用
		var style6 = this.$("#sales_style-6").prop("checked");		// 物販
		var style7 = this.$("#sales_style-7").prop("checked");		// 物販
		var style8 = this.$("#sales_style-8").prop("checked");		// 指定外メディカル
		var style9 = this.$("#sales_style-9").prop("checked");		// 物販
		var style10 = this.$("#sales_style-10").prop("checked");	// 物販
		var style11 = this.$("#sales_style-11").prop("checked");	// 役務提供（特定契約外）
		var style12 = this.$("#sales_style-12").prop("checked");	// 物販
		var style13 = this.$("#sales_style-13").prop("checked");	// 物販
		var style14 = this.$("#sales_style-14").prop("checked");	// 物販

		// 物品系を選択しているかどうか
		if(style2 || style6 || style7 || style9 || style10 || style12 || style13 || style14){
			return true;
		}
		return false;
	},

	// 指定役務以外の形態
	isNotSpecific:function(){
		var style1 = this.$("#sales_style-1").prop("checked");		// 特定継続的役務販売
		var style2 = this.$("#sales_style-2").prop("checked");		// 物販
		var style3 = this.$("#sales_style-3").prop("checked");		// 未使用
		var style4 = this.$("#sales_style-4").prop("checked");		// 指定メディカル
		var style5 = this.$("#sales_style-5").prop("checked");		// 未使用
		var style6 = this.$("#sales_style-6").prop("checked");		// 物販
		var style7 = this.$("#sales_style-7").prop("checked");		// 物販
		var style8 = this.$("#sales_style-8").prop("checked");		// 指定外メディカル
		var style9 = this.$("#sales_style-9").prop("checked");		// 物販
		var style10 = this.$("#sales_style-10").prop("checked");	// 物販
		var style11 = this.$("#sales_style-11").prop("checked");	// 役務提供（特定契約外）
		var style12 = this.$("#sales_style-12").prop("checked");	// 物販
		var style13 = this.$("#sales_style-13").prop("checked");	// 物販
		var style14 = this.$("#sales_style-14").prop("checked");	// 物販

		if(style4 || style11){
			if(!style1 && !style8){
				return true;
			}else{
				return false;
			}
		}else{
			return false;
		}
	},

	// 指定役務の形態
	isSpecific:function(){
		var style1 = this.$("#sales_style-1").prop("checked");		// 特定継続的役務販売
		var style2 = this.$("#sales_style-2").prop("checked");		// 物販
		var style3 = this.$("#sales_style-3").prop("checked");		// 未使用
		var style4 = this.$("#sales_style-4").prop("checked");		// 指定メディカル
		var style5 = this.$("#sales_style-5").prop("checked");		// 未使用
		var style6 = this.$("#sales_style-6").prop("checked");		// 物販
		var style7 = this.$("#sales_style-7").prop("checked");		// 物販
		var style8 = this.$("#sales_style-8").prop("checked");		// 指定外メディカル
		var style9 = this.$("#sales_style-9").prop("checked");		// 物販
		var style10 = this.$("#sales_style-10").prop("checked");	// 物販
		var style11 = this.$("#sales_style-11").prop("checked");	// 役務提供（特定契約外）
		var style12 = this.$("#sales_style-12").prop("checked");	// 物販
		var style13 = this.$("#sales_style-13").prop("checked");	// 物販
		var style14 = this.$("#sales_style-14").prop("checked");	// 物販

		if(style1 || style8){
			if(!style4 && !style11){
				return true;
			}else{
				return false;
			}
		}else{
			return false;
		}
	},

	getBitSalesStyle:function(){
		var bit = 0;
		bit += this.$("#sales_style-1").prop("checked") ? 1 : 0;			// 特定継続的役務販売
		bit += this.$("#sales_style-2").prop("checked") ? 2 : 0;			// 物品販売※特役業者使用
		bit += this.$("#sales_style-3").prop("checked") ? 4 : 0;			// 権利（未使用）
		bit += this.$("#sales_style-4").prop("checked") ? 8 : 0;			// メディカル（特役対象）
		bit += this.$("#sales_style-5").prop("checked") ? 16 : 0;			// その他（未使用）
		bit += this.$("#sales_style-6").prop("checked") ? 32 : 0;			// 訪問販売
		bit += this.$("#sales_style-7").prop("checked") ? 64 : 0;			// 連鎖販売
		bit += this.$("#sales_style-8").prop("checked") ? 128 : 0;			// メディカル
		bit += this.$("#sales_style-9").prop("checked") ? 256 : 0;			// 企業間取引
		bit += this.$("#sales_style-10").prop("checked") ? 512 : 0;			// 物品販売（特定契約外）
		bit += this.$("#sales_style-11").prop("checked") ? 1024 : 0;		// 役務提供（特定契約外）
		bit += this.$("#sales_style-12").prop("checked") ? 2048 : 0;		// 通信販売
		bit += this.$("#sales_style-13").prop("checked") ? 4096 : 0;		// 電話勧誘販売
		bit += this.$("#sales_style-14").prop("checked") ? 8192 : 0;		// 業務提供誘引販売

		return bit;
	},

/*=====  End of Program  ======*/

/*=============================
=            Event            =
=============================*/

	// 申込予定日を設定 役務の提供期間のスタートと連動させる。
	onChangeCreateApply:function(){

		var year = this.$("#create_apply_year").val();
		var month = this.$("#create_apply_month").val();
		var day = this.$("#create_apply_day").val();

		if(_.validDate(year,month,day)){
			if(!this.isBuppin()){
				this.$("#start_of_offer_year").val(year);
				this.$("#start_of_offer_month").val(month);
				this.$("#start_of_offer_day").val(day);
			}

			this.onChangeStartOffer();
			this.onChangeAttendItemDelivery();
			this.funcSetPayMonthCol();
			this.$("#createApplyDate .error_message").hide();
		}else{
			this.setErrorMessage("#createApplyDate","正しい日付をご入力ください");
		}
	},

	// 販売形態の状態から、フォームを切り替える
	onChangeService:function(){

		//販売形態の控印刷タイプによって選択できる販売形態を変更する
		var chkd1 = document.querySelector('[id*="sales_style-"]:checked');
		if (chkd1) {
			var $lbl = this.$(chkd1.nextElementSibling);
			var clses = $lbl.attr('class').split(' ');
			var cls = null;
			for (var i = clses.length; i--;) {
				if (-1 < clses[i].indexOf('stpl-')) {
					cls = clses[i];
					break;
				}
			}
			this.$('[for*="sales_style-"]:not(.' + cls + ')').each(function() {
				this.previousElementSibling.disabled = true;
				this.previousElementSibling.checked = false;
			});
		} else {
			this.$('[id*="sales_style-"]').each(function() {
				this.disabled = false;
			});
		}

		this.is_medical = false;

		if(this.isBuppin()){
			//特役・Cメディカル・Aメディカル・役務提供以外で、
			//物販か訪問か連鎖か企業間か物品か通信か電話か業務提供

			var selTyp = this.$("#specific_type_select").val();
			if (selTyp) this.select_specific_type_select = selTyp;

			this.$(".shitei_ekimu_hissu").hide();
			this.$("#specificSaleArea").css("opacity","0.3");
			this.$("#is_specific_sale-1").prop("checked",false);
			this.$("#is_specific_sale-2").prop("checked",false);
			this.$("#is_specific_sale-1").prop("disabled",true);
			this.$("#is_specific_sale-2").prop("disabled",true);

			this.$(".ekimu_category_hissu").hide();
			this.$("#specificTypeArea").css("opacity","0.3");
			this.$("#specific_type_select").val("").prop("disabled",true);

			this.$(".ekimu_date_hissu").hide();
			this.$("#serviceRangeDate .calender-picker").css("opacity","0.6");
			this.$("#serviceRangeDate .show-calender-btn").hide();
			this.$("#serviceRangeDate .select-year").prop("disabled",true);
			this.$("#serviceRangeDate .select-month").prop("disabled",true);
			this.$("#serviceRangeDate .select-day").prop("disabled",true);
			this.$("#kenri_itenjiki").text("");

			this.$(".ekimu_hutai_umu_hissu").hide();
			this.$("#hutai_item_umu").css("opacity","0.6");
			this.$("#is_attend_item-1").prop("checked",false);
			this.$("#is_attend_item-2").prop("checked",true);
			this.$("#is_attend_item-1").prop("disabled",true);
			this.$("#is_attend_item-2").prop("disabled",true);

			this.onUseAttendItemDelivery();

			this.$("#buppin_mask").show();
			
		}else if(this.isNotSpecific()){
			//Cメディカルか役務提供で
			//特役販売・Aメディカルでない

			//役務提供かどうか
			var isServiceOffer = this.$("#sales_style-11").prop("checked");

			this.$(".shitei_ekimu_hissu").show();
			this.$("#specificSaleArea").css("opacity","1");
			// this.$("#is_specific_sale-1").prop("checked",true);
			// this.$("#is_specific_sale-2").prop("checked",false);
			this.$("#is_specific_sale-1").prop("checked",false);
			this.$("#is_specific_sale-2").prop("checked",true);
			this.$("#is_specific_sale-1").prop("disabled",true);
			this.$("#is_specific_sale-2").prop("disabled",true);

			var medical_specific_sale = 1;

			if(this.$("#is_specific_sale_fix").size()){
				medical_specific_sale = this.$("#is_specific_sale_fix").val();
			}else{
				var ss1 = this.$("#is_specific_sale-1").prop("checked"); // 指定役務
				var ss2 = this.$("#is_specific_sale-2").prop("checked"); // 指定役務以外
				if(!ss1 && ss2){
					medical_specific_sale = 2;
				}
			}

			this.$(".ekimu_category_hissu").show();
			this.$("#specificTypeArea").css("opacity","1");
			this.$("#specific_type_select").val(this.select_specific_type_select).prop("disabled",false);

			// 医療、指定役務または役務提供の場合
			if(medical_specific_sale==1 || isServiceOffer){
				this.$(".ekimu_date_hissu").show();
				this.$("#serviceRangeDate .calender-picker").css("opacity","1");
				this.$("#serviceRangeDate .show-calender-btn").show();
				this.$("#serviceRangeDate .select-year").prop("disabled",false);
				this.$("#serviceRangeDate .select-month").prop("disabled",false);
				this.$("#serviceRangeDate .select-day").prop("disabled",false);
				this.onChangeStartOffer();
				this.$("#buppin_mask").hide();
			}else{// 医療、指定役務の別の場合
				this.is_medical = true;
				this.$(".ekimu_date_hissu").hide();
				this.$("#serviceRangeDate .calender-picker").css("opacity","0.6");
				this.$("#serviceRangeDate .show-calender-btn").hide();
				this.$("#serviceRangeDate .select-year").prop("disabled",true);
				this.$("#serviceRangeDate .select-month").prop("disabled",true);
				this.$("#serviceRangeDate .select-day").prop("disabled",true);
				this.$("#kenri_itenjiki").text("");
				this.$("#buppin_mask").show();
			}
			this.$(".ekimu_hutai_umu_hissu").show();
			this.$("#hutai_item_umu").css("opacity","1");
			this.$("#is_attend_item-1").prop("disabled",false);
			this.$("#is_attend_item-2").prop("disabled",false);

			this.onUseAttendItemDelivery();

		}else if(this.isSpecific()){
			//特役販売かAメディカルで
			//Cメディカル・役務提供でない

			this.is_buppin = false;
			this.is_medical = false;
			this.$(".shitei_ekimu_hissu").show();
			this.$("#specificSaleArea").css("opacity","1");

			this.$("#is_specific_sale-1").prop("checked",true);
			this.$("#is_specific_sale-2").prop("checked",false);
			this.$("#is_specific_sale-1").prop("disabled",true);
			this.$("#is_specific_sale-2").prop("disabled",true);

			this.$(".ekimu_category_hissu").show();
			this.$("#specificTypeArea").css("opacity","1");
			this.$("#specific_type_select").val(this.select_specific_type_select).prop("disabled",false);

			this.$(".ekimu_date_hissu").show();
			this.$("#serviceRangeDate .calender-picker").css("opacity","1");
			this.$("#serviceRangeDate .show-calender-btn").show();
			this.$("#serviceRangeDate .select-year").prop("disabled",false);
			this.$("#serviceRangeDate .select-month").prop("disabled",false);
			this.$("#serviceRangeDate .select-day").prop("disabled",false);
			this.onChangeStartOffer();

			this.$(".ekimu_hutai_umu_hissu").show();
			this.$("#hutai_item_umu").css("opacity","1");
			this.$("#is_attend_item-1").prop("disabled",false);
			this.$("#is_attend_item-2").prop("disabled",false);

			this.onUseAttendItemDelivery();

			this.$("#buppin_mask").hide();
		}else{
			this.is_buppin = false;
			this.is_medical = false;
			this.$(".shitei_ekimu_hissu").show();
			this.$("#specificSaleArea").css("opacity","1");
			this.$("#is_specific_sale-1").prop("checked",true);
			this.$("#is_specific_sale-2").prop("checked",false);
			this.$("#is_specific_sale-1").prop("disabled",false);
			this.$("#is_specific_sale-2").prop("disabled",false);

			this.$(".ekimu_category_hissu").show();
			this.$("#specificTypeArea").css("opacity","1");
			this.$("#specific_type_select").val(this.select_specific_type_select).prop("disabled",false);

			this.$(".ekimu_date_hissu").show();
			this.$("#serviceRangeDate .calender-picker").css("opacity","1");
			this.$("#serviceRangeDate .show-calender-btn").show();
			this.$("#serviceRangeDate .select-year").prop("disabled",false);
			this.$("#serviceRangeDate .select-month").prop("disabled",false);
			this.$("#serviceRangeDate .select-day").prop("disabled",false);
			this.onChangeStartOffer();

			this.$(".ekimu_hutai_umu_hissu").show();
			this.$("#hutai_item_umu").css("opacity","1");
			this.$("#is_attend_item-1").prop("disabled",false);
			this.$("#is_attend_item-2").prop("disabled",false);

			this.onUseAttendItemDelivery();

			this.$("#buppin_mask").hide();
			
		}
	},

	onClickClearService:function(){
		this.$('[for*="sales_style-"].lock-0').each(function() {
			this.previousElementSibling.disabled =
			this.previousElementSibling.checked = false;
		});
		this.onChangeService();
	},

	// 権利の引渡し時期設定
	onChangeStartOffer:function(){

		var year = this.$("#start_of_offer_year").val();
		var month = this.$("#start_of_offer_month").val();
		var day = this.$("#start_of_offer_day").val();

		if(year && month && day){
			if(_.validDate(year,month,day)){

				var date = moment(year+"/"+month+"/"+day);

				if(!this.isBuppin()){
					this.$("#kenri_itenjiki").text(date.format("YYYY年MM月DD日頃"));
				}

				this.$("#serviceRangeDate .error_message").hide();
			}else{
				this.setErrorMessage("#serviceRangeDate","正しい日付をご入力ください");
			}
		}
	},
	// 権利の引渡し時期設定
	onChangeEndOffer:function(){

		var year = this.$("#end_of_offer_year").val();
		var month = this.$("#end_of_offer_month").val();
		var day = this.$("#end_of_offer_day").val();

		if(year && month && day){
			if(_.validDate(year,month,day)){
				this.$("#serviceRangeDate .error_message").hide();
			}else{
				this.setErrorMessage("#serviceRangeDate","正しい日付をご入力ください");
			}
		}
	},

	// 指定役務内、外フラグ
	onChangeSpecificSale:function(){

		// var style1 = this.$("#sales_style-1").prop("checked");
		// var style2 = this.$("#sales_style-2").prop("checked");
		// var style3 = this.$("#sales_style-3").prop("checked");
		// var style4 = this.$("#sales_style-4").prop("checked");
		// var style5 = this.$("#sales_style-5").prop("checked");

		// if(!style1 && !style3 && style4 && !style5 && !style2){
		// 	this.is_medical = false;
		// 	this.is_buppin = false;

		// 	var medical_specific_sale = 1;

		// 	if(this.$("#is_specific_sale_fix").size()){
		// 		medical_specific_sale = this.$("#is_specific_sale_fix").val();
		// 	}else{
		// 		var ss1 = this.$("#is_specific_sale-1").prop("checked");
		// 		var ss2 = this.$("#is_specific_sale-2").prop("checked");
		// 		if(!ss1 && ss2){
		// 			medical_specific_sale = 2;
		// 		}
		// 	}

		// 	// 医療、指定役務の場合
		// 	if(medical_specific_sale==1){
		// 		this.$(".ekimu_date_hissu").show();
		// 		this.$("#serviceRangeDate .calender-picker").css("opacity","1");
		// 		this.$("#serviceRangeDate .show-calender-btn").show();
		// 		this.$("#serviceRangeDate .select-year").prop("disabled",false);
		// 		this.$("#serviceRangeDate .select-month").prop("disabled",false);
		// 		this.$("#serviceRangeDate .select-day").prop("disabled",false);
		// 		this.onChangeStartOffer();
		// 		this.$("#buppin_mask").hide();
		// 	}else{// 医療、指定役務の別の場合
		// 		this.is_medical = true;
		// 		this.$(".ekimu_date_hissu").hide();
		// 		this.$("#serviceRangeDate .calender-picker").css("opacity","0.6");
		// 		this.$("#serviceRangeDate .show-calender-btn").hide();
		// 		this.$("#serviceRangeDate .select-year").prop("disabled",true);
		// 		this.$("#serviceRangeDate .select-month").prop("disabled",true);
		// 		this.$("#serviceRangeDate .select-day").prop("disabled",true);
		// 		this.$("#kenri_itenjiki").text("");
		// 		this.$("#buppin_mask").show();
		// 	}


		// }

		/*
		var $checked = this.$('#specificSaleArea input:checked');
		var value = $checked.val();
		
		if(value=="1"){
			this.$("#tr_specific_type").show();
			this.$("#tr_specific_content").hide();
		}else{
			this.$("#tr_specific_content").show();
			this.$("#tr_specific_type").hide();
		}
		*/
	},

	// 引渡し時期を使えるようにするかどうか
	onUseAttendItemDelivery: function(){
		var saller_name = this.$("#saller_name").text();

		// 関連商品の有無
		if(this.$("#is_attend_item-1").prop("type")=="radio"){
			var coItem = this.$("#is_attend_item-1").prop("checked");
		}else{
			var coItem = this.$(".is_attend_item_hidden").val() == 1 ? 1 : 0;
		}

		var val = this.$("#deliveryTimeArea").data("default");
		if(!this.$("#attend_item_delivery_select").prop("disabled")){
			if(this.$("#deliveryTimeArea").data("lock")){
			}else{
				val = this.$("#attend_item_delivery_select").val();
			}
		}


		if(coItem){
			this.$("#loan_item_naigai_type").show();
			this.$("#loan_item_naigai_type_hidden").val("1");
		}else{
			this.$("#loan_item_naigai_type").hide();
			this.$("#loan_item_naigai_type_hidden").val("0");
		}

		var $options = this.$("#attend_item_delivery_select").find("option");

		//if(this.funcCheckUseDeliveryTime()){
		if(coItem){
			this.$("#saller_name_form").val(saller_name);
			this.$("#hikiwatashi_hissu").show();
			this.$("#attend_item_delivery_select").prop("disabled",false);
			if(this.$("#deliveryTimeArea").data("lock")){
				$options.each(function(){
					var oval = $(this).attr("value");
					if(oval==val){
						$(this).prop("readonly",true);
					}else{
						$(this).prop("disabled",true);
					}
				});
			}else{
				$options.each(function(){
					$(this).prop("readonly",false);
					$(this).prop("disabled",false);
				});
			}
			this.$("#attend_item_delivery_select").val(val);
			//this.$("");
		}else{
			this.$("#saller_name_form").val("");
			this.$("#hikiwatashi_hissu").hide();
			this.$("#attend_item_delivery_select").prop("disabled",true);
			$options.each(function(){
				$(this).prop("readonly",false);
				$(this).prop("disabled",false);
			});
			this.$("#attend_item_delivery_select").val("");
		}
		if(this.funcCheckUseDeliveryTime()){
			this.$("#hikiwatashi_hissu").show();
			this.$("#attend_item_delivery_select").prop("disabled",false);
			this.$("#attend_item_delivery_select").val(val);
			if(this.$("#deliveryTimeArea").data("lock")){
				$options.each(function(){
					var oval = $(this).attr("value");
					if(oval==val){
						$(this).prop("readonly",true);
					}else{
						$(this).prop("disabled",true);
					}
				});
			}else{
				$options.each(function(){
					$(this).prop("readonly",false);
					$(this).prop("disabled",false);
				});
			}
		}else{
			this.$("#attend_item_delivery_select").val("");
			$options.each(function(){
				$(this).prop("readonly",false);
				$(this).prop("disabled",false);
			});
			
		}
		this.onChangeAttendItemDelivery();
	},

	// 引渡し時期の計算処理
	onChangeAttendItemDelivery: function(){
		//（12月20日頃）
		this.$("#attend_item_delivery_view").text("");

		var delVal = this.$("#attend_item_delivery_select").val();
		if(delVal){

			var year = this.$("#create_apply_year").val();
			var month = this.$("#create_apply_month").val();
			var day = this.$("#create_apply_day").val();

			// バリデートする
			var text = "";
			if(Validator.isDate(year,month,day)){
				var moment_obj = moment(year+"/"+month+"/"+day).add(delVal, 'days');
				text = moment_obj.format("（YYYY年MM月DD日頃）");
			}

			this.$("#attend_item_delivery_view").text(text);
		}

	},

/*----------  商品のモーダル関係から、商品の値段の計算  ----------*/

	// アイテムを選ぶ
	onClickShowSelectItem:function(e){
		this.$item_select_area = $(e.currentTarget).parents(".items");
		//this.$item_select_modal.modal("show");
	},

	// アイテムを選択したタイミング
	onClickModalSelectItem:function(e){

		var $this = $(e.currentTarget).parents("li");
		var name = $this.data("name");
		var price = $this.data("price");
		var id = $this.data("category_id");
		var category = $this.data("category");
		var category_name = $this.data("category_name");
		var amount = $this.data("amount");
		var month = $this.data("month");
		var isfree = $this.data("isfree");
		var count = $this.data("count");

		this.$item_select_area.removeData();

		this.$item_select_area.data("count",count);

		this.$item_select_area.data("name",name);
		this.$item_select_area.data("category",category);


		this.$item_select_area.find(".input_item_id_area").val(id);
		this.$item_select_area.find(".input_item_month_area").val(month);
		this.$item_select_area.find(".input_item_code_area").val(category);
		this.$item_select_area.find(".input_item_max_count").val(count);
		this.$item_select_area.find(".input_item_code_name").val(category_name);
		this.$item_select_area.find(".input_item_isfree_area").val(isfree);

		this.$item_select_area.find(".item_category").text(category_name);
		this.$item_select_area.find(".item_name input").val(name);
		this.$item_select_area.find(".item_amount input").val(amount);
		this.$item_select_area.find(".item_price input").val(changeNumFomatMoney(price));

		this.$item_select_area.find(".item_name").show();
		this.$item_select_area.find(".item_amount_unit").show();
		this.$item_select_area.find(".item_amount").show();
		this.$item_select_area.find(".item_price").show();
		this.$item_select_area.find(".item_price_unit").show();

		this.$item_select_area.find(".item_name input").prop("readonly",true);
		this.$item_select_area.find(".item_amount input").prop("readonly",true);

		this.$item_select_area.find(".item_price input").prop("readonly",true);
		this.$item_select_area.find(".item_price_unit input").prop("readonly",true);
		if(!amount){
			this.$item_select_area.find(".item_amount input").prop("readonly",false);
		}
		if(!price){
			this.$item_select_area.find(".item_price input").prop("readonly",false);
		}

		if(!name){
			this.$item_select_area.find(".item_name input").prop("readonly",false);
			this.$item_select_area.find(".item_amount input").prop("readonly",false);
			this.$item_select_area.find(".item_price input").prop("readonly",false);
			this.$item_select_area.find(".item_price_unit input").prop("readonly",false);
		}

		this.$item_select_modal.modal("hide");

		// 役務提供期間をセットする
		this.onSetServiceRange();

		// お支払い回数をセットする
		this.onSetPaymentCount();

		// 値段を再計算する
		this.onStartItemAreaReload();

	},

	// 販売商品を空にする（削除ボタン）
	onClickItemEmpty:function(e){
		var $this = $(e.currentTarget).parents(".sale_item_tr").find(".items");

		$this.removeData();
		$this.data("count","");

		$this.find(".input_item_id_area").val("");
		$this.find(".input_item_month_area").val("");
		$this.find(".input_item_max_count").val("");
		$this.find(".input_item_code_area").val("");
		$this.find(".input_item_code_name").val("");
		$this.find(".input_item_isfree_area").val("");
		$this.find(".item_category").text("");
		$this.find(".item_name input").val("");
		$this.find(".item_amount input").val("");
		$this.find(".item_price input").val("");

		$this.find(".item_name").hide();
		$this.find(".item_amount_unit").hide();
		$this.find(".item_amount").hide();
		$this.find(".item_price").hide();
		$this.find(".item_price_unit").hide();

		// お支払い回数をセットする
		this.onSetPaymentCount();

		// 値段を再計算する
		this.onStartItemAreaReload();
	},

	// 役務提供期間を計算
	onSetServiceRange:function(){
		//役務提供かどうか
		var isServiceOffer = this.$("#sales_style-11").prop("checked");

		if(isServiceOffer || (!this.isBuppin() && !this.isNotSpecific())){
			var syear = this.$("#start_of_offer_year").val();
			var smonth = this.$("#start_of_offer_month").val();
			var sday = this.$("#start_of_offer_day").val();
			if(syear && smonth && sday){
				var start = moment(syear+"/"+smonth+"/"+sday);
				var $months = this.$(".sale_item_tr .input_item_month_area");
				var month = 0;
				$months.each(function(){
					var val = Number($(this).val());
					if(val){
						month = Math.max(month,val);
					}
				});

				if(month){
					var end = start.add(month,"M").subtract(1,"d");
					this.$("#end_of_offer_year").val(end.year());
					this.$("#end_of_offer_month").val(end.month()+1);
					this.$("#end_of_offer_day").val(end.date());
				}
			}
		}
	},

	// 手数料全額負担のチェック
	onChangeBurdenCommission:function(e){
		
		// 変数
		var check = this.$("#burden_commission").prop("type") == "checkbox";
		var isHide;

		// lockかかっているかどうか
		if(!check){ // ロックしている
			isHide = Number(this.$("#burden_commission").val());
		}else{ // ロックなし（選択可能）
			isHide = this.$("#burden_commission").prop("checked");
		}
		if(isHide){
			// 加盟店全額負担
			this.$("#paymentBurdenCommissionNumArea").hide();
			this.$("#paymentBurdenCommissionNumAreaOnePay").hide();
			this.$("#paymentBurdenCommissionNumAreaTwoPay").hide();	
		}else{
			// 加盟店按分負担可
			if(this.pay_type == 1 || this.pay_type == 2){
				this.$("#paymentBurdenCommissionNumArea").show();
				this.$("#paymentBurdenCommissionNumAreaOnePay").hide();
				this.$("#paymentBurdenCommissionNumAreaTwoPay").hide();
			}else if(this.pay_type == 3){
				this.$("#paymentBurdenCommissionNumArea").hide();
				this.$("#paymentBurdenCommissionNumAreaOnePay").show();
				this.$("#paymentBurdenCommissionNumAreaTwoPay").hide();
			}else if(this.pay_type == 4){
				this.$("#paymentBurdenCommissionNumArea").hide();
				this.$("#paymentBurdenCommissionNumAreaOnePay").hide();
				this.$("#paymentBurdenCommissionNumAreaTwoPay").show();
			}
		}

		if(!can_bonus_burden){
			this.$("#paymentBurdenCommissionNumAreaOnePay").hide();
			this.$("#paymentBurdenCommissionNumAreaTwoPay").hide();	
		}

		this.onSetBonusPaymentCount();

		this.onEventLoanSimlation();
	},

	// 分割回数数値変更
	onSetPaymentCount:function(){
		var self = this;
		var $items = this.$("#sale_items_area").find(".items");
		var $target = this.$("#pay_manual_count");
		var max_count = 0;
		var counts = [];
		var def = "";

		var burden_count = this.$("#paymentBurdenCommissionNumSelect").val();

		if($target.size()){
			$items.each(function(){
				var $this = $(this);
				var count = $this.data("count");
				if(count){
					if(max_count){
						max_count = Math.max(max_count,count);
					}else{
						max_count = count;
					}
				}
			});
			
			if(max_count){
				for(var i=3; i<=max_count; i++){
					if(pay_counts.indexOf(""+i) !== -1){
						counts.push(""+i);
					}
				}
			}else{
				counts = pay_counts;
				def = pay_count_default;
			}
			var options = "<option value=''> - </option>";

			var burdenMaxCount = 0;

			_.each(counts,function(v,i){
				var s = "";
				if(def==v){
					s = "selected";
				}
				options += "<option value='"+v+"' "+s+">"+v+" 回</option>";
				burdenMaxCount = Math.max(v,burdenMaxCount);
			},this);

			this.$("#payment_manual_count").empty().html(options);

			// 手数料支払い回数の設定
			if(this.$("#paymentBurdenCommissionNumSelect").size()){
				var def = this.$("#paymentBurdenCommissionNumSelect").val();
				var options = "";

				// 手数料支払い回数を空にして、新しい回数を入れる
				for(var i=0; i<=burdenCommissionNumber; i++){
					if(i <= Math.min(burdenMaxCount,burdenCommissionNumber)){
						var s = "";
						if(def==i){
							s = "selected";
						}
						options += "<option value='"+i+"' "+s+">"+i+" 回</option>";
					}
				}

				this.$("#paymentBurdenCommissionNumSelect").empty().html(options);
			}
		}

		this.onSetBonusPaymentCount();
	},

	// 一括、2回払の加盟店支払い可能回数変更
	onSetBonusPaymentCount:function(){

		var count = 0;

		// 一括、二回は、こちらをBaseに作る
		var cyear = this.$("#create_apply_year").val();
		var cmonth = this.$("#create_apply_month").val();
		var cday = this.$("#create_apply_day").val();
		var oneTwoPayCmoment = moment(cyear+"/"+cmonth+"/"+cday);
		if(!isTablet){
			oneTwoPayCmoment = contract_created_at;
		}

		var select = null;
		var base = oneTwoPayCmoment.clone().startOf('month');
		var max = null;

		if(this.pay_type == 3){
			select = this.$("#paymentBurdenCommissionNumSelectOneType");
			if(!select.size()){
				select = null;
			}

			if(select){
				max = moment(this.$("#onePaySelect1").val().replace(/-/g, "/"));
			}
		}else if(this.pay_type == 4){
			select = this.$("#paymentBurdenCommissionNumSelectTwoType");
			if(!select.size()){
				select = null;
			}

			if(select){
				max = moment(this.$("#twoPaySelect2").val().replace(/-/g, "/"));
			}
		}

		if(max){
			count = max.diff(base, 'months'); // + 1;

			var options = "";
			_.times(count,function(v){
				var s = "";
				if(burdenCommissionCount==v){
					s = "selected";
				}
				options += "<option value='"+v+"' "+s+">"+v+" 回</option>";
			},this);

			if(select){
				select.empty().html(options);
			}
			this.onCheckBurdenCommision();
		}

		this.onEventLoanSimlation();

	},

	// アイテムが変更等行った場合、自動計算
	onStartItemAreaReload:function(){

		// トータルと頭金の計算を行う
		this.funcReloadDepositMoney();

		// ローンシュミレーション
		this.funcLoanSimlation();
	},


	onKeyUpItemPriceArea:function(){
		this.funcReloadDepositMoney();
	},

	onChangeDiscountItemPrice:function(){
		var val = this.$("#discount_item_price").val();
		val = val.replace(/[Ａ-Ｚａ-ｚ０-９]/g, function(s) {
            return String.fromCharCode(s.charCodeAt(0) - 65248);
        });
		var discount = Number(val);

		if(_.isNaN(discount)){
			this.$("#discount_item_price").val("");
		}else{
			if(discount > 0){ // 価格を反転
				discount = discount * (-1);
				this.$("#discount_item_price").val(discount);
			}
		}
	},

/*----------  お支払に関する処理  ----------*/
	onCheckBonusMoney:function(){

		var errorKey2 = "bonusPayMoneyArea";

		this.delError(errorKey2);

		var val = this.$("#bonusPayMoneyInput").val();
		val = val.replace(/[Ａ-Ｚａ-ｚ０-９]/g, function(s) {
            return String.fromCharCode(s.charCodeAt(0) - 65248);
        });
		var total = this.$("#loan_total").val();

		val = changeNumFomatInt(val);
		total = changeNumFomatInt(total);
		harf = Math.floor(total/2);
		if(val){
			if(val % 1000 != 0){
				this.setError(errorKey2,"加算額が間違っています。ボーナス加算金は必ず千円単位で設定してください。");
				this.setErrorMessage("#"+errorKey2,this.getError(errorKey2));
			}else{
				this.$("#bonusPayMoneyArea .error_message").hide();
			}
		}else{
			this.$("#bonusPayMoneyInput").val(0);
		}

		this.funcLoanSimlation();
	},

	onKeyUpLoanDeposit:function(){
		this.funcReloadDepositMoney();
	},

	onKeyUpTwoPayMoney:function(){
		var total = changeNumFomatInt(this.$("#loan_total").val());
		var firstPay = changeNumFomatInt(this.$("#twoPayInput1AreaInput").val());
		if(total && firstPay){
			var secondPay = total - firstPay;
			this.$("#twoPayInput2AreaInput").val(changeNumFomatMoney(secondPay));
		}else{
			this.$("#twoPayInput2AreaInput").val("");
		}
	},

	onChangePaymentType:function(){
		var $area = this.$("#paymentTypeArea");
		var $num_area = this.$("#paymentNumArea");
		var $sim_area = this.$("#simulationArea");
		var value = $area.find('[name="ctsp_is_payment_type"]:checked').val();

		$num_area.find(".pay_manual").hide();
		$num_area.find(".pay_one").hide();
		$num_area.find(".pay_two").hide();

		this.$("#bonus_pay_block").hide();
		this.$("#one_pay_block").hide();
		this.$("#two_pay_block").hide();

		$sim_area.find(".simlation_manual_pay").hide();
		$sim_area.find(".simlation_bonus_pay").hide();
		$sim_area.find(".simlation_one_pay").hide();
		$sim_area.find(".simlation_two_pay").hide();

		if(value == 1 || value == 2){
			$num_area.find(".pay_manual").show();

			if(value == 1){
				$sim_area.find(".simlation_manual_pay").show();
			}
			if(value == 2){
				this.$("#bonus_pay_block").show();
				$sim_area.find(".simlation_bonus_pay").show();
			}
		}else if(value == 3){
			$num_area.find(".pay_one").show();
			this.$("#one_pay_block").show();
			$sim_area.find(".simlation_one_pay").show();
		}else if(value == 4){
			$num_area.find(".pay_two").show();
			this.$("#two_pay_block").show();
			$sim_area.find(".simlation_two_pay").show();
		}

		this.pay_type = value;
		this.funcSetTwoPayFirstMoney();

		// 手数料負担回数の表示非表示
		this.onChangeBurdenCommission();

		// ローンシュミレーション
		//this.funcLoanSimlation();
	},

	onCheckBurdenCommision:function(){
		this.$("#paymentBurdenCommissionNumArea").find(".error_message").hide();
		//this.onSetBonusPaymentCount();
		this.checkLoanCount();
		this.onEventLoanSimlation();
	},

	onChangeBonusPayMonthForm:function(){
		var val = $('#bonus_pay_month_form').val();
		if(val){
			if(val=='1'){
				$('#pay_bonus_summer_month').val(1);
				$('#pay_bonus_winter_month').val(1);
			}
			if(val=='2'){
				$('#pay_bonus_summer_month').val(2);
				$('#pay_bonus_winter_month').val(2);
			}
		}else{
			$('#pay_bonus_summer_month').val('');
			$('#pay_bonus_winter_month').val('');
		}
		this.onEventLoanSimlation();
	},

	onChangeTwoPaySelect1:function(){
		this.funcSetPayMonthCol();
		this.onSetBonusPaymentCount();
	},

	onEventLoanSimlation:function(){
		if(!this.$("#loan_deposit").val()){
			this.$("#loan_deposit").val("0");
		}

		// ローンシュミレーション
		this.funcLoanSimlation();
	},

	// 担当者が選択された時
	onSelectStaff:function(e){
		// var $select = this.$("#staff_select");
		// var val = $select.val();
		// if(val=="other"){
		// 	if(!_(e).isUndefined()){
		// 		this.$("#no_regit_staff").find(".name1").val("");
		// 		this.$("#no_regit_staff").find(".name2").val("");
		// 	}
		// 	this.$("#no_regit_staff").show();
		// }else{
		// 	this.$("#no_regit_staff").hide();
		// 	if(val){
		// 		var $option = $select.find("option:selected");

		// 		var data = $option.data();

		// 		this.$("#no_regit_staff").find(".name1").val(data.sei);
		// 		this.$("#no_regit_staff").find(".name2").val(data.mei);
		// 	}
		// }
	},

	onChangeMailDomainSelect:function(){
		//「その他」ならフォーム表示
		var $sel = this.$('[name="mail_address_select_domain"]');
		var $other = this.$('[name="mail_address_input_domain"]');
		if ($sel.val() == 'other') {
			$sel.addClass('show_other');
			$other.show();
		} else {
			$sel.removeClass('show_other');
			$other.hide();
		}
	},

/*=====  End of Event  ======*/



});
