var RedInvoiceDetail = Backbone.View.extend({

	events:{

		"change #mail_address_select_domain":"onClickSelectDomain",
		"click #dairi_mail_submit":"onClickAgencySubmit",

		"change #readed_check" : "onCheckReadedCheck",

		"click #agency_agree_modal_btn" : "onClickAgencyAgreeModalBtn",
		"click #agency_agree_btn" : "onClickAgencyAgreeBtn",
	},

	
	onClickSelectDomain:function(){
		var selectDomain = this.$("#mail_address_select_domain").val();
		if(selectDomain=="other"){
			this.$(".other_domain").css("display","inline-block");
		}else{
			this.$(".other_domain").css("display","none");
		}

	},
	
	onClickAgencySubmit:function(){
		var error = false;
		var $mail = this.$('#mail_address_input');
		var $sel = this.$('[name="mail_address_select_domain"]');
		var account = this.$('[name="mail_address_input_account"]').val();
		var domain = ($sel.val() == 'other') ? this.$('[name="mail_address_input_domain"]').val() : $sel.find('option:selected').text();

		// 小文字に変換してアドレス渡す
		var address = account.toLowerCase() + '@' + domain.toLowerCase();
		$mail.val(address);
	
		if (!account) {
			this.$("#red_invoice_agency_check_back_error p").text("メールアドレスの「@」マークより前の部分は必ず入力してください。");
			this.$("#red_invoice_agency_check_back_error").show();
			agency_error = true;
		} else if (($sel.val() == 'other') && !domain) {
			//「その他」でドメイン未入力の場合
			this.$("#red_invoice_agency_check_back_error p").text("その他の場合は、必ずその他入力欄にメールアドレスの「@」マークより後ろの部分を入力してください。");
			this.$("#red_invoice_agency_check_back_error").show();
			agency_error = true;

		} else if (!Validator.isMail(address)) {
			this.$("#red_invoice_agency_check_back_error p").text("メールアドレスが正しくない可能性があります。");
			this.$("#red_invoice_agency_check_back_error").show();
			agency_error = true;
		}else{
			this.$("#red_invoice_agency_check_back_error").hide();
		}

		if(!error){
			$("#mail_submit_form").submit();
		}

		return false;

	},

	onCheckReadedCheck:function(){
		var checked = this.$("#readed_check").prop("checked");
		var flg = checked ? 1 : 0;
		this.ajax(COMMENT_READED,{"flg":flg},function(res){
			console.log(res);
			this.$(".readed_check_date").text(res.date);
		});
	},

	onClickAgencyAgreeModalBtn:function(){
		$("#agency_agree_modal").modal("show");
		return false;
	},
	onClickAgencyAgreeBtn:function(){
		$("#agency_agree_form").submit();

		return false;
	},

});
