var UseDropzone;
var ingDropzone = false;
//---------------------------------------------------------------------------
// ファイルのアップローダー
//---------------------------------------------------------------------------
var ImageUploaderForm = Backbone.View.extend({

	events:{
		"click .drop-area-icon":"onClickDropZone",
        "click .file-empty span":"onClickDropZone",
		"click .drop-area-message":"onClickDropZone",
        //"click .file-empty":"onClickDropZone",
		"click .remove-btn":"onClickRemoveImage",
        "uploaded":"onUploaded",
        "showWaitMe":"showWaitMe",
        "showError":"showError",
        "hideWaitMe":"hideWaitMe",
        "hideError":"hideError",
	},

    id:0,
    name:"",
	dropzone:null,
    droparea:null,
    imagebox:null,
    removeBtn:null,

    timeout:60000,
    timeoutSet:false,

	initialize:function(){
		var self = this;
        self.droparea = self.$(".file-empty");
        self.imagebox = self.$(".file-uploaded");
        self.removeBtn = self.$(".remove-btn");

        self.droparea.on("click",function(e){
            if(!ingDropzone){
                UseDropzone = $(e.currentTarget).parents(".image-uploader");
            }else{
                e.stopImmediatePropagation();
                alert("アップロード中です。");
                return false;
            }
        });


		self.dropzone = new Dropzone(self.droparea.get(0), {
			url: __UPLOAD_IMAGE_URL__,

			createImageThumbnails:false,
            previewTemplate:"",
            parallelUploads:1,
            uploadMultiple:false,
            paramName:"image",

            // フィルタ
            accept: function(file, done) {
                var reg = /^image\/[jpg|gif|png|jpeg]/;
                var reg2 = "application/pdf";
                //done();

                if((file.type.match(reg) || file.type==reg2) && file.type){
                    done();
                    UseDropzone.trigger("showWaitMe");
                    ingDropzone = true;
                    clearTimeout(self.timeoutSet);
                    self.timeoutSet = setTimeout(function(){
                        clearTimeout(self.timeoutSet);
                        self.dropzone.removeAllFiles(true);
                        //ingDropzone = false;
                        //UseDropzone.trigger("hideWaitMe");
                        //UseDropzone.trigger("showError","アップロードタイムアウト");
                    },self.timeout);
                    //self.showWaitMe();
                }else{
                    ingDropzone = false;
                    UseDropzone.trigger("showError","画像データ（jpg,jpeg,png,gif,pdf）のみアップロード可能です");
                    //self.showError("画像データのみアップロード可能です");
                }

            },

            // 画像がドラッグされた時
            addedfile:function(file,e){
                // ドラッグされた画像
                //console.log(file);
                //var name = file.name;
            },

            sending: function(file, xhr, formData) {
	            formData.append("_token", $('meta[name="csrf-token"]').attr('content'));
	        },
            
            // アップされた画像をレンダー側へ表示する
            success:function(file,res,e){
                //self.uploaded(res);
                //this.files.shift();
                //this.files = [];
                clearTimeout(self.timeoutSet);
                UseDropzone.trigger("hideWaitMe");
                UseDropzone.trigger("hideError");
                self.uploaded(res);
                ingDropzone = false;
            },
            
            // ドラッグ関係のイベント
            dragover:function(e){
                self.$el.addClass('drag-on');
            },
            dragleave:function(e){
                self.$el.removeClass('drag-on');
            },
            drop:function(e){
                UseDropzone = $(e.target).parents(".image-uploader");
                self.$el.removeClass('drag-on');
            },
            


            // waitMe関係
            complete:function(file){
                
            },
            error:function(file,res){
                ingDropzone = false;
                clearTimeout(self.timeoutSet);
                console.log(res);
                UseDropzone.trigger("hideWaitMe");
                if(res == "Upload canceled."){
                    UseDropzone.trigger("showError","アップロードがタイムアウトしました。再度アップロードお願いします。");
                }else if(res=="Server responded with 0 code."){
                    UseDropzone.trigger("showError","アップロードがタイムアウトしました。再度アップロードお願いします。");
                }else if(res=="Server responded with 0 code."){
                    UseDropzone.trigger("showError","アップロードがタイムアウトしました。再度アップロードお願いします。");
                }else{
                    UseDropzone.trigger("showError",res.image[0]);
                }
            },
		});



        this.id = Number(self.$el.data("id"));
        this.name = self.$el.data("name");
        if(!this.id){
            this.droparea.show();
            this.imagebox.hide();
            this.removeBtn.hide();
        }else{
            this.droparea.hide();
            this.imagebox.show();
            this.removeBtn.show();
            this.attendHiddenForm();
        }

	},

    showWaitMe:function(){
        this.droparea.waitMe({effect : 'progressBar'});
    },
    hideWaitMe:function(){
        this.droparea.waitMe("hide");
    },

    showError:function(e,message){
        this.hideError();
        var html = "<div class='image_error_message'>"+message+"</div>";
        this.$el.append(html);
    },
    hideError:function(message){
        this.$(".image_error_message").remove();
    },


	uploaded:function(res){

        if(UseDropzone){
            UseDropzone.trigger("uploaded",res);
        }

	},

	onClickRemoveImage:function(e){
		this.droparea.show();
        this.imagebox.hide();
        this.imagebox.find("img").remove();
        this.removeBtn.hide();
        this.id = 0;
        this.$el.data("id","");
        this.$("input.id_data").remove();
	},


	onClickDropZone:function(e){
        this.droparea.trigger("click");
	},

    onUploaded:function(e,res){
        //img.attr("data-img-id",res.imgs_big_id);

        var self = this;

        this.droparea.hide();
        this.imagebox.show();
        this.removeBtn.show();

        this.id = res.imgs_big_id;
        this.$el.data("id",this.id);

        this.$("input.id_data").remove();

        setTimeout(function(){
            //var img = $("<img />").attr("src",res.imgs_url);
            var img = $("<img />").attr("src",res["base64"]["encoded"]);
            self.imagebox.append(img);

            self.attendHiddenForm();
        },50);
    },

    attendHiddenForm:function(){
        var input = $("<input />").attr("type","hidden");
            input.addClass("id_data");
            input.attr("name",this.name);
            input.attr("value",this.id);

            this.$el.append(input);
    },



});

//---------------------------------------------------------------------------
// ジェネレーター
//---------------------------------------------------------------------------
(function(){
    SCRIPTS["ImageUploaderForm"] = function(commonScript){
        commonScript.$(".image-uploader").each(function(){
            new ImageUploaderForm({el:this});
        });
    };
})();
