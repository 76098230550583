//---------------------------------------------------------------------------
// プラムページ　一括最終承認・再指定
//---------------------------------------------------------------------------
var PlumBatchList = Backbone.View.extend({
	events:{
		'show.bs.modal .modal': 'onShowModal',
		'click [data-target]': 'onClickBtn',
		'click .modal-footer .submit_btn': 'onClickSubmit',
	},

	initialize:function(){
		// ドラッグ時のイベント
		var tBody = document.querySelector('[name^="batch_"] tbody');
		var lbls = tBody.getElementsByTagName('label');

		tBody.addEventListener('mousedown', function(md) {
			// マウス押下でドラッグを予期
			var mdTgt = null;
			switch (md.target.tagName) {
				case 'LABEL': mdTgt = md.target.firstElementChild; break;
				case 'INPUT': mdTgt = md.target;
			}
			var mdIsChecked = (mdTgt) ? mdTgt.checked : false;

			if (mdTgt) {
				for (var i = lbls.length; i--;) lbls[i].onmouseenter = function(me) {
					// 他の要素に入ったらドラッグしたと見做す
					mdTgt.checked = !mdIsChecked;
					var meTgt = me.currentTarget.firstElementChild;
					var meIsChecked = meTgt.checked;
					if (mdIsChecked && meIsChecked || !mdIsChecked && !meIsChecked) meTgt.click();
				};
			}

			tBody.addEventListener('mouseup', function() {
				// マウス離すとドラッグ解除
				for (var i = lbls.length; i--;) lbls[i].onmouseenter = null;
			}, false);

		}, false);

	},

/*=============================
=            Event            =
=============================*/

	onShowModal: function(e) {
		var isClick = false;
		e.target.onkeydown = function(kd) {
			if (kd.key === 'Enter') {
				var btn = e.target.querySelector('.modal-footer .submit_btn');
				btn.classList.add('active');
				e.target.onkeyup = function() {
					if (!isClick) btn.click();
					btn.classList.remove('active');
					isClick = true;
				};
			}
		};
	},

	onClickBtn: function(e) {
		var modal = document.querySelector( e.currentTarget.getAttribute('data-target') );
		var contList = modal.getElementsByClassName('cont_list')[0];
		var checkeds = document.querySelectorAll('[data-id][data-name]:checked');

		var html = '';
		for (var i = 0; i < checkeds.length; i++) {
			html += '<tr><td>' + checkeds[i].getAttribute('data-id') + '</td><td>' + checkeds[i].getAttribute('data-name') + '</td></tr>';
		}

		modal.getElementsByClassName('cont_cnt')[0].textContent = i;
		contList.textContent = '';
		contList.insertAdjacentHTML('beforeend', html);
	},

	onClickSubmit: function() {
		this.$('[name^="batch_"]').submit();
	},
	
/*=====  End of Event  ======*/

});