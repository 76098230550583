//---------------------------------------------------------------------------
// チャットのプログラム
//---------------------------------------------------------------------------
var ChatDetail = Backbone.View.extend({
	events:{
		"click .weit-btn" : "onClickWeitBtn",

		"click .menu_item" : "onClickMenuitem",

		"click #submit_btn":"onSubmitMessageBtn",

		"change .readed_check" : "onChangeReadedCheck",
	},

	chatRoomId:null,

	messageTimeout:null,
	messageReloadTime: 10000,


	initialize:function(){
		
		// 初期クリック
		this.$(".menu_item").first().trigger("click");


	},





/*===============================
=            Checker            =
===============================*/



/*=====  End of Checker  ======*/


	



	
/*===============================
=            Program            =
===============================*/
	openChatRoom:function(){
		this.$(".chat_room").waitMe({effect : 'progressBar'});
		this.clearGetMessage();

		var data = {
			id:this.chatRoomId,
		};

		this.ajax(__CHAT_ROOM_URL,data,function(res){
			this.setChatRoom(res);
			this.$(".chat_room").waitMe("hide");
		},function(){
			this.$(".chat_room").waitMe("hide");
		});

	},

	setChatRoom:function(res){
		var contract = res;
		var customer = res.customer;
		var mail_address = res.mail_address;
		var self = this;

		this.$("#contract_id").text(contract.ctsp_var_local_id);
		this.$("#customer_name").text(customer.name1);
		this.$("#customer_mail").text(mail_address.ctml_vc_send_address);
		this.$("#contract_status").text(contract.status_name);
		this.$("#contract_apply_dt").text(customer.apply_at);

		this.$("#to_detail_btn").attr("href",contract.store_detail_url);

		var $message_list = this.$(".message_list");
		$message_list.empty();

		this.gotMessage(res.room_comments);

		this.clearMessageForm();

		// メッセージを出力する


		this.messageTimeout = setTimeout(function(){
			self.getMessage();
		},this.messageReloadTime);
	},

	clearMessageForm:function(){
		this.$("#message_form").val("");
	},

	sendMessage:function(){
		var message = this.$("#message_form").val();
		if(message){
			var data = {
				id:this.chatRoomId,
				message:message,
			};

			var self = this;

			this.ajax(__CHAT_SEND_URL,data,function(){
				self.getMessage();
			},function(){
				
			});

			this.$("#message_form").val("");
		}
	},

	getMessage:function(){
		this.clearGetMessage();
		var self = this;

		var chat_message = this.$(".chat_message").first();
		var messageId = 0;
		if(chat_message.size()){
			messageId = chat_message.data("messageId");
		}

		var data = {
			id:this.chatRoomId,
			message_id:messageId,
		};

		this.ajax(__CHAT_MESSAGE_URL,data,function(res){
			this.gotMessage(res);

			this.messageTimeout = setTimeout(function(){
				self.getMessage();
			},this.messageReloadTime);
		},function(){
		});
	},

	gotMessage:function(messages){
		var $message_list = this.$(".message_list");

		var owner_tpl = _.template(this.$("#message_owner").html());
		var plum_tpl = _.template(this.$("#message_plum").html());

		var all_html = "";

		_.each(messages,function(v){
			var user = v.login_user;
			var type = user.users_int_type;
			var html = "";

			v.is_readed = 0;

			switch(__USER_TYPE){
				case 1 : {
					v.is_readed = v.cht_is_branch_readed;
					break;
				}
				case 2 : {
					v.is_readed = v.cht_is_member_readed;
					break;
				}
				case 3 : {
					v.is_readed = v.cht_is_broker_readed;
					break;
				}
			}

			if(type==9){
				html = plum_tpl(v);
			}else{
				html = owner_tpl(v);
			}

			$message_list.prepend(html);
		});


	},

	clearGetMessage:function(){
		clearTimeout(this.messageTimeout);
	},

	updateUnreadCount:function(){
		var $check = this.$(".readed_check");
		var count = 0;
		$check.each(function(){
			var $this = $(this);
			if(!$this.prop("checked")){
				count++;
			}
		});

		var $menu_item = this.$(".menu_item.cur");
		var $comment = $menu_item.find(".comment");
		if(count==0){
			$comment.empty();
		}else{
			var $label = $comment.find("label");
			if($label.size()){
				$label.text(count);
			}else{
				$comment.html('<span class="label label-danger">'+count+'</span> ');
			}
		}
	},



/*=====  End of Program  ======*/

/*=============================
=            Event            =
=============================*/

	onClickWeitBtn:function(){
		this.$el.waitMe({effect : 'progressBar'});
	},

	onClickMenuitem:function(e){
		var $this = $(e.currentTarget);

		this.$(".menu_item").removeClass("cur");
		$this.addClass("cur");

		this.chatRoomId = $this.data("id");

		this.openChatRoom();
	},

	onSubmitMessageBtn:function(){

		this.sendMessage();

		return false;
	},

	onChangeReadedCheck:function(e){
		var $this = $(e.currentTarget);
		var $chat_message = $this.parents(".chat_message");

		var data = {
			readed : $this.prop("checked") ? 1 : 0,
			message_id : $chat_message.data("messageId"),
		}
		this.ajax(__CHAT_READED_URL,data,function(){

		});

		this.updateUnreadCount();
	},

	

/*=====  End of Event  ======*/



});